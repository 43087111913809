<script>
import { onMounted } from 'vue';

import { trlMessage } from '@/shared/lib/i18n';

export default {
  name: 'NativeCloseConfirmation',
  props: {
    confimationText: {
      type: String,
      default: trlMessage('modal.close.confirm')
    }
  },
  emits: ['resolve'],
  setup(props, { emit }) {
    onMounted(() => {
      const result = confirm(props.confimationText);
      emit('resolve', result);
    });
    return {};
  },
  render() {
    return null;
  }
};
</script>

<i18n lang="json">{}</i18n>

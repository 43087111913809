<template>
  <div>
    <validation-form-select
      name="delivery_preference"
      :label="$trlMessage('document_request.form.delivery_preference.label')"
      :class="commonStyles.field"
    >
      <template #default>
        <base-option v-for="type in typeList" :key="type.id" :value="type.id">
          {{ type.name }}
        </base-option>
      </template>
    </validation-form-select>

    <validation-form-input
      v-if="selectedType === type.EMAIL"
      name="delivery_other_email"
      :placeholder="$trlMessage('document_request.form.delivery_preference.email.placeholder')"
      :class="commonStyles.field"
    />

    <div
      v-if="selectedType === type.DIADOK"
      :class="$style.helpText"
      v-html="$trlMessage('document_request.form.delivery_preference.diadok.help_text')"
    />

    <validation-form-textarea
      v-if="selectedType === type.OTHER"
      name="delivery_comment"
      :placeholder="$trlMessage('document_request.form.delivery_preference.other.placeholder')"
      :maxlength="255"
      :class="[commonStyles.textarea, commonStyles.field]"
    />
  </div>
</template>

<script>
import { string } from 'yup';
import { isEmailValid } from '@/shared/lib/util/validators/email';
import { trlMessage } from '../../shared/lib/i18n';
import ValidationFormInput from './partials/input';
import ValidationFormTextarea from './partials/textarea';
import ValidationFormSelect from './partials/select';
import commonStyles from './common-styles.module.css';
import BaseOption from '@/components/select/base-option.vue';

const Type = {
  CONTACT: 'contact_person',
  EMAIL: 'email',
  DIADOK: 'kontur_diadok',
  OTHER: 'other'
};

export default {
  name: 'DeliveryTypeForm',
  components: {
    BaseOption,
    ValidationFormInput,
    ValidationFormTextarea,
    ValidationFormSelect
  },
  props: {
    formValues: {
      type: Object,
      required: true
    }
  },
  schema: {
    delivery_preference: string().required(),
    delivery_other_email: string().when('delivery_preference', {
      is: (v) => v === Type.EMAIL,
      then: (schema) =>
        schema
          .required()
          .test('email', trlMessage('error.invalid_email'), (v) => (v ? isEmailValid(v) : true))
    }),
    delivery_comment: string().when('delivery_preference', {
      is: (v) => v === Type.OTHER,
      then: (schema) => schema.required()
    })
  },
  computed: {
    commonStyles: () => commonStyles,
    type() {
      return Type;
    },
    typeList() {
      return [
        {
          id: this.type.CONTACT,
          name: this.$trlMessage('document_request.form.delivery_preference.contact')
        },
        {
          id: this.type.EMAIL,
          name: this.$trlMessage('document_request.form.delivery_preference.email')
        },
        {
          id: this.type.DIADOK,
          name: this.$trlMessage('document_request.form.delivery_preference.diadok')
        },
        {
          id: this.type.OTHER,
          name: this.$trlMessage('document_request.form.delivery_preference.other')
        }
      ];
    },
    selectedType() {
      return this.formValues.delivery_preference;
    }
  }
};
</script>

<style lang="less" module>
.helpText {
  padding-top: 3px;
  color: #999;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.2px;
}
</style>

<i18n lang="json">{}</i18n>

import { InterviewTypeAPI } from '@/shared/api/interview-type';
import { trlMessage } from '../../../shared/lib/i18n';

export default {
  namespaced: true,
  state: {
    types: (
      window.InterviewTypes ||
      // На странице настроек нет InterviewTypes переменной
      // TODO сделать
      window.Organization?.interview_types ||
      []
    )
      .concat(
        // Виртуалный источник, живущий на фронте
        {
          order: Infinity,
          id: null,
          name: trlMessage('Other')
        }
      )
      .filter((el) => !el.removed)
  },
  getters: {
    byId: (state) => (id) => state.types.find((el) => el.id === id),
    types(state) {
      return state.types.sort((a, b) => otherTypeLast(a, b) ?? a.order - b.order);
    }
  },
  mutations: {
    updateTypes(state, types) {
      state.types = types.concat(
        // Потому что от бека на пост не приходит "Другие причины"
        // возможно это лучше перенести в action
        state.types.find(isOther)
      );
    }
  },

  actions: {
    update: (store, types) => {
      return InterviewTypeAPI.update(transformTypes(types)).then((types) => {
        store.commit('updateTypes', types);
      });
    }
  }
};

function transformTypes(types) {
  return types
    .filter(({ type }) => type)
    .map(({ id, name }) => {
      if (Number.isNaN(Number(id))) {
        return {
          name
        };
      }
      return {
        id,
        name
      };
    });
}

function isOther(type) {
  return type.id === null;
}

function otherTypeLast(a, b) {
  if (isOther(a)) {
    return 1;
  }
  if (isOther(b)) {
    return -1;
  }
}

<template>
  <div :class="$style.root">
    <base-dropdown placement="bottom-end" @change="handleState">
      <template #default="{ toggle }">
        <base-button type="button" size="xs" icon="options-2-18" data-qa="settings" @click="toggle">
          {{ $trlMessage('vacancy.quota.modal.settings') }}
        </base-button>
      </template>
      <template #content>
        <base-autocomplete
          v-if="selectedSchemaComputed"
          :class="$style.autocomplete"
          multiple
          :selection="selectedSchemasFields[selectedSchemaComputed]"
          :search-engine-instance="searchEngine"
          :include-group-item="false"
          data-qa="visibility-settings-list"
          @change="handleFieldsSelect"
        />
        <select-list
          v-else
          :class="$style.select"
          :items="schemaItems"
          inline
          @change="handleSchemaSelect"
        />
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import { compare } from '@/shared/lib/compators/compators';
import { useLocalAutocomplete } from '@/components/autocomplete-next/search-engine/use-local-autocomplete';
import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown';
import BaseButton from '@/components/button/button';
import BaseAutocomplete from '@/components/autocomplete-next/autocomplete';
import SelectList from '@/components/select-list/select-list';

export default {
  name: 'ModalSettings',
  components: {
    BaseDropdown,
    BaseButton,
    BaseAutocomplete,
    SelectList
  },
  props: {
    schemas: {
      type: Array,
      required: true
    },
    schemasFields: {
      type: Object,
      required: true
    },
    selectedSchemasFields: {
      type: Object,
      required: true
    }
  },
  emits: ['change'],
  data() {
    return {
      selectedSchemaId: null
    };
  },
  computed: {
    singleSchema() {
      return this.schemas.length === 1;
    },
    schemaItems() {
      return this.schemas.map(({ id, name }) => ({
        id,
        name
      }));
    },
    selectedSchemaComputed() {
      return this.singleSchema ? this.schemas[0].id : this.selectedSchemaId;
    },
    schemaFields() {
      return [...this.schemasFields[this.selectedSchemaComputed]].sort((a, b) =>
        compare(a, b, 'title')
      );
    },
    searchEngine() {
      return useLocalAutocomplete({
        sources: [{ items: this.schemaFields }],
        options: {
          matchFn: (query) => (field) => {
            const name = field.title.toLocaleLowerCase();
            const q = query.toLocaleLowerCase();
            return name.includes(q);
          }
        }
      });
    }
  },
  methods: {
    handleState(flag) {
      if (!flag) {
        this.selectedSchemaId = null;
      }
    },
    handleSchemaSelect({ id }) {
      this.selectedSchemaId = id;
    },
    handleFieldsSelect(fields) {
      this.$emit('change', { id: this.selectedSchemaComputed, fields });
    }
  }
};
</script>

<style module>
.root {
  flex: 0 0 auto;
}

.autocomplete {
  margin-top: 10px;
  width: $largeWidth;
}
.select {
  width: $largeWidth;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <div>
    <hierarchy-value
      :hierarchy-map="fullHierarchyMap"
      :multiple="multiple"
      :only-leaf="onlyLeaf"
      :value="value"
      :selected-items="selectedItems"
    >
      <template #default="{ count, allValue }">
        <toggle-text
          :value="allValue"
          :required="required"
          :placeholder="placeholder"
          :multiple="multiple"
          :loading="loading"
          :count="count"
        >
          <template #default="{ text, showText }">
            <slot
              name="toggle"
              :loading="loading"
              :disabled="disabled"
              :invalid="invalid"
              :count="count"
              :value="allValue"
              :multiple="multiple"
              :placeholder="placeholder"
              :required="required"
              :toggle="toggle"
              :show="() => (showModal = true)"
              :shown="showModal"
              :text="text"
              :show-text="showText"
              :selected-items="selectedItems"
            >
              <toggle-button
                :id="id"
                :text="text"
                :name="name"
                :invalid="invalid"
                :disabled="disabled"
                @click="toggle"
              >
                <slot
                  name="toggle-text"
                  :count="count"
                  :value="allValue"
                  :multiple="multiple"
                  :selected-items="selectedItems"
                >
                  <template v-if="!multiple && selectedItems">
                    {{ selectedItems[valueField] }}
                  </template>
                </slot>
              </toggle-button>
            </slot>
          </template>
        </toggle-text>
      </template>
    </hierarchy-value>
    <modal-wrapper :open="showModal" @change="showModal = $event">
      <template #default="{ hide }">
        <autocomplete-modal-content
          :items="items"
          :loading="loading"
          :page-mode="pageMode"
          :multiple="multiple"
          :value="value"
          :item-size="itemSize"
          :hierarchy-map="currentHierarchyMap"
          :show-inactive="showInactive"
          :only-leaf="onlyLeaf"
          :max-values="maxValues"
          :activity-toggle="activityToggle"
          :clearable="clearable"
          :query="query"
          :loop-pointer="loopPointer"
          :title="title"
          :collapsible="collapsible"
          :search-placeholder="searchPlaceholder"
          :caption-field="captionField"
          :disabled="disabled"
          :disable-auto-compress="disableAutoCompress"
          @change="$emit('change', $event)"
          @search="$emit('search', $event)"
          @reset="$emit('reset')"
          @toggle-activity-toggle="$emit('toggle-activity-toggle', $event)"
          @close="hide"
          @collapsed="$emit('collapsed', $event)"
        >
          <template #content>
            <slot name="content" />
          </template>
          <template #before="props">
            <slot name="before" v-bind="props" />
          </template>
          <template #before-search>
            <slot name="before-search" />
          </template>
          <template #search="props">
            <slot name="search" v-bind="props" />
          </template>
          <template #after-search>
            <slot name="after-search" />
          </template>
          <template #not-found>
            <slot name="not-found" />
          </template>
          <template #loading>
            <slot name="loading" />
          </template>
          <template #item-header="props">
            <slot name="item-header" v-bind="props" />
          </template>
          <template #item-header-title="props">
            <slot name="item-header-title" v-bind="props" />
          </template>
          <template #item="props">
            <slot name="item" v-bind="props" />
          </template>
          <template #item-title="props">
            <slot name="item-title" v-bind="props" />
          </template>
          <template #item-hint="props">
            <slot name="item-hint" v-bind="props" />
          </template>
          <template #item-subtitle="props">
            <slot name="item-subtitle" v-bind="props" />
          </template>
          <template #after="props">
            <slot name="after" v-bind="props" />
          </template>
          <template #after-list="props">
            <slot name="after-list" v-bind="props" />
          </template>
        </autocomplete-modal-content>
      </template>
    </modal-wrapper>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';

import HierarchyValue from '@/components/hierarchy/hierarchy-value';
import ModalWrapper from '@/components/modal-next/modal-wrapper';
import { useSelectedItems } from '../composables/use-selected-items';
import { useRemoveNonexistent } from '../composables/use-remove-nonexistent';
import ToggleText from '../toggle-text';
import ToggleButton from '../toggle-button';
import AutocompleteModalContent from './autocomplete-modal-content';

export default {
  name: 'ModalAutocomplete',
  components: {
    ToggleText,
    AutocompleteModalContent,
    HierarchyValue,
    ModalWrapper,
    ToggleButton
  },
  props: {
    loading: AutocompleteModalContent.props.loading,
    pageMode: AutocompleteModalContent.props.pageMode,
    items: AutocompleteModalContent.props.items,
    itemSize: AutocompleteModalContent.props.itemSize,
    showInactive: AutocompleteModalContent.props.showInactive,
    hierarchyMap: AutocompleteModalContent.props.hierarchyMap,
    hierarchyMapWithInactive: {
      type: Object,
      default: null
    },
    loopPointer: AutocompleteModalContent.props.loopPointer,
    multiple: AutocompleteModalContent.props.multiple,
    value: AutocompleteModalContent.props.value,
    maxValues: AutocompleteModalContent.props.maxValues,
    onlyLeaf: AutocompleteModalContent.props.onlyLeaf,
    searchPlaceholder: AutocompleteModalContent.props.searchPlaceholder,
    clearable: AutocompleteModalContent.props.clearable,
    focus: AutocompleteModalContent.props.focus,
    query: AutocompleteModalContent.props.query,
    activityToggle: AutocompleteModalContent.props.activityToggle,
    collapsible: AutocompleteModalContent.props.collapsible,
    captionField: AutocompleteModalContent.props.captionField,
    valueField: AutocompleteModalContent.props.captionField,
    disableAutoCompress: AutocompleteModalContent.props.disableAutoCompress,
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: undefined
    },
    disabled: Boolean,
    required: Boolean,
    invalid: Boolean,
    show: Boolean
  },
  emits: ['change', 'search', 'reset', 'toggle-activity-toggle', 'collapsed', 'visibility-state'],
  setup(props, { emit }) {
    const {
      query,
      items,
      hierarchyMap,
      hierarchyMapWithInactive,
      value,
      multiple,
      loading,
      showInactive
    } = toRefs(props);
    const fullHierarchyMap = computed(() => hierarchyMapWithInactive.value ?? hierarchyMap.value);
    const currentHierarchyMap = computed(() => {
      if (showInactive.value && hierarchyMapWithInactive.value) {
        return hierarchyMapWithInactive.value;
      }
      return hierarchyMap.value;
    });
    const parentChildMap = computed(() => fullHierarchyMap.value.parentChildMap);

    const { selectedItems, normalizedValue } = useSelectedItems(items, {
      showInactive: true,
      parentChildMap,
      multiple,
      query,
      value
    });

    useRemoveNonexistent(normalizedValue, {
      loading,
      parentChildMap,
      onChange(newValue) {
        emit('change', newValue);
      }
    });

    return {
      selectedItems,
      currentHierarchyMap,
      fullHierarchyMap
    };
  },
  data() {
    return {
      showModal: false
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        this.showModal = show;
      }
    },
    showModal: {
      handler(flag) {
        this.$emit('visibility-state', flag);
      },
      immediate: true
    }
  },
  methods: {
    toggle() {
      this.showModal = !this.showModal;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

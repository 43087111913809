<template>
  <popup-layout single-column :title="title">
    <template #prepend>
      <form
        :id="formId"
        :class="$style.form"
        @keydown.meta.enter="handleSubmit"
        @submit.prevent="handleSubmit"
      >
        <slot name="content">
          <collapsed-combobox
            :id="id"
            ref="collapsedCombobox"
            :value="innerValue"
            :items="items"
            :loading="loading"
            :page-mode="pageMode"
            :multiple="multiple"
            :item-size="itemSize"
            :hierarchy-map="hierarchyMap"
            :show-inactive="showInactive"
            :only-leaf="onlyLeaf"
            :max-values="maxValues"
            :activity-toggle="activityToggle"
            :clearable="clearable"
            :query="query"
            :loop-pointer="loopPointer"
            :collapsible="collapsible"
            :placeholder="searchPlaceholder"
            :caption-field="captionField"
            :disable-auto-compress="disableAutoCompress"
            focus
            @search="$emit('search', $event)"
            @reset="$emit('reset')"
            @toggle-activity-toggle="$emit('toggle-activity-toggle', $event)"
            @collapsed="$emit('collapsed', $event)"
            @change="innerValue = $event"
          >
            <template #before="props">
              <slot name="before" v-bind="props" />
            </template>
            <template #before-search>
              <slot name="before-search" />
            </template>
            <template #search="props">
              <slot name="search" v-bind="props" />
            </template>
            <template #after-search>
              <slot name="after-search" />
            </template>
            <template #not-found>
              <slot name="not-found">
                <not-found />
              </slot>
            </template>
            <template #loading>
              <div :class="$style.loader">
                <slot name="loading">
                  <content-loader />
                </slot>
              </div>
            </template>
            <template #item-header="props">
              <slot name="item-header" v-bind="props" />
            </template>
            <template #item-header-title="props">
              <slot name="item-header-title" v-bind="props" />
            </template>
            <template #item="props">
              <slot name="item" v-bind="props" />
            </template>
            <template #item-title="props">
              <slot name="item-title" v-bind="props" />
            </template>
            <template #item-hint="props">
              <slot name="item-hint" v-bind="props" />
            </template>
            <template #item-subtitle="props">
              <slot name="item-subtitle" v-bind="props" />
            </template>
            <template #after="props">
              <slot name="after" v-bind="props" />
            </template>
            <template #after-list="props">
              <slot name="after-list" v-bind="props" />
            </template>
          </collapsed-combobox>
        </slot>
      </form>
    </template>
    <template #footer>
      <base-button
        data-qa="save"
        :disabled="disallowSubmit"
        :form="formId"
        type="submit"
        color="black"
      >
        {{ $trlMessage('button.select') }}
      </base-button>
      <base-button data-qa="cancel" @click="$emit('close')">
        {{ $trlMessage('button.cancel') }}
      </base-button>
    </template>
  </popup-layout>
</template>

<script>
import { nanoid } from 'nanoid';

import PopupLayout from '@/components/popup-layout/popup-layout';
import { CollapsedCombobox } from '@/components/base-combobox';
import BaseButton from '@/components/button/button';
import { ContentLoader } from '@/components/loader';
import NotFound from './not-found';

export default {
  name: 'AutocompleteModalContent',
  components: {
    BaseButton,
    PopupLayout,
    CollapsedCombobox,
    ContentLoader,
    NotFound
  },
  props: {
    id: CollapsedCombobox.props.id,
    loading: CollapsedCombobox.props.loading,
    pageMode: CollapsedCombobox.props.pageMode,
    items: CollapsedCombobox.props.items,
    itemSize: CollapsedCombobox.props.itemSize,
    showInactive: CollapsedCombobox.props.showInactive,
    hierarchyMap: CollapsedCombobox.props.hierarchyMap,
    loopPointer: CollapsedCombobox.props.loopPointer,
    multiple: CollapsedCombobox.props.multiple,
    value: CollapsedCombobox.props.value,
    maxValues: CollapsedCombobox.props.maxValues,
    onlyLeaf: CollapsedCombobox.props.onlyLeaf,
    searchPlaceholder: CollapsedCombobox.props.placeholder,
    clearable: CollapsedCombobox.props.clearable,
    query: CollapsedCombobox.props.query,
    activityToggle: CollapsedCombobox.props.activityToggle,
    captionField: CollapsedCombobox.props.captionField,
    disableAutoCompress: CollapsedCombobox.props.disableAutoCompress,
    title: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    collapsible: Boolean
  },
  emits: ['search', 'reset', 'toggle-activity-toggle', 'collapsed', 'close', 'change'],
  data() {
    return {
      innerValue: this.value,
      formId: nanoid()
    };
  },
  computed: {
    disallowSubmit() {
      if (this.disabled) {
        return true;
      }
      if (this.multiple) {
        return false;
      }
      if (Object.keys(this.hierarchyMap?.parentChildMap || {}).length) {
        return !(this.innerValue in this.hierarchyMap.parentChildMap);
      }
      return !this.innerValue;
    }
  },
  methods: {
    handleSubmit() {
      if (this.disallowSubmit) {
        return;
      }
      this.$emit('change', this.innerValue);
      this.$emit('close');
    },
    toggleCollapse(id) {
      this.$refs.collapsedCombobox.toggleCollapse(id);
    },
    collapse(id) {
      this.$refs.collapsedCombobox.collapse(id);
    },
    expand(id) {
      this.$refs.collapsedCombobox.expand(id);
    }
  }
};
</script>

<style module>
.form {
  --combobox-before-gap: 10px 0 0;
  padding-top: 5px;
  height: 416px; /* полная высота модалки должна быть 550 всегда */
}
.loader {
  color: #8f999c;
}
</style>

<i18n lang="json">{}</i18n>

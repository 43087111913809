import { AxiosClient, AxiosError } from '@/shared/api/utils/client/axios';
import { ApiLayer } from '@/shared/api/utils/api-layer';
import { CursorList, Job, ResponseError } from '@/shared/api/utils/types';
import { MessageEvent } from '@/shared/types/poller-message';
import { DictionarySettings } from '@/shared/types/dictionary-settings';
import { Message } from '@/shared/lib/poller/poller';
import { ResolverGeneric } from '@/shared/api/utils/api-memoize';
import { DIVISION_DICTIONARY } from './index';

import {
  DictionaryItem,
  DictionaryLog,
  DictionaryStructure,
  ListOptions,
  LogsOptions,
  SearchDictionaryItem,
  SearchOptions,
  TreeOptions
} from './types';
import { CancelablePromise } from '../utils/cancelable-promise';

export type FullNames = Record<number, string[]>;

export interface Command {
  add: {
    name: string;
    foreign?: string;
    parent: number | null;
  };
  rename: {
    id: number;
    name: string;
    foreign?: string;
  };
  archive: {
    ids: number[];
  };
  restore: {
    id: number;
  };
  move: {
    ids: number[];
    parent: number | null;
  };
  unite: {
    ids: number[];
    to: number;
  };
  remove: {
    id: number;
    change_to?: number;
  };
}

interface UpdateStatus {
  in_progress: boolean;
  time: number;
}

interface DictionaryInfo {
  code: string;
  id: number;
  organization_id: number;
  settings: DictionarySettings;
  used_in_available_on: boolean;
}

interface HasMeta {
  has_meta: boolean;
}

type ConstructorOptions = {
  hasPermissions?: boolean;
  dictionaryId: string;
};

export class PartialDictionaryLayer extends ApiLayer<AxiosClient> {
  readonly hasPermissions: boolean;
  readonly dictionaryId: string;
  constructor(
    private basePath: string,
    apiClient: AxiosClient,
    { hasPermissions = false, dictionaryId }: ConstructorOptions
  ) {
    super(apiClient);
    this.hasPermissions = hasPermissions;
    this.dictionaryId = dictionaryId;
    const resolver: ResolverGeneric<any> = ({ method, update, message, invalidate }, params) => {
      const messageInfo = this.checkMessage(message);
      if (!messageInfo.matched) {
        return;
      }

      if (messageInfo.type === MessageEvent.dictionaryUpdateEdit) {
        invalidate();
        return;
      }
      if (messageInfo.type !== MessageEvent.dictionaryUpdated) {
        return;
      }
      if (
        messageInfo.event === MessageEvent.job ||
        (messageInfo.event === MessageEvent.notify && messageInfo.data.success)
      ) {
        update(method(params));
      }
    };
    this.getStructure = this.memoizeMethod(this.getStructure, resolver);
    this.getTree = this.memoizeMethod(this.getTree, resolver);
    this.getFullNames = this.memoizeMethod(this.getFullNames, resolver);
  }

  private makePath(subPath: string) {
    return [this.basePath, subPath].join('/');
  }

  public checkMessage(message: Message) {
    return PartialDictionaryLayer.checkMessageByDictionaryId(message, this.dictionaryId);
  }

  public static checkMessageByDictionaryId({ type, event, data }: Message, dictionaryId: string) {
    if (type === MessageEvent.dictionaryUpdateEdit) {
      const payload = data as {
        settings: DictionarySettings;
        meta: {
          type: string;
          code?: string;
          name?: string;
        };
      };
      const matched =
        payload.meta.type === DIVISION_DICTIONARY
          ? dictionaryId === DIVISION_DICTIONARY
          : payload.meta.code === dictionaryId;

      return {
        type,
        event,
        matched,
        data: payload
      };
    }

    if (type === MessageEvent.dictionaryUpdated) {
      const payload = data as {
        success?: boolean;
        type: 'user' | 'api' | 'script';
        name: string;
        meta: {
          type: string;
          code?: string;
          name?: string;
        };
      };
      const matched =
        payload.meta.type === DIVISION_DICTIONARY
          ? dictionaryId === DIVISION_DICTIONARY
          : payload.meta.code === dictionaryId;

      return {
        type,
        event,
        matched,
        data: payload
      };
    }

    return {
      type,
      event,
      matched: false,
      data
    };
  }

  public getStructure({
    include_inactive,
    only_available = true
  }: {
    include_inactive?: boolean;
    only_available?: boolean;
  } = {}): CancelablePromise<DictionaryStructure> {
    return this.methods.get<DictionaryStructure>(this.makePath('struct'), {
      params: {
        only_available: this.hasPermissions ? only_available : undefined,
        include_inactive
      }
    });
  }

  public getTree(options: TreeOptions = {}): CancelablePromise<CursorList<DictionaryItem>> {
    return this.fetchSequentialList(
      (params) =>
        this.methods.get<CursorList<DictionaryItem>>(this.makePath('tree'), {
          params
        }),
      options
    );
  }

  public getList(options: ListOptions = {}): CancelablePromise<CursorList<DictionaryItem>> {
    return this.fetchSequentialList(
      (params) =>
        this.methods.get<CursorList<DictionaryItem>>(this.makePath('list'), {
          params
        }),
      options
    );
  }

  public getSearch(
    options: SearchOptions = {}
  ): CancelablePromise<CursorList<SearchDictionaryItem>> {
    return this.fetchSequentialList(
      (params) =>
        this.methods.get<CursorList<SearchDictionaryItem>>(this.makePath('search'), {
          params
        }),
      options
    );
  }

  fetchSequentialList<Item extends DictionaryItem, Options extends ListOptions>(
    fetch: (props: Options) => CancelablePromise<CursorList<Item>>,
    props: Options = {} as Options
  ): CancelablePromise<CursorList<Item>> {
    const fetchSequential = async () => {
      const onlyActive = !props.include_inactive;
      let repeat = onlyActive;

      const cursorList: CursorList<Item> = {
        next_page_cursor: props.next_page_cursor,
        items: []
      };
      do {
        const { next_page_cursor, items } = await fetch({
          ...props,
          only_available: this.hasPermissions ? (props.only_available ?? true) : undefined,
          include_inactive: true,
          next_page_cursor: cursorList.next_page_cursor
        });
        cursorList.next_page_cursor = next_page_cursor;
        cursorList.items.push(...items);
        if (onlyActive) {
          repeat = !items.some(({ active }) => active);
        }
        if (!next_page_cursor) {
          repeat = false;
        }
      } while (repeat);
      return cursorList;
    };
    return this.toCancelablePromise(fetchSequential());
  }

  public getFullNames<Ids extends number[]>(id: Ids): CancelablePromise<FullNames> {
    return this.methods.get<FullNames>(this.makePath('full_names'), { params: { id } });
  }

  public getFullName(id: number): CancelablePromise<string[]> {
    return this.getFullNames([id]).then((map) => map[id]);
  }

  public hasMeta(): CancelablePromise<HasMeta> {
    return this.methods.get<HasMeta>(this.makePath('has_meta'));
  }

  public getUpdateStatus(): CancelablePromise<UpdateStatus> {
    return this.methods
      .get<UpdateStatus>(this.makePath('update_status'))
      .then(({ in_progress, time }) => ({
        in_progress,
        time: Number(time)
      }));
  }

  public updateSettings(settings: DictionarySettings): CancelablePromise<Job> {
    return this.methods.put<Job>(this.makePath('settings'), settings);
  }

  public getInfo(): CancelablePromise<DictionaryInfo> {
    return this.methods.get<DictionaryInfo>(this.basePath);
  }

  public update(commands: Command[]): CancelablePromise<Job> {
    return this.methods
      .put<Job>(this.basePath, {
        commands
      })
      .catch((err: AxiosError<ResponseError>) => {
        const response = err.response;
        if (response?.status === 400) {
          if (response) {
            Object.entries(response.data.errors || {}).forEach(([key, error]) => {
              delete response.data.errors[key];
              response.data.errors[key.replace(/commands.\d+.\w+./g, '')] = error;
            });
          }
        }
        throw err;
      });
  }

  public logs(params: LogsOptions): CancelablePromise<CursorList<DictionaryLog>> {
    return this.methods.get<CursorList<DictionaryLog>>(this.makePath('logs'), {
      params
    });
  }
}

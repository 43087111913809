<template>
  <modal-wrapper :open="showApp" @change="showApp = $event">
    <div :id="$options.id" class="app-version-info">
      {{ $options.version }}
    </div>
  </modal-wrapper>
</template>

<script>
import { appConfig } from '@/shared/lib/config/app-config';
import ModalWrapper from '@/components/modal-next/modal-wrapper.vue';

const KEY_CODES = {
  TILDE: 192
};

export default {
  name: 'AppVersionInfo',
  components: { ModalWrapper },
  data() {
    return {
      showApp: false
    };
  },
  version: appConfig.get('version').split('-')[0],
  id: 'app-version-info',
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      const isShowVersionShortcut =
        (event.ctrlKey || event.metaKey) && event.shiftKey && event.keyCode === KEY_CODES.TILDE;
      if (isShowVersionShortcut) {
        this.showApp = this.showApp ? false : true;
      }
    }
  }
};
</script>

<style lang="less">
@import '~@less/common/mixins/font';

.app-version-info {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .font_bold();
  font-size: 150px;
  color: #ffffff;
  background: rgba(56, 52, 63, 0.5);
}
</style>

<i18n lang="json">{}</i18n>

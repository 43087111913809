<template>
  <button type="button" :class="$style.arrow" :disabled="isDisabled" @click="handleChange">
    <template v-if="increment"> &gt; </template>
    <template v-else> &lt; </template>
  </button>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';
import { DatePickerUtils } from '@/components/base-datepicker/utils';

export default {
  name: 'BaseArrow',
  props: {
    increment: Boolean,
    incrementUnit: {
      type: Number,
      default: 1
    },
    current: {
      type: DateTimeHelper,
      required: true
    },
    disabled: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      required: true
    }
  },
  emits: ['change'],
  computed: {
    date() {
      const roundDate = this.current.floor({
        unit: this.unit,
        roundingIncrement: this.incrementUnit
      });

      if (this.increment) {
        return roundDate.add({
          [`${this.unit}s`]: this.incrementUnit
        });
      }
      return roundDate.add({
        days: -1
      });
    },
    isDisabled() {
      return DatePickerUtils.isDisabled(this.date, this.disabled, {
        unit: this.unit
      });
    }
  },
  methods: {
    handleChange() {
      this.$emit('change', this.date);
    }
  }
};
</script>

<style module lang="less">
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  grid-column: span 3;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0;
}
.arrow:not(:disabled) {
  cursor: pointer;
}
.arrow:not(:disabled):hover {
  background: #eee;
}
.arrow:disabled {
  color: #ddd;
}
</style>

<i18n lang="json">{}</i18n>

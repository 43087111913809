import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import BaseIcon from '@/components/icon.vue';

interface Props {
  hasError?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'base-attachment-remove-button',
  props: {
    hasError: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    role: "button",
    class: _normalizeClass(_ctx.$style.removeButton),
    "data-qa": "remove-button"
  }, [
    _createVNode(BaseIcon, {
      class: _normalizeClass([_ctx.$style.removeIcon, { [_ctx.$style.error]: _ctx.hasError }]),
      type: "x-close"
    }, null, 8, ["class"])
  ], 2))
}
}

})
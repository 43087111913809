<template>
  <form-field
    :label="label"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
    :files="files"
    error-placement="top"
    :class="$props.class"
    :style="style"
  >
    <template #default="{ id: computedId }">
      <div :class="$style.wrapper">
        <base-radio
          v-for="option in radioOptions"
          :key="option.value"
          :checked="computedModel"
          :value="option.value"
          :name="computedId"
          :label="option.label"
          column
          v-bind="fieldProps"
          @change="computedModel = $event"
        />
      </div>
    </template>
  </form-field>
</template>

<script>
import BaseRadio from '@/components/form-fields/radio/radio';
import FormField from '@/components/vjsf/widgets/form-field';

export default {
  name: 'FormFieldRadioRating',
  components: { FormField, BaseRadio },
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change'],
  computed: {
    files() {
      return this.schema.metadata?.files ?? [];
    },
    computedModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      }
    },
    radioOptions() {
      const realValuePredicate = (value) => value || value === 0 || value === false;
      const options = this.schema.enum.filter(realValuePredicate);
      return options.map((value) => {
        const label = this.options.trl?.[value] ?? String(value);
        return { value, label };
      });
    },
    fieldProps() {
      return {
        ...this.options,
        ...this.$attrs
      };
    }
  }
};
</script>

<style module>
.wrapper {
  display: inline-flex;
  column-gap: 16px;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <component :is="tagName">
    <base-layer
      v-if="showPopper"
      ref="layer"
      :trigger="referenceNode"
      :content-class="contentClass"
      @remove="showPopper = false"
    >
      <slot />
    </base-layer>
    <slot name="reference"></slot>
  </component>
</template>

<script>
import { createPopper } from '@popperjs/core';
import BaseLayer from '@/shared/ui/base-layer/base-layer.vue';

export default {
  name: 'VPopper',
  components: { BaseLayer },
  props: {
    tagName: {
      type: String,
      default: 'span'
    },
    delayOnMouseOver: {
      type: Number,
      default: 10
    },
    delayOnMouseOut: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    },
    referenceNode: {
      type: Function,
      required: true
    },
    contentNode: {
      type: Function,
      required: true
    },
    forceShow: {
      type: Boolean,
      default: false
    },
    dataValue: {
      default: null
    },
    stopPropagation: {
      type: Boolean,
      default: false
    },
    preventDefault: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    contentClass: BaseLayer.props.contentClass
  },

  emits: ['show', 'hide', 'destroyed', 'created'],

  data() {
    return {
      referenceElm: null,
      popperJS: null,
      showPopper: false,
      currentPlacement: '',
      popperStyle: null,
      popperOptions: {
        placement: 'bottom'
      }
    };
  },

  watch: {
    showPopper(value) {
      if (value) {
        // подписываемся на все события скроллов, т.к. у нас всегда контент выносится в конец боди,
        // при вложении нескольких подобных элементов друг в друга последующие перестают позиционироваться,
        // т.к. находятся не в скролящямся элементе
        window.addEventListener('scroll', this.updatePopper, {
          capture: true,
          passive: true
        });
        this.$emit('show', this);
        this.updatePopper();
      } else {
        this.doDestroy();
        this.$emit('hide', this);
      }
    },

    forceShow: {
      handler(value) {
        this[value ? 'doShow' : 'doClose']();
      },
      immediate: true
    },

    disabled(value) {
      if (value) {
        this.showPopper = false;
      }
    }
  },

  created() {
    this.popperOptions = Object.assign(this.popperOptions, this.options);
  },

  beforeUnmount() {
    this.destroyPopper();
  },

  methods: {
    doShow() {
      this.showPopper = true;
    },

    doClose() {
      this.showPopper = false;
    },

    doDestroy() {
      if (this.showPopper) {
        return;
      }

      window.removeEventListener('scroll', this.updatePopper, {
        capture: true,
        passive: true
      });
      if (this.popperJS) {
        this.popperJS.destroy();
        this.popperJS = null;
      }
      this.$emit('destroyed');
    },

    createPopper() {
      this.$nextTick(() => {
        this.referenceElm = this.referenceNode();
        this.popper = this.$refs.layer.getContent();
        this.popperJS = createPopper(this.referenceElm, this.popper, {
          placement: this.options.placement,
          onFirstUpdate: () => {
            this.$emit('created', this);
            this.$nextTick(this.updatePopper);
          },
          modifiers: [...this.options.modifiers]
        });
      });
    },

    destroyPopper() {
      this.showPopper = false;
      this.doDestroy();
    },

    updatePopper() {
      this.popperJS ? this.popperJS.update() : this.createPopper();
      this.$nextTick(() => {
        const ddNode = this.popper;
        if (ddNode) {
          const tr = ddNode.style.transform;
          const reMatches = tr.match(/translate\(([^]+)px,(.*)\)/);
          if (reMatches) {
            ddNode.style.transform = `translate(${reMatches[1] + '.1'}px, ${reMatches[2]})`;
          }
        }
      });
    }
  }
};
</script>

<style lang="less">
@import '~@less/common/mixins/font.less';

.popper {
  width: auto;
  background-color: #fafafa;
  color: #212121;
  text-align: center;
  padding: 2px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  font-size: 14px;
  .font_regular();
  border: 1px #ebebeb solid;
  z-index: 200000;
  -moz-box-shadow: rgb(58, 58, 58) 0 0 6px 0;
  -webkit-box-shadow: rgb(58, 58, 58) 0 0 6px 0;
  box-shadow: rgb(58, 58, 58) 0 0 6px 0;
}

.popper .popper__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.popper[x-placement^='top'] {
  margin-bottom: 5px;
}

.popper[x-placement^='top'] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: #fafafa transparent transparent transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.popper[x-placement^='bottom'] {
  margin-top: 5px;
}

.popper[x-placement^='bottom'] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #fafafa transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.popper[x-placement^='right'] {
  margin-left: 5px;
}

.popper[x-placement^='right'] .popper__arrow {
  border-width: 5px 5px 5px 0;
  border-color: transparent #fafafa transparent transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.popper[x-placement^='left'] {
  margin-right: 5px;
}

.popper[x-placement^='left'] .popper__arrow {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #fafafa;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
</style>

<i18n lang="json">{}</i18n>

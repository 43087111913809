<template>
  <div v-if="isShowVerify">
    <email-status :type="EmailType.LOGIN" :class="$style.widget" />
    <email-status :type="EmailType.NOTIFICATION" :class="$style.widget" />
    <div v-if="showDelimiter" :class="$style.delimiter" />
  </div>
</template>

<script>
import { EmailType } from '@/shared/types/email-user';
import EmailStatus from '@/components/email-verification/main/email-status.vue';
import { userEmails } from '@/shared/lib/user-emails/user-emails';

export default {
  name: 'EmailStatusFull',
  components: { EmailStatus },
  computed: {
    EmailType: () => EmailType,
    showDelimiter() {
      const list = userEmails.getList() || [];
      return list.some((email) => !email.verified);
    },
    isShowVerify() {
      const excludeUrl = 'getting-started';
      const pathSegments = window.location.pathname.split('/').filter((segment) => segment);

      return pathSegments[pathSegments.length - 1] !== excludeUrl;
    }
  }
};
</script>

<style module>
.widget {
  margin: 10px 0 10px 15px;
}

.delimiter {
  width: 100%;
  height: 1px;
  background-color: $delimiterColor;
}
</style>

<i18n lang="json">{}</i18n>

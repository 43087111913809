<template>
  <base-layer
    v-for="(modal, index) in stack"
    :id="modal.id"
    :key="index"
    wrapper-class="modal-target no-scroll"
    :content-class="$style.backdrop"
    :z-index-increment="1000"
    :on-hide="() => handleHide(modal)"
    @remove="() => handleHide(modal)"
  >
    <div
      ref="modal"
      class="modal in modal_show"
      :class="$style.modal"
      tabindex="-1"
      role="dialog"
      @mousedown="handleMouseDown($event, index)"
      @mouseup="handleMouseUp($event, index, modal)"
    >
      <div
        v-if="!(modal.props || {}).orphanMode"
        class="modal-dialog modal-dialog_size_calendar"
        :class="(modal.props || {}).className || {}"
        role="document"
      >
        <div class="modal-content">
          <component :is="modal.component" :props="modal.props"></component>
        </div>
      </div>
      <component :is="modal.component" v-else :props="modal.props"></component>
    </div>
  </base-layer>
</template>
<script>
import { nanoid } from 'nanoid';

import { modal } from '../shared/ui/modals';
import BaseLayer from '@/shared/ui/base-layer/base-layer.vue';

export default {
  name: 'ModalView',
  components: { BaseLayer },
  data() {
    return {
      stack: []
    };
  },
  created() {
    this.unsubscribe = modal.subscribe(this._checkToggle);
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    _checkToggle({ state, component, props = {} }) {
      if (typeof state === 'undefined') {
        const c = this.stack.find((modal) => modal.component === component);
        state = !c;
      }
      this.toggle({ state, component, props });
    },
    toggle({ state, component, props }) {
      if (!state) {
        const idx = this.stack.findIndex((modal) => modal.component === component);

        const item = this.stack[idx];

        if (!item) {
          return;
        }

        this.stack.splice(idx, 1);
        return;
      }
      const id = props.modalId || nanoid();

      this.stack.push({
        component,
        props,
        id
      });
    },
    handleMouseDown(event) {
      this.backdropLastMouseDownTarget = event.target;
    },
    handleMouseUp(event, index, { props, component }) {
      if (
        event.target === this.backdropLastMouseDownTarget &&
        event.target === this.$refs.modal[index]
      ) {
        this.handleHide({ props, component });
      }
    },
    handleHide({ props, component } = {}) {
      const shouldHideModal = props.onHide?.() ?? true;
      if (shouldHideModal) {
        this.toggle({ component, props });
      }
      return shouldHideModal;
    }
  }
};
</script>

<style>
/** :not(:first-child) нужен тупо для фф, там почему-то не пересчитывается has **/
.modal-target:has(~ .modal-target:not(:first-child)) {
  display: none;
}
</style>

<style module>
.backdrop::before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(56, 52, 63, 0.5);
}
.modal {
  z-index: initial;
}
:global(body):has(.modal-target) {
  overflow: hidden;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <form-field
    :label="label"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || $attrs.id"
    :error-schema="errorSchema"
    :files="files"
    error-placement="top"
    :class="$props.class"
    :style="style"
  >
    <template #default="{ id }">
      <rating
        :id="id"
        :value="computedModel"
        :label="label"
        :description="description"
        @change="computedModel = $event"
      />
    </template>
  </form-field>
</template>

<script>
import FormField from '@/components/vjsf/widgets/form-field';
import Rating from '../form-fields/rating/rating.vue';

export default {
  name: 'FormFieldRating',
  components: { FormField, Rating },
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change'],
  computed: {
    files() {
      return this.schema.metadata?.files ?? [];
    },
    computedModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      }
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

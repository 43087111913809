import memoize from 'lodash/memoize';
import axios from '@/shared/api/utils/client/axios';
import { PartialDictionaryLayer } from './layer';
export type { FullNames } from './layer';

export const DIVISION_DICTIONARY = 'account_division';
export const REGION_DICTIONARY = 'account_region';

function dictionaryLayersFactory(dictionaryId: string): PartialDictionaryLayer {
  switch (dictionaryId) {
    case DIVISION_DICTIONARY:
      return new PartialDictionaryLayer('/divisions', axios, {
        hasPermissions: true,
        dictionaryId
      });
    case REGION_DICTIONARY:
      return new PartialDictionaryLayer('/regions', axios, {
        dictionaryId
      });
    default:
      return new PartialDictionaryLayer(`/dictionaries/${dictionaryId}`, axios, {
        dictionaryId
      });
  }
}

export const getMethodsById = memoize(dictionaryLayersFactory);

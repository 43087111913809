import axios from 'axios';
import { ref } from 'vue';

import { basePath, promiseRequest } from '../../../shared/lib/util/request';

export const useServerSideAutocomplete = ({ sources = [], options = {} }) => {
  const { includeBasePath = true } = options;
  const result = ref(undefined);

  let axiosSources = [];

  const search = (query) => {
    axiosSources.forEach((axiosSource) => {
      axiosSource.cancel('Canceled by user');
    });
    axiosSources = [];

    const lastResults = new WeakMap();

    const promises = sources.map((source) => {
      const axiosSource = axios.CancelToken.source();
      axiosSources.push(axiosSource);
      const { getRequestParams, getRequest, ...sourceInfo } = source;
      let promise;
      if (getRequest) {
        promise = getRequest(query || '', { cancelToken: axiosSource.token });
      } else if (getRequestParams) {
        const { url, query: queryObj } = getRequestParams(query ?? '');
        promise = promiseRequest({
          method: 'get',
          path: includeBasePath ? basePath(url) : url,
          query: queryObj,
          cancelToken: axiosSource.token
        });
      } else {
        throw new Error('Invalid autocomplete source');
      }

      return promise
        .then((response) => {
          const result = {
            ...sourceInfo,
            items: response.data.items
          };
          lastResults.set(source, result);
          return result;
        })
        .catch(
          () =>
            lastResults.get(source) ?? {
              ...sourceInfo,
              items: []
            }
        );
    });

    const promise = Promise.all(promises);
    promise.then((matches) => {
      result.value = matches;
    });
    return promise;
  };

  return {
    search,
    result
  };
};

<template>
  <layer-target
    v-if="isOpened"
    :on-hide="dismiss"
    :wrapper-class="wrapperClass"
    :content-class="contentClass"
    :z-index-increment="1000"
    @remove="dismiss"
  >
    <slot name="before" />
    <div
      ref="modal"
      :class="[$style.modal, modalClass]"
      @mousedown.self="handleMouseDown"
      @click.self="handleClick"
    >
      <slot
        :hide="handleHide"
        :dismiss="dismiss"
        :is-dismissing="isDismissing"
        :resolve-dismissing="resolveDismissing"
      />
    </div>
  </layer-target>
</template>
<script>
import { ref, computed, provide, watch } from 'vue';

import LayerTarget from '@/shared/ui/base-layer/base-layer.vue';
import { useModalNext } from './use-modal-next';

export default {
  name: 'ModalNext',
  components: { LayerTarget },
  props: {
    id: {
      type: String,
      default: undefined
    },
    isOpened: {
      type: Boolean,
      default: false
    },
    autoDismiss: {
      type: Boolean,
      default: true
    },
    hide: {
      type: Function,
      required: true
    },
    modalClass: {
      type: String,
      default: ''
    },
    // true - не скрывать предыдущее модальное окно
    onTop: Boolean
  },
  setup(props) {
    const element = ref(null);
    const modal = ref(null);
    provide('$modalNode', modal);
    const { show: showDismiss, hide: hideDismiss, isOpened: isDismissingOpened } = useModalNext();
    const isDismissing = computed(() => isDismissingOpened.value && !props.autoDismiss);

    let isBackdropClick = false;

    watch(
      () => props.isOpened,
      (v) => {
        if (!v) {
          hideDismiss();
        }
      }
    );

    const handleMouseDown = () => {
      isBackdropClick = true;
    };

    const handleClick = () => {
      if (!isBackdropClick) {
        // click is inside modal-dialog
        return;
      }
      isBackdropClick = false;
      dismiss();
    };

    const dismiss = () => {
      if (!props.isOpened || isDismissing.value) {
        return true;
      }
      const dismissResultPromise = props.autoDismiss ? Promise.resolve(true) : showDismiss();
      return dismissResultPromise.then((dismissResult) => {
        if (dismissResult) {
          props.hide();
        }
        return dismissResult;
      });
    };

    const resolveDismissing = (value) => hideDismiss(Boolean(value));

    return {
      handleHide: (value) => {
        resolveDismissing(false);
        props.hide(value);
      },
      handleMouseDown,
      handleClick,
      dismiss,
      isDismissing,
      resolveDismissing,
      element,
      modal
    };
  },
  computed: {
    contentClass() {
      return [this.$style.backdrop];
    },
    wrapperClass() {
      return ['modal-target', { 'modal-target-on-top': this.onTop }];
    }
  }
};
</script>

<style>
/** :not(:first-child) нужен тупо для фф, там почему-то не пересчитывается has **/
.modal-target:has(~ .modal-target:not(:first-child)) {
  display: none;
}

.modal-target:has(+ .modal-target-on-top) {
  display: block !important;
}
</style>

<style lang="less" module>
@import '~@less/common/variables';

.backdrop::before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(56, 52, 63, 0.5);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
}
:global(body:has(.modal-target)) {
  overflow: hidden;
}
</style>

<i18n lang="json">{}</i18n>

import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { StorageHelper } from '@/shared/lib/util/storage-helper';
import { compare } from '../../../shared/lib/compators/compators';
import { VacancyNamingHelper } from '@/shared/lib/util/vacancy-naming';
import { VacancyAPI } from '@/shared/api/vacancy';
import { VacancyState } from '@/shared/types/vacancy-state';
import { SkeletonHelper, SkeletonZones } from '@/shared/lib/util/skeleton-helper';

const initialState = {
  selectedMember: parseInt(StorageHelper.get('SIDEBAR_SELECTED_ID', { namespaced: true }), 10),
  requestsCollapsed:
    StorageHelper.get('vacancyRequestListCollapsed', { namespaced: true }) === 'true',
  vacancyByMember: {
    // [id]: {
    //     loading: Boolean,
    //     error: Boolean,
    //     items: ids[]
    // }
  }
};

function sortVacancies(vacancies) {
  return sortBy(vacancies, [(v) => (v.state === 'HOLD' ? 1 : 0), (v) => -v.priority, 'position']);
}

function checkStatus(vacancy) {
  return ['OPEN', 'HOLD'].includes(vacancy.state);
}

const moduleGetters = {
  requestsCollapsed: (state) => state.requestsCollapsed,
  selectedMember(state, getters, _, rootGetters) {
    const isWatcher = userPermissions.isWatcher;
    if (isWatcher) {
      return getters.meId;
    }
    if (rootGetters['members/find'](state.selectedMember)) {
      return state.selectedMember;
    }

    return getters.meId;
  },
  meId(_, __, ___, rootGetters) {
    return rootGetters['members/meId'];
  },
  myVacancies: (_, getters, __, rootGetters) => {
    const vacancyPermissions = userPermissions.vacancyPermissions;
    const allVacancies = rootGetters['vacancies/all'];
    const vacancies = allVacancies.filter((v) => !!vacancyPermissions[v.id] && checkStatus(v));
    // докидываем родительские вакансии если их нет для дочек в выборке
    // кейс: заказчик назначен на дочку, но не назначен на родителя
    const missingParents = new Set();
    const filteredVacancyIds = new Set(vacancies.map((v) => v.id));
    vacancies.forEach((v) => {
      if (v.parent && !filteredVacancyIds.has(v.parent)) {
        const parentVacancy = rootGetters['vacancies/byId'](v.parent);
        if (parentVacancy) {
          missingParents.add(parentVacancy);
        }
      }
    });
    return vacancies.concat(Array.from(missingParents));
  },
  filteredVacancies: (state, getters, ___, rootGetters) => {
    const allHashedById = rootGetters['vacancies/allHashedById'];
    const vacancies = (state.vacancyByMember[getters.selectedMember]?.items || [])
      .map((id) => allHashedById[id])
      .filter(Boolean);

    const { null: parents, ...childrenById } = groupBy(vacancies, (vacancy) => vacancy.parent);
    return {
      parents: sortVacancies(parents),
      children: Object.fromEntries(
        Object.entries(childrenById).map(([parentId, children]) => {
          const childrenNameInfoMap = {};
          children.forEach((child) => {
            const nameInfo = VacancyNamingHelper.computeVacancyName(child);
            childrenNameInfoMap[child.id] = nameInfo;
            child.childName = (
              nameInfo.fullForm.length ? nameInfo.fullForm : nameInfo.shortForm
            ).join(VacancyNamingHelper.DIVIDER);
          });
          const prefixCount = VacancyNamingHelper.computeCommonPrefixCount(
            Object.values(childrenNameInfoMap)
          );
          children.forEach((child) => {
            const { fullForm, shortForm } = childrenNameInfoMap[child.id];
            const nameParts = fullForm.length ? fullForm : shortForm;
            child.childShortName = VacancyNamingHelper.removePrefix(nameParts, prefixCount).join(
              VacancyNamingHelper.DIVIDER
            );
          });
          children.sort((a, b) => compare(a, b, 'childName'));
          // TODO: объединить сортировки
          return [parentId, sortVacancies(children)];
        })
      )
    };
  },
  hasVacancyByMember: (state) => (id) => {
    const vacancies = state.vacancyByMember[id];
    return Boolean(vacancies) && (vacancies.loading || !vacancies.error);
  },
  showLoading(state, getters) {
    return (state.vacancyByMember[getters.selectedMember] || { loading: true }).loading;
  },
  membersWithVacancy: (state) => (vacancyId) => {
    return Object.entries(state.vacancyByMember)
      .filter(([, { items }]) => {
        return items.includes(vacancyId);
      })
      .map(([memberId]) => memberId);
  }
};

const mutations = {
  toggleRequests(state) {
    state.requestsCollapsed = !state.requestsCollapsed;
    StorageHelper.set('vacancyRequestListCollapsed', state.requestsCollapsed, {
      namespaced: true
    });
  },
  changeMember(state, { id }) {
    StorageHelper.set('SIDEBAR_SELECTED_ID', id, { namespaced: true });
    state.selectedMember = id;
  },
  fetchStart(state, { id }) {
    state.vacancyByMember[id] = {
      loading: true,
      error: false,
      items: []
    };
  },

  fetchSuccess(state, { ids, id }) {
    state.vacancyByMember[id] = {
      items: ids,
      loading: false,
      error: false
    };
  },
  fetchError(state, { id }) {
    state.vacancyByMember[id].error = true;
    state.vacancyByMember[id].loading = false;
  },
  removeVacancyByMember(state, { memberId, vacancyId }) {
    const recruiterIndex = state.vacancyByMember[memberId];
    recruiterIndex.items = recruiterIndex.items.filter((id) => vacancyId !== id);
  },
  addVacancyByMember(state, { recruiterId, vacancyId }) {
    const recruiterIndex = state.vacancyByMember[recruiterId];
    if (!recruiterIndex.items.includes(vacancyId)) {
      recruiterIndex.items.push(vacancyId);
    }
  }
};

// Actions внутри делаем запрос к локальному стору, если это meId, иначе на сервер

const actions = {
  changeMember({ commit, getters }, { id }) {
    if (getters.hasVacancyByMember(id)) {
      commit('changeMember', { id });
      return;
    }
    // Два подряд, потому что сначала надо добавить структуру в
    // набор индексов, а потом на него переключить
    commit('fetchStart', { id });
    commit('changeMember', { id });

    SkeletonHelper.patchPromise(
      SkeletonZones.SIDEBAR_VACANCIES,
      VacancyAPI.fetchListByFilter({
        member: id,
        state: [VacancyState.open, VacancyState.hold]
      })
        .then(({ items }) => {
          items.forEach((vacancy) => {
            commit('vacancies/add', { vacancy }, { root: true });
          });
          commit('vacancies/ready', true, { root: true });
          const ids = items.map(({ id }) => id);
          commit('fetchSuccess', { ids, id });
        })
        .catch(() => {
          commit('fetchError', { id });
        })
    );
  },
  updateMe({ commit, getters }) {
    const ids = getters.myVacancies.map(getId);
    commit('fetchSuccess', { ids, id: getters.meId });
  },
  remove({ commit, getters }, { vacancyId }) {
    getters.membersWithVacancy(vacancyId).forEach((memberId) => {
      commit('removeVacancyByMember', { vacancyId, memberId });
    });
  },
  add({ commit, rootGetters, state }, { vacancyId, memberId: accountMemberId }) {
    const recruiter = rootGetters['members/find'](accountMemberId);
    const vacancy = rootGetters['vacancies/allHashedById'][vacancyId];
    const needAddVacancy =
      recruiter && state.vacancyByMember[accountMemberId] && checkStatus(vacancy);

    if (needAddVacancy) {
      commit('addVacancyByMember', {
        vacancyId,
        recruiterId: accountMemberId
      });
    }
  }
};

function getId({ id }) {
  return id;
}

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations
};

<template>
  <form-field
    :label="label"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
    :class="$props.class"
    :style="style"
  >
    <template #default="{ id: computedId, invalid }">
      <default-wysiwyg
        :id="computedId"
        :invalid="invalid"
        :content="value"
        v-bind="fieldProps"
        @update="handleUpdate"
      />
    </template>
  </form-field>
</template>

<script>
import FormField from './form-field.vue';
import DefaultWysiwyg from '@/components/wysiwyg/default-wysiwyg/default-wysiwyg.vue';

export default {
  name: 'FormFieldHtml',
  components: { FormField, DefaultWysiwyg },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['input', 'change'],
  computed: {
    fieldProps() {
      return {
        ...this.options,
        ...this.$attrs
      };
    }
  },
  methods: {
    handleUpdate(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

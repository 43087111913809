import { ApiLayer } from './utils/api-layer';
import axios, { AxiosClient } from './utils/client/axios';
import { ItemList } from './utils/types';

interface SettingsItem {
  account: number;
  created: string;
  data: string | number;
  id: number;
  type?: string;
}

interface UserInfo {
  email: string;
  has_photo: boolean;
  id: number;
  locale: string;
  name: string;
  position: string;
  phone: string;
  timezone: string;
}

type UserInfoPayload = Omit<UserInfo, 'email' | 'has_photo' | 'id'>;

interface EventValue {
  account: number;
  created: string;
  data: null | string;
  value: number;
  context: number;
  type: string;
  id: number;
}

interface NotificationSettings {
  email: string;
}

class AccountLayer extends ApiLayer<AxiosClient> {
  fetchSettings() {
    return this.methods.get<SettingsItem[]>(`/account/setting`);
  }

  fetchUserInfo() {
    return this.methods.get<UserInfo>('/account/personal', { baseURL: '/' });
  }

  patchUserInfo(payload: UserInfoPayload) {
    return this.methods.post<UserInfo>('/account/personal', payload, { baseURL: '/' });
  }

  updateNotificationEmail(params: NotificationSettings) {
    return this.methods.post('/account/notifications', params, { baseURL: '/' });
  }

  completeApplicantViewSettingsOnboarding() {
    const payload = { type: 'applicant-view-settings', value: 1 };
    return this.methods.post('/account/event', payload, { baseURL: '/' });
  }

  isApplicantViewSettingsOnboardingCompleted() {
    return this.methods.get<ItemList<EventValue>>('/account/event').then((response) => {
      const item = response.items.find(({ type }) => type === 'applicant-view-settings');
      return Boolean(item?.value);
    });
  }

  isApplicantSeenRecommendationGuide() {
    return this.methods.get<ItemList<EventValue>>('/account/event').then((response) => {
      const item = response.items.find(({ type }) => type === 'recommendations-popup');
      return Boolean(item?.value);
    });
  }

  completeRecommendationGuide() {
    const payload = { type: 'recommendations-popup', value: 1 };
    return this.methods.post('/account/event', payload, { baseURL: '/' });
  }
}

export const AccountAPI = new AccountLayer(axios);

<template>
  <form
    method="post"
    :class="$style.form"
    data-qa="applicant_export"
    @submit.prevent.stop="handleSubmit"
  >
    <input type="hidden" name="_xsrf" :value="_xsrf" />
    <input
      v-for="field in allSelectedFields"
      :key="field"
      type="hidden"
      name="field"
      :value="field"
    />
    <input
      v-for="status in selectedStatuses"
      :key="status"
      type="hidden"
      name="status"
      :value="status"
    />
    <dropdown-menu-layout :title="$trlMessage('vacancy.title.stoplist')" size="larger">
      <template #main>
        <dropdown-autocomplete
          :value="selectedFields"
          multiple
          :items="fields"
          @change="selectedFields = $event"
        >
          <template #before>
            <vue-void />
          </template>
          <template #toggle="{ toggle, count }">
            <div class="form-group" data-qa="export_fields">
              <base-label :class="$style.label">
                {{ $trlMessage('vacancy.stoplist.title') }}
              </base-label>
              <toggle-button @click="toggle">
                {{ $trlMessage('vacancy.stoplist.field.fio') }}
                <template v-if="count">
                  + {{ $trlMessage('applicant.export.fields.count', { count }) }}
                </template>
              </toggle-button>
            </div>
          </template>
        </dropdown-autocomplete>

        <dropdown-autocomplete
          v-if="additionalFields.length"
          :value="selectedAdditionalFields"
          multiple
          :items="additionalFields"
          @change="selectedAdditionalFields = $event"
        >
          <template #before>
            <vue-void />
          </template>
          <template #toggle="{ toggle, count, selectedItems }">
            <div :class="{ 'form-group': showStatusList }" data-qa="additional_fields">
              <base-label :class="$style.label">
                {{ $trlMessage('applicant.export.additional.fields.label') }}
              </base-label>
              <toggle-button @click="toggle">
                <template v-if="count === 1">
                  {{ selectedItems[0].name }}
                </template>
                <template v-else>
                  {{ $trlMessage('applicant.export.additional.fields.count', { count }) }}
                </template>
              </toggle-button>
            </div>
          </template>
        </dropdown-autocomplete>

        <div v-if="showStatusList">
          <span :class="$style.statusesTitle">
            {{ $trlMessage('vacancy.stoplist.status.title') }}
          </span>
          <ul :class="$style.statuses">
            <li v-for="status in statuses" :key="status.id" :class="$style.status" data-qa="status">
              <checkbox-field
                :label="status.name"
                :checked="(selectedStatuses || []).includes(status.id)"
                ellipsis
                @change-value="handleStatusChange(status.id, $event)"
              />
            </li>
          </ul>
          <span v-if="statusesError" :class="$style.error">{{ statusesError }}</span>
        </div>
      </template>

      <template #footer>
        <base-button type="submit" color="black" :loading="loading" data-qa="download">
          {{ $trlMessage('button.download') }}
        </base-button>
        <base-button data-qa="cancel" @click="handleCancel">
          {{ $trlMessage('button.cancel') }}
        </base-button>
        <div v-if="limit" :class="$style.limitMsg">
          {{ $trlMessage('Downloading search results limited to') }}&nbsp;{{ limit }}
        </div>
      </template>
    </dropdown-menu-layout>
  </form>
</template>

<script>
import { ref, computed, watch } from 'vue';

import { useGlobal } from '@/shared/lib/use-global';
import { useStore } from 'vuex';
import { XSRFHelper } from '@/shared/lib/util/xsrf-helper';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';
import BaseButton from '@/components/button/button.vue';
import CheckboxField from '@/components/form-fields/checkbox/checkbox.vue';
import { DropdownAutocomplete, ToggleButton } from '@/components/base-autocomplete';
import BaseLabel from '@/components/label/label';
import VueVoid from '@/components/vue-void';

export default {
  name: 'ApplicantsExport',
  components: {
    VueVoid,
    DropdownAutocomplete,
    ToggleButton,
    BaseLabel,
    DropdownMenuLayout,
    BaseButton,
    CheckboxField
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    statuses: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: undefined
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['change', 'submit', 'cancel'],
  setup(props, { emit }) {
    const { $trlMessage } = useGlobal();
    const store = useStore();

    const fieldNames = ['email', 'phone', 'company', 'money', 'birthdate', 'status'];
    const fields = fieldNames.map((id) => ({
      id,
      // eslint-disable-next-line no-restricted-syntax
      name: $trlMessage(`stoplist.fields.${id}`)
    }));
    const questionaryExists = computed(() => store.getters['account/questionaryExists']);
    const applicantQuestionary = computed(() => store.getters['account/applicant_questionary']);
    const additionalFields = computed(() => {
      if (!questionaryExists.value) {
        return [];
      }

      return flattenFields(applicantQuestionary.value).sort((a, b) => a.order - b.order);

      function flattenFields(fields) {
        const entries = Object.entries(fields);
        return entries.reduce((acc, [id, field]) => {
          return field.fields
            ? [...acc, ...flattenFields(field.fields)]
            : [...acc, { id, name: field.title, order: field.order }];
        }, []);
      }
    });

    const selectedFields = ref([]);
    const selectedAdditionalFields = ref([]);
    const selectedStatuses = ref([]);
    const statusesError = ref(undefined);

    watch(
      () => props.value,
      (value) => {
        const field = value?.field ?? [];
        selectedFields.value = fields.map(({ id }) => id).filter((id) => field.includes(id));
        selectedAdditionalFields.value = additionalFields.value
          .map(({ id }) => id)
          .filter((id) => field.includes(id));
        selectedStatuses.value = value?.status ?? [];
      },
      { immediate: true }
    );

    watch(additionalFields, () => {
      selectedAdditionalFields.value = [];
    });

    const allSelectedFields = computed(() => {
      return [...selectedFields.value, ...selectedAdditionalFields.value];
    });

    const emitChange = () => {
      emit('change', {
        field: allSelectedFields.value,
        status: selectedStatuses.value
      });
    };

    watch(allSelectedFields, emitChange);
    watch(selectedStatuses, emitChange);
    watch(selectedStatuses, () => {
      statusesError.value = undefined;
    });
    const showStatusList = computed(() => props.statuses?.length > 0);

    return {
      _xsrf: XSRFHelper.getToken(),
      fields,
      additionalFields,
      selectedStatuses,
      selectedFields,
      selectedAdditionalFields,
      allSelectedFields,

      statusesError,
      showStatusList,

      handleSubmit: () => {
        if (showStatusList.value && selectedStatuses.value.length === 0) {
          statusesError.value = $trlMessage('applicant.export.error.required-statuses');
          return;
        }

        emit('submit', { field: allSelectedFields.value, status: selectedStatuses.value });
      },
      handleCancel: () => emit('cancel'),
      handleStatusChange: (statusId, isChecked) => {
        selectedStatuses.value = isChecked
          ? [...selectedStatuses.value, statusId]
          : selectedStatuses.value.filter((selectedStatusName) => selectedStatusName !== statusId);
      }
    };
  }
};
</script>

<style module>
.form {
  border-radius: 8px;
}
.selectBtn {
  composes: full from '../../shared/ui/css/widths.module.css';
  composes: custom-select from global;
  composes: custom-select_triangle from global;
  display: block;
  padding-left: 15px;
  text-align: left;
}

.statusesTitle {
  display: block;
  margin-bottom: 12px;
}

.label {
  margin-bottom: 4px;
}

.statuses {
  list-style: none inside none;
  margin: 0;
  padding: 0;
}

.status {
  @mixin ellipsis;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.error {
  composes: form-group__error from global;
  display: block;
}

.limitMsg {
  border-top: 1px solid $borderColor;
  margin-top: 8px;
  padding-top: 12px;
  font-size: 15px;
  line-height: 20px;
  color: #bbb;
  width: 100%;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <form-field
    :label="computedLabel"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
    :class="$props.class"
    :style="style"
  >
    <template #default="{ id: computedId, invalid }">
      <partial-dictionary-autocomplete
        :id="computedId"
        :value="computedModel"
        :modal-title="schema.title"
        :invalid="invalid"
        :multiple-label="$i18n('selected')"
        multiple-label-default-unselected
        v-bind="fieldProps"
        @change="computedModel = $event"
      />
    </template>
    <template #hint>
      <div v-html="hint" />
    </template>
  </form-field>
</template>

<script>
import { DIVISION_DICTIONARY } from '@/shared/api/dictionary-partial';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { PartialDictionaryAutocomplete } from '@/components/partial-dictionary-autocomplete';
import FormField from './form-field.vue';

export default {
  name: 'FormFieldPartialDictionary',
  components: { FormField, PartialDictionaryAutocomplete },
  inject: ['setFormDataByPointer'],
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, Number, String],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    },
    pointer: {
      type: String,
      required: true
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change'],
  computed: {
    hint() {
      if (this.fieldProps.dictionaryId === DIVISION_DICTIONARY) {
        return userPermissions.isOwner || userPermissions.get('can_manage_dictionaries')
          ? this.$i18n('hint.editable', {
              a: (text) => {
                if (this.fieldProps.disabled) {
                  return `<u>${text}</u>`;
                }
                return `<a target="_blank" href="/settings/orgs/${userPermissions.get(
                  'nick'
                )}/dictionary-list/${this.fieldProps.dictionaryId}">${text}</a>`;
              }
            })
          : this.$i18n('hint');
      }
      return '';
    },
    computedModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.setFormDataByPointer?.(this.pointer, value);
        this.$emit('change', value);
      }
    },
    computedLabel() {
      return this.label || this.schema.title;
    },
    fieldProps() {
      const props = {
        ...this.options,
        ...this.$attrs
      };

      const multiple = props.hasOwnProperty('multiple')
        ? props.multiple
        : this.schema.type === 'array';

      return {
        ...props,
        multiple
      };
    }
  }
};
</script>

<i18n>
{
  "hint": {
    "ru_RU": "Добавляют, удаляют и настраивают порядок и вложенность пунктов управляющие рекрутеры",
    "en_US": "Owners can add, remove and change the order and nesting of&nbsp;items in the settings"
  },
  "hint.editable": {
    "ru_RU": "Добавляют, удаляют и настраивают порядок и вложенность пунктов <a>в настройках</a>",
    "en_US": "You can add, remove and change the order and nesting of items <a>in the settings</a>"
  }
}
</i18n>

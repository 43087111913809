import { userPermissions } from '@/shared/lib/config/user-permissions';
import { JsonHelper } from '@/shared/lib/util/json-helper';

function detectAvailability() {
  try {
    const test = `HUNTFLOW-${Math.random()}`;

    if (!('localStorage' in window)) {
      return false;
    }

    localStorage.setItem(test, test);
    localStorage.removeItem(test);

    return true;
  } catch (e) {
    return false;
  }
}

const available = detectAvailability();

function namespacedStorageKey(key: string) {
  const org = userPermissions.get('id');
  return `${key}:${org}`;
}

type StorageOptions = {
  namespaced?: boolean;
};

export const StorageHelper = {
  available,

  makeKey(key: string, options: StorageOptions = {}) {
    if (options.namespaced) {
      return namespacedStorageKey(key);
    }

    return key;
  },

  getJSON<T>(key: string, options?: StorageOptions): T | null {
    return JsonHelper.safeJsonParse<T>(this.get(key, options) || '');
  },

  setJSON(key: string, value: any, options?: StorageOptions) {
    this.set(key, JSON.stringify(value), options);
  },

  get(key: string, options?: StorageOptions) {
    // общие мысли: не нравится возврат false из этой функции
    // кмк тут либо должен быть null/undefined, либо string
    // чтобы отличать явную установку настройки в false от остального
    if (!available) {
      return false;
    }

    try {
      // + блок кажется лишним, ибо не пройдёт дальше проверки на available в случае чего
      return localStorage.getItem(this.makeKey(key, options));
    } catch (e) {
      return false;
    }
  },

  getString(key: string, defaultTo?: string, options?: StorageOptions) {
    if (!available) {
      return defaultTo;
    }
    return localStorage.getItem(this.makeKey(key, options)) ?? defaultTo;
  },

  getBool(key: string, defaultTo?: boolean, options?: StorageOptions) {
    if (!available) {
      return defaultTo;
    }
    const str = localStorage.getItem(this.makeKey(key, options));
    if (!str) {
      return defaultTo;
    }
    const value = JsonHelper.safeJsonParse(str, defaultTo as any);
    return typeof value === 'boolean' ? value : defaultTo;
  },

  getInt(key: string, defaultTo?: number, options?: StorageOptions) {
    if (!available) {
      return defaultTo;
    }
    const str = localStorage.getItem(this.makeKey(key, options));
    if (!str) {
      return defaultTo;
    }
    const value = JsonHelper.safeJsonParse<string>(str, defaultTo as any);
    const result = parseInt(value || '');
    return isNaN(result) ? defaultTo : result;
  },

  set(key: string, value: any, options?: StorageOptions) {
    if (!available) {
      return;
    }

    try {
      localStorage.setItem(this.makeKey(key, options), value);
    } catch (e) {
      // suppress any errors
    }
  },

  remove(key: string, options?: StorageOptions) {
    if (!available) {
      return false;
    }

    try {
      localStorage.removeItem(this.makeKey(key, options));
    } catch (e) {
      // suppress any errors
    }
  }
};

import { trlMessage } from '../../i18n';
// eslint-disable-next-line
const VALID_EMAIL_REGEX = /^[-a-z0-9!\.#$%&'*+/=?^_`{|}~]*@([^(@|.)\s]+\.)+[^@\s]{2,}$/i;

export function isEmailValid(value) {
  return VALID_EMAIL_REGEX.test(value);
}

export function emailValidation(value, required = true) {
  if (!value) {
    return required ? trlMessage('This field is required.') : undefined;
  }
  if (!isEmailValid(value)) {
    return trlMessage('error.not-correct-email');
  }
}

<template>
  <base-item
    :class="$style.month"
    :date="date"
    :selected="selected"
    :disabled="disabled"
    unit="month"
    @change="$emit('change', $event)"
  >
    <template #default="scopedProps">
      <month-content :date="scopedProps.parsedDate">
        <template #default="{ month }">
          <slot v-bind="scopedProps" :month="month">
            {{ month }}
          </slot>
        </template>
      </month-content>
    </template>
  </base-item>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';
import BaseItem from './base-item.vue';
import MonthContent from './month-content.vue';

export default {
  name: 'BaseMonth',
  components: { MonthContent, BaseItem },
  props: {
    date: {
      type: String,
      required: true
    },
    selected: {
      type: DateTimeHelper,
      default: null
    },
    disabled: {
      type: Object,
      required: true
    }
  },
  emits: ['change']
};
</script>

<style module lang="less">
@import '~@less/common/variables';
.month {
  text-transform: capitalize;
  grid-column: span 7;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <base-dropdown>
    <template #default="{ toggle }">
      <button type="button" :class="btnClassName" data-qa="recipient" @click="toggle">
        <base-userpic v-if="photo" :src="photo" :class="$style.photo" />
        <base-icon v-else :type="iconType" :class="$style.icon" />
        <span :class="$style.title">{{ title }}</span>
      </button>
    </template>
    <template #content="{ hide }">
      <dropdown-menu-layout size="large">
        <template #heading>
          <h2 :class="$style.heading">{{ title }}</h2>
        </template>
        <template #main>
          <span :class="$style.email">{{ description }}</span>
        </template>
        <template #footer>
          <base-button color="red" @click="$emit('remove')">
            {{ $trlMessage('button.remove') }}
          </base-button>
          <base-button @click="hide">
            {{ $trlMessage('button.cancel') }}
          </base-button>
        </template>
      </dropdown-menu-layout>
    </template>
  </base-dropdown>
</template>

<script>
import { computed, unref, useCssModule } from 'vue';
import { EntityType } from '@/shared/api/photo';
import { PhotoHelper } from '@/modules/applicant-photo-editor/helper';
import { useGlobal } from '@/shared/lib/use-global';
import { useStore } from 'vuex';
import BaseButton from '@/components/button/button.vue';
import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';
import BaseUserpic from '@/components/userpic/userpic.vue';
import BaseIcon from '@/components/icon.vue';
// 300

export default {
  name: 'EmailListItem',
  components: {
    BaseDropdown,
    DropdownMenuLayout,
    BaseIcon,
    BaseUserpic,
    BaseButton
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove'],
  setup(props) {
    const { $trlMessage } = useGlobal();
    const store = useStore();

    const accountRef = computed(() => store.getters['config/account']);
    const account = unref(accountRef);

    const isMe = computed(() => props.item.member === account.id);
    const title = computed(() =>
      isMe.value ? $trlMessage('Me') : props.displayName || props.item.name || props.item.email
    );
    const description = computed(() =>
      props.item.resource ? $trlMessage('email-list-item.resource-description') : props.item.email
    );
    const photo = computed(() =>
      PhotoHelper.getPhotoUrl({
        entity: props.item,
        type: EntityType.USER,
        photoSeed: performance.now()
      })
    );

    const styles = useCssModule();
    return {
      title,
      description,
      photo,
      iconType: getIconType(props.item),
      btnClassName: computed(() => ({
        [styles.btn]: true,
        [styles.invalidStateBtn]: props.invalid
      }))
    };
  }
};

function getIconType(item) {
  if (item.resource) {
    return 'circle-chair';
  }
  return 'circle-profile';
}
</script>

<style module>
.photo {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.btn {
  @mixin reset-button-styles;
  line-height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100%;

  .icon,
  .photo {
    flex: none;
    margin-right: 10px;
  }

  .title {
    flex: 1;
    font-size: 15px;
    @mixin ellipsis;
  }
}

.invalidStateBtn {
  color: $errorColor;
}

.email {
  @mixin ellipsis;
  display: block;
}

.heading {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}
</style>

<i18n lang="json">{}</i18n>

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'buttons-line',
  props: {
    alignment: { default: 'start' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.buttons, _ctx.$style[_ctx.alignment]])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      push: _ctx.$style.push
    })
  ], 2))
}
}

})
import cloneDeep from 'lodash/cloneDeep';
import { MessageEvent } from '@/shared/types/poller-message';
import WebsocketChannel from '@/shared/lib/websocket-channel/websocket-channel';

export type Message<T = unknown> = {
  id?: string;
  event: MessageEvent;
  type: MessageEvent;
  data: T;
};

type Response = {
  messages: {
    message: Message | Message[];
  }[];
  seq: number;
};

type Callback<T = unknown> = (message: Message<T>) => void;
type CallbackMap<T = unknown> = Record<MessageEvent, Callback<T>[]>;
type SubscribeEvent<T = unknown> = Record<MessageEvent, Callback<T>>;
type Unsubscribe = () => void;

export default class Poller {
  #baseUrl: string;
  #wsBaseUrl: string;
  #seq = -1;
  #wsMode = true;
  #request: XMLHttpRequest | null = null;
  #errorSleepTime = 500;
  #callbacks: Callback<any>[] = [];
  #callbacksMap: Partial<CallbackMap<any>> = {};
  // интервал для проверки вебсокета (если находится в режиме пуллинга)
  #wsCheckInterval: null | ReturnType<typeof setInterval> = null;
  #wsCheckIntervalTime = 1000 * 60 * 1.5; // 1.5min

  constructor(baseUrl: string, wsBaseUrl: string) {
    this.#baseUrl = baseUrl;
    this.#wsBaseUrl = wsBaseUrl;
  }

  #swithToLongPolling(channel: WebsocketChannel): void {
    console.log(`[Poller] ${new Date().toLocaleString()} - switching to long-polling`);
    channel.close();
    this.#wsMode = false;

    this.#wsCheckInterval = setInterval(() => {
      console.log(`[Poller] ${new Date().toLocaleString()} - check WS availability`);
      this.start();
    }, this.#wsCheckIntervalTime);

    this.poll();
  }

  public start(): void {
    const channel = new WebsocketChannel({
      url: () => `${this.#wsBaseUrl}/websocket?seq=${this.#seq}`,
      pingInterval: 50000
    });
    channel.emitter.on('open', () => {
      if (this.#wsMode) {
        return;
      }
      // switch back to
      console.log(`[Poller] ${new Date().toLocaleString()} - switching back to web-socket`);
      this.#wsMode = true;
      if (this.#wsCheckInterval) {
        clearInterval(this.#wsCheckInterval);
      }
    });
    channel.emitter.on('error', (err) => {
      if (this.#wsMode && err.type === 'WSReconnectFailure') {
        this.#swithToLongPolling(channel);
      }
    });
    channel.emitter.on('message', (message) => {
      try {
        const data = JSON.parse(message.data);
        this.#handleSuccess(data);
      } catch (err) {
        console.log('wserr', err);
      }
    });
  }

  public onMessage<T = unknown>(cb: Callback<T>): Unsubscribe {
    this.#callbacks.push(cb);

    return () => {
      const index = this.#callbacks.indexOf(cb);
      this.#callbacks.splice(index, 1);
    };
  }

  public onMessageMap<T = unknown>(map: Partial<SubscribeEvent<T>>): Unsubscribe {
    for (const event in map) {
      const typedEvent = event as MessageEvent;
      if (!this.#callbacksMap[typedEvent]) {
        this.#callbacksMap[typedEvent] = [] as Callback<T>[];
      }
      this.#callbacksMap[typedEvent]?.push(map[typedEvent]!);
    }

    return () => {
      for (const event in map) {
        const typedEvent = event as MessageEvent;
        const index = this.#callbacksMap[typedEvent]?.indexOf(map[typedEvent]!);
        if (index === undefined) {
          return;
        }
        this.#callbacksMap[typedEvent]?.splice(index, 1);
      }
    };
  }

  public poll(): void {
    if (this.#request) {
      return;
    }

    const url = `${this.#baseUrl}/updates?seq=${this.#seq}`;
    const request = new XMLHttpRequest();
    request.open('post', url, true);
    request.withCredentials = true;
    request.setRequestHeader('Accept', 'application/json');
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        const { status, response } = request;
        if (!status) {
          return;
        }
        if ((status >= 200 && status < 300) || status === 304) {
          try {
            // No Content
            if (status === 204) {
              this.#retry();
            } else {
              const json = JSON.parse(response);
              this.#handleSuccess(json);
            }
          } catch (e) {
            this.#handleSuccess();
          }
        } else {
          this.#handleError(request.status === 0);
        }
      }
    };
    request.send();

    this.#request = request;
  }

  public cancel(): void {
    if (this.#request) {
      this.#request.abort();
      this.#request = null;
    }
  }

  #emit(message: Message): void {
    this.#callbacksMap[message.event]?.forEach((cb) => {
      try {
        cb(cloneDeep(message));
      } catch (e) {
        console.error(e);
      }
    });

    this.#callbacks.forEach((cb) => {
      try {
        cb(cloneDeep(message));
      } catch (e) {
        console.error(e);
      }
    });
  }

  #retry(): void {
    this.#request = null;
    window.setTimeout(() => {
      this.poll();
    });
  }

  #handleSuccess(response?: Response): void {
    if (!response) {
      return this.#handleError();
    }
    response.messages.forEach((message) => {
      const parsed = message.message;
      if (Array.isArray(parsed)) {
        parsed.forEach((m) => this.#emit(m));
      } else {
        this.#emit(parsed);
      }
    });
    this.#seq = response.seq;
    if (!this.#wsMode) {
      this.#errorSleepTime = 500;
      this.#request = null;
      window.setTimeout(() => {
        this.poll();
      });
    }
  }

  // TODO: залупается в случае ошибки в обработчике
  /* poller.onMessage((message) => {...  */
  #handleError(isAbort = false): void {
    if (isAbort) {
      return;
    }

    this.#errorSleepTime = Math.min(this.#errorSleepTime * 2, 10000);
    this.#request = null;
    window.setTimeout(() => {
      this.poll();
    }, this.#errorSleepTime);
  }
}

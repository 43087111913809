import { setLocale, addMethod, string } from 'yup';
import { trlMessage } from '@/shared/lib/i18n';
import { getErrors, ErrorsType } from '@/shared/lib/util/normalize-errors';
import { yupResolver } from '@vue-validate-form/resolvers';
export { ValidationProvider, ValidationFieldArray } from 'vue-validate-form';
export { yupResolver };
export { default as ValidationField } from './validation-field.vue';
export { default as ValidationErrors } from './validation-errors.vue';
export { default as FormField } from '../form-field/form-field.vue';

// eslint-disable-next-line
const VALID_EMAIL_REGEX = /^[^@\s]+@([^@\s]+\.)+[^@\s]{2,}$/i;

setLocale({
  mixed: {
    required: trlMessage('This field is required.'),
    notType: ({ type }) => trlMessage('survey.validation.type', { type })
  },
  array: {
    max: ({ max }) => trlMessage('survey.validation.maxItems', { limit: max }),
    min: ({ min }) => trlMessage('survey.validation.minItems', { limit: min })
  },
  string: {
    max: ({ max }) => trlMessage('survey.validation.maxLength', { limit: max }),
    min: ({ min }) => trlMessage('survey.validation.minLength', { limit: min }),
    url: trlMessage('form.validation.error.url')
  },
  number: {
    max: ({ max }) => trlMessage('survey.validation.maximum', { limit: max }),
    min: ({ min }) => trlMessage('survey.validation.minimum', { limit: min })
  }
});

addMethod(string, 'email', function validateEmail(message = '') {
  return this.matches(VALID_EMAIL_REGEX, {
    message: message || trlMessage('error.not-correct-email'),
    name: 'email',
    excludeEmptyString: true
  });
});

interface SubmitErrorOptions {
  setError: (name: string, error: Error) => void;
  focusInvalidField: () => void;
}

export function setSubmitError(
  err: ErrorsType,
  { setError, focusInvalidField }: SubmitErrorOptions
): void {
  const errors = getErrors(err);

  Object.entries(errors).forEach(([key, values]) => {
    values.forEach((message) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setError(key, { message, type: 'custom' });
    });
  });
  focusInvalidField();
}

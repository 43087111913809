<template>
  <validation-field :name="name">
    <template #default="{ modelValue, onChange }">
      <base-file :class="$style.file" @change="handleChange($event, onChange)">
        <template #default="{ remove, open }">
          <base-attachment
            v-if="modelValue"
            :value="modelValue"
            :title="modelValue.name"
            icon="clip-18"
            can-remove
            update-on-mount
            @remove="remove"
          />
          <base-button v-else size="xs" color="gray" icon="clip" @click="open">
            {{ label }}
          </base-button>
        </template>
      </base-file>
    </template>
  </validation-field>
</template>

<script>
import { ValidationField } from '../../../shared/ui/validate-form';
import BaseAttachment from '@/components/hf/base-attachment/base-attachment';
import BaseFile from '@/shared/ui/base-file/base-file';
import BaseButton from '@/components/button/button';

export default {
  name: 'FileUpload',
  components: {
    BaseAttachment,
    BaseFile,
    BaseButton,
    ValidationField
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    handleChange(files, onChange) {
      onChange(files?.[0]);
    }
  }
};
</script>

<style module>
.file {
  margin-top: 4px;
  max-width: 100%;
  margin-left: 9px;
}
</style>

<i18n lang="json">{}</i18n>

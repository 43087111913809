<template>
  <base-item
    :class="$style.day"
    :date="date"
    :today="today"
    :selected="selected"
    :disabled="disabled"
    :blur="blur"
    unit="day"
    @change="$emit('change', $event)"
  >
    <template #default="scopedProps">
      <day-content :date="scopedProps.parsedDate">
        <template #default="{ day }">
          <slot v-bind="scopedProps" :day="day">
            {{ day }}
          </slot>
        </template>
      </day-content>
    </template>
  </base-item>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';
import BaseItem from './base-item.vue';
import DayContent from './day-content.vue';

export default {
  name: 'BaseDay',
  components: { DayContent, BaseItem },
  props: {
    date: {
      type: String,
      required: true
    },
    today: {
      type: DateTimeHelper,
      required: true
    },
    selected: {
      type: DateTimeHelper,
      default: null
    },
    disabled: {
      type: Object,
      required: true
    },
    blur: Boolean
  },
  emits: ['change']
};
</script>

<style module lang="less">
@import '~@less/common/variables';
.day {
  grid-column: span 3;
}
</style>

<i18n lang="json">{}</i18n>

import { config } from '@/shared/lib/config/config';

const endings = [2, 0, 1, 1, 1, 2]; // для 0 будет возвращено окончание "много", например, 0 вакансий
function numberEnding(number: number) {
  const c = number % 100;
  if (c > 4 && c < 20) {
    return 2;
  }

  return endings[Math.min(number % 10, 5)];
}

/**
 * @deprecated use trlMessage with icu
 */
function conversion(num: number): string {
  if (num === 0) {
    return 'zero';
  }
  return ['one', 'some', 'many'][numberEnding(num)];
}

function toPriceFormat(number: number, lang: string = config.appConfig.get('lang')): string {
  return new Intl.NumberFormat(lang).format(number);
}

function castToInt(value: any, defaultValue: any): unknown {
  return isNaN(Number(value)) ? defaultValue : parseInt(value);
}

export const NumberHelper = {
  conversion,
  toPriceFormat,
  castToInt
};

<template>
  <div>
    <modal-wrapper :open="showViewModal" @change="handleCancel">
      <template #default="{ hide }">
        <vacancy-request-view
          :vacancy-request="completeVacancyRequest"
          :loading="loading"
          :vacancy-request-schema="vacancyRequestSchema"
          @hide="hide"
          @edit="editRequest"
          @retry="retryRequest"
          @start="start"
        />
      </template>
    </modal-wrapper>

    <template v-if="!loading && completeVacancyRequest">
      <vacancy-request-edit
        :open="showEditModal"
        :vacancy-request="completeVacancyRequest"
        :vacancy-request-schema="vacancyRequestSchema"
        @change="showEditModal = $event"
      />

      <vacancy-request-create
        :open="showCreateModal"
        :vacancy-request="completeVacancyRequest"
        :vacancy-request-schema="vacancyRequestSchema"
        @change="showCreateModal = $event"
      />

      <modal-wrapper :open="showTypeModal" @change="showTypeModal = $event">
        <template #default="{ hide }">
          <vacancy-add-type-dialog
            data-qa="vacancy-request-type-dialog"
            @cancel="hide"
            @submit="startVacancyRequest"
          />
        </template>
      </modal-wrapper>

      <modal-wrapper :open="showVacancyModal" @change="showVacancyModal = $event">
        <template #default="{ dismiss, setDirty }">
          <vacancy-request-vacancy-wrapper
            :vacancy-request="completeVacancyRequest"
            :vacancy-request-schema="vacancyRequestSchema"
            :vacancy-id="vacancyId"
            @cancel="dismiss"
            @save="complete"
            @touch="setDirty(true)"
            @untouch="setDirty(false)"
          />
        </template>
      </modal-wrapper>
    </template>
  </div>
</template>

<script>
import omit from 'lodash/omit';
import { SchemaConverterAPI } from '@/shared/api/schema-converter';
import ModalWrapper from '@/components/modal-next/modal-wrapper';
import VacancyAddTypeDialog from '../../shared/ui/modals/vacancy-add-type-dialog.vue';
import { VacancyRequestStatus } from '@/shared/types/vacancy-request-status';
import VacancyRequestView from './modals/view/view.vue';
import VacancyRequestCreate from './modals/edit/request-create';
import VacancyRequestEdit from './modals/edit/request-edit';
import VacancyRequestVacancyWrapper from './modals/vacancy-wrapper.vue';
import vacancyRequestAPI from '@/shared/api/vacancy-request';

export default {
  name: 'VacancyRequest',
  components: {
    ModalWrapper,
    VacancyRequestCreate,
    VacancyRequestEdit,
    VacancyRequestView,
    VacancyRequestVacancyWrapper,
    VacancyAddTypeDialog
  },
  props: {
    vacancyRequest: {
      type: Object,
      default: undefined
    },
    isResolved: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'update-request-values'],
  data() {
    return {
      vacancyId: undefined,
      vacancyRequestSchema: null,
      loading: false,
      showViewModal: false,
      showEditModal: false,
      showCreateModal: false,
      showTypeModal: false,
      showVacancyModal: false
    };
  },
  computed: {
    completeVacancyRequest() {
      if (!this.vacancyRequest) {
        return undefined;
      }
      const result = this.vacancyRequest;
      return result.status === VacancyRequestStatus.REJECTED ? omit(result, 'id') : result;
    }
  },
  watch: {
    vacancyRequest: {
      immediate: true,
      handler(request, oldRequest) {
        if (oldRequest !== request) {
          this.hideAllModals();
        }
        if (!request) {
          return;
        }
        this.loading = true;

        const vacancyRequestPromise = this.isResolved
          ? Promise.resolve(this.vacancyRequest)
          : vacancyRequestAPI.getById(this.vacancyRequest.id);

        Promise.all([
          vacancyRequestPromise,
          SchemaConverterAPI.getVacancyRequestSchemaClient(request.account_vacancy_request)
        ])
          .then(([{ values }, vacancyRequestSchema]) => {
            this.$emit('update-request-values', values);
            this.vacancyRequestSchema = vacancyRequestSchema;
          })
          .finally(() => (this.loading = false));

        this.showViewModal = true;
      }
    }
  },
  methods: {
    hideAllModals() {
      this.showViewModal = false;
      this.showEditModal = false;
      this.showCreateModal = false;
      this.showTypeModal = false;
      this.showVacancyModal = false;
    },
    handleCancel(flag) {
      this.showViewModal = flag;
      if (!flag) {
        this.$emit('close');
      }
    },
    editRequest() {
      this.showEditModal = true;
    },
    retryRequest() {
      this.showCreateModal = true;
      this.showViewModal = false;
    },
    start(hasVacancy) {
      if (hasVacancy) {
        this.showTypeModal = true;
      } else {
        this.startVacancyRequest(undefined);
      }
    },
    startVacancyRequest(id) {
      this.vacancyId = id;
      this.showVacancyModal = true;
    },
    complete() {
      this.hideAllModals();
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

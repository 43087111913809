<template>
  <dropdown-autocomplete
    :id="id"
    :items="items"
    :loading="loading"
    :page-mode="pageMode"
    :multiple="multiple"
    :value="value"
    :max-height="maxHeight"
    :hierarchy-map="hierarchyMap"
    :hierarchy-map-with-inactive="hierarchyMapWithInactive"
    :show-inactive="showInactive"
    :only-leaf="onlyLeaf"
    :max-values="maxValues"
    :activity-toggle="activityToggle"
    :query="query"
    :name="name"
    :placeholder="placeholder"
    :required="required"
    :clearable="!required"
    :disabled="disabled"
    :loop-pointer="false"
    :dropdown-size="dropdownSize"
    :disable-auto-compress="disableAutoCompress"
    @search="$emit('search', $event)"
    @reset="$emit('reset')"
    @toggle-activity-toggle="$emit('toggle-activity-toggle', $event)"
    @change="$emit('change', $event)"
    @visibility-state="$emit('visibility-state', $event)"
  >
    <template #content>
      <slot name="content" />
    </template>
    <template #toggle="scopedProps">
      <slot name="toggle" v-bind="scopedProps" />
    </template>
    <template #toggle-text="scopedProps">
      <slot name="toggle-text" v-bind="scopedProps" />
    </template>
    <template #before>
      <slot name="before" />
    </template>
    <template #before-search>
      <slot name="before-search" />
    </template>
    <template #after-search>
      <slot name="after-search" />
    </template>
    <template #not-found>
      <slot name="not-found" />
    </template>
    <template #loading>
      <slot name="loading" />
    </template>
    <template #item-header="props">
      <slot name="item-header" v-bind="props" />
    </template>
    <template #item-header-title="props">
      <slot name="item-header-title" v-bind="props" />
    </template>
    <template #item="props">
      <slot name="item" v-bind="props" />
    </template>
    <template #item-title="props">
      <slot name="item-title" v-bind="props" />
    </template>
    <template #item-hint="props">
      <slot name="item-hint" v-bind="props" />
    </template>
    <template #item-subtitle="props">
      <slot name="item-subtitle" v-bind="props" />
    </template>
    <template #after="props">
      <slot name="after" v-bind="props" />
    </template>
    <template #toggle-icon>
      <slot name="toggle-icon" />
    </template>
    <template #after-list="props">
      <slot :id="null" name="after-level" />
      <spinner-item v-if="loadingByLevel[null]" :deep="0" />
      <slot name="after-list" v-bind="props" />
    </template>
  </dropdown-autocomplete>
</template>

<script>
import { DropdownAutocomplete } from '@/components/base-autocomplete';
import SpinnerItem from '@/components/list-item/spinner-item';

export default {
  name: 'PartialDropdownAutocomplete',
  components: { DropdownAutocomplete, SpinnerItem },
  props: {
    loading: DropdownAutocomplete.props.loading,
    pageMode: DropdownAutocomplete.props.pageMode,
    items: DropdownAutocomplete.props.items,
    itemSize: DropdownAutocomplete.props.itemSize,
    showInactive: DropdownAutocomplete.props.showInactive,
    hierarchyMap: DropdownAutocomplete.props.hierarchyMap,
    hierarchyMapWithInactive: DropdownAutocomplete.props.hierarchyMapWithInactive,
    multiple: DropdownAutocomplete.props.multiple,
    value: DropdownAutocomplete.props.value,
    maxValues: DropdownAutocomplete.props.maxValues,
    onlyLeaf: DropdownAutocomplete.props.onlyLeaf,
    searchPlaceholder: DropdownAutocomplete.props.placeholder,
    clearable: DropdownAutocomplete.props.clearable,
    focus: DropdownAutocomplete.props.focus,
    query: DropdownAutocomplete.props.query,
    activityToggle: DropdownAutocomplete.props.activityToggle,
    maxHeight: DropdownAutocomplete.props.maxHeight,
    placeholder: DropdownAutocomplete.props.placeholder,
    title: DropdownAutocomplete.props.title,
    name: DropdownAutocomplete.props.name,
    disabled: DropdownAutocomplete.props.disabled,
    required: DropdownAutocomplete.props.required,
    id: DropdownAutocomplete.props.id,
    invalid: DropdownAutocomplete.props.invalid,
    dropdownSize: DropdownAutocomplete.props.dropdownSize,
    disableAutoCompress: DropdownAutocomplete.props.disableAutoCompress,
    loadingByLevel: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['search', 'reset', 'toggle-activity-toggle', 'change', 'visibility-state']
};
</script>

<i18n lang="json">{}</i18n>

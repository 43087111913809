import omit from 'lodash/omit';
import { AxiosRequestConfig } from 'axios';
import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios, { AxiosClient } from '@/shared/api/utils/client/axios';
import { ItemList } from '@/shared/api/utils/types';
import { Delete } from '@/shared/api/utils/default-responses';
import { IFileInfoShort } from '@/shared/api/file';

interface ISurveyFile {
  id: number;
}

interface IFileInfo extends Omit<IFileInfoShort, 'size'> {
  url: string;
}

export interface ISurvey {
  active: boolean;
  created: string;
  id?: number;
  name: string;
  type: 'type_q';
  updated: string;
  schema: {
    properties: Record<
      string,
      {
        metadata: {
          files?: ISurveyFile[] | ISurveyFile['id'][];
        };
      }
    >;
  };
  ui_schema: Record<string, unknown>;
}

export interface ISurveyItem {
  name: string;
  id: number;
  type: 'type_q';
  active: boolean;
  created: string;
  updated: string;
}

interface IQuestionnaire {
  id: number;
  survey: ISurvey;
  respondent: {
    applicant_id: number;
    name: string;
  };
  survey_answer_id: null | number;
  created: string;
  created_by: {
    account_id: number;
    name: string;
  };
  link: string;
}

interface IQuestionnaireAnswer {
  id: number;
  created: string;
  survey: ISurvey;
  respondent: IQuestionnaire['respondent'];
  survey_questionary: Pick<IQuestionnaire, 'id' | 'created' | 'created_by'>;
}

class SurveyTypeQLayer extends ApiLayer<AxiosClient> {
  fetchById(id: number) {
    return this.methods.get<ISurvey>(`/surveys/type_q/${id}`);
  }

  createQuestionnaire(surveyId: number, applicantId: number | string) {
    const data = {
      survey_id: surveyId,
      respondent: {
        applicant_id: applicantId
      }
    };
    return this.methods.post<unknown>(`/surveys/type_q/questionary`, data);
  }

  publishAnswer(code: string, data: Record<string, unknown>, token: string) {
    const url = `/survey_questionary/${code}/publish?token=${token}`;
    const config: AxiosRequestConfig = {
      baseURL: ''
    };
    return this.methods.post<{ status: 'ok' }>(url, data, config);
  }

  fetchQuestionnaireById(id: number) {
    return this.methods.get<IQuestionnaire>(`/surveys/type_q/questionary/${id}`);
  }

  fetchQuestionnaireResponseById(id: number) {
    return this.methods.get<IQuestionnaireAnswer>(`/surveys/type_q/answers/${id}`);
  }

  fetchQuestionnaireResponseFiles(id: number) {
    const url = `/surveys/type_q/answers/${id}/files`;
    return this.methods.get<ItemList<IFileInfo>>(url).then(({ items }) => items);
  }

  // для запроса кандидатом информации по файлам
  // кейс: загрузил файлы, обновил страницу с формой заполнения -
  // нужно восстановить форму и показать полную информацию о файлах
  // (в данных формы файлы представлены идентификаторами)
  fetchFiles(ids: number[], code: string) {
    const url = `/survey_questionary/${code}/file_info`;
    return this.methods
      .get<ItemList<IFileInfoShort>>(url, {
        baseURL: '/',
        params: {
          file_id: ids
        }
      })
      .then(({ items }) => items);
  }

  deleteQuestionnaire(id: number) {
    return this.methods.delete<Delete>(`/surveys/type_q/questionary/${id}`);
  }

  fetchActiveList() {
    const params = { short_info: true, active: true };
    return this.methods
      .get<ItemList<ISurveyItem>>('/surveys/type_q', { params })
      .then(({ items }) => items);
  }

  fetchArchiveList() {
    const params = { short_info: true, active: false };
    return this.methods
      .get<ItemList<ISurveyItem>>('/surveys/type_q', { params })
      .then(({ items }) => items);
  }

  create(survey: ISurvey) {
    return this.methods.post<ISurvey>('/surveys/type_q', survey);
  }

  update(id: number, survey: ISurvey) {
    const payload = omit(survey, ['id', 'created', 'updated']);
    return this.methods.put<ISurvey>(`/surveys/type_q/${id}`, payload);
  }

  delete(id: number) {
    return this.methods.delete<Delete>(`/surveys/type_q/${id}`);
  }
}

export const SurveyTypeQAPI = new SurveyTypeQLayer(axios);

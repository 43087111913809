import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios, { AxiosClient } from '@/shared/api/utils/client/axios';
import { ItemList } from '@/shared/api/utils/types';
import { CancelablePromise } from '@/shared/api/utils/cancelable-promise';

interface NewTag {
  color: string;
  name: string;
}

interface Tag extends NewTag {
  account: number;
  id: number;
}

class TagsLayer extends ApiLayer<AxiosClient> {
  create(tags: (Tag | NewTag)[]): CancelablePromise<ItemList<Tag>> {
    return this.methods.post<ItemList<Tag>>('/tag', tags);
  }
}

export const TagsAPI = new TagsLayer(axios);

<template>
  <view-field
    :schema="schema"
    :is-visible="options.isVisible"
    :label="label"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
  >
    <widget-value :value="dateStr" />
  </view-field>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';
import ViewField from './view-field';
import WidgetValue from './base-widget/widget-value';

export default {
  name: 'ViewFieldDate',
  components: {
    ViewField,
    WidgetValue
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: ViewField.props.schema
  },
  computed: {
    dateStr() {
      return DateTimeHelper.parse({ date: this.value }).toShortFormat();
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

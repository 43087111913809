<template>
  <base-item
    :class="$style.year"
    :date="date"
    :selected="selected"
    :disabled="disabled"
    unit="year"
    @change="$emit('change', $event)"
  >
    <template #default="scopedProps">
      <year-content :date="scopedProps.parsedDate">
        <template #default="{ year }">
          <slot v-bind="scopedProps" :month="year">
            {{ year }}
          </slot>
        </template>
      </year-content>
    </template>
  </base-item>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';
import BaseItem from './base-item.vue';
import YearContent from './year-content.vue';

export default {
  name: 'BaseYear',
  components: { YearContent, BaseItem },
  props: {
    date: {
      type: String,
      required: true
    },
    selected: {
      type: DateTimeHelper,
      default: null
    },
    disabled: {
      type: Object,
      required: true
    }
  },
  emits: ['change']
};
</script>

<style module lang="less">
@import '~@less/common/variables';
.year {
  grid-column: span 7;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <div :class="$style.root" @click.prevent="$emit('click', type)">
    <div :class="$style.text" v-html="text" />
    <span :class="$style.link">
      {{ $i18n('link') }}
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none">
        <path
          fill="#684200"
          fill-rule="evenodd"
          d="M9.605 6.43h-.002l-3.641 3.64-.93-.928L7.956 6.22H.64V4.906H8.08L5.043 1.87l.929-.93 4.562 4.563-.93.93V6.43Z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
  </div>
</template>

<script>
import { EmailType } from '@/shared/types/email-user';

export default {
  name: 'EmailStatusBanner',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  emits: ['click'],
  computed: {
    isLogin() {
      return this.type === EmailType.LOGIN;
    },
    text() {
      return this.isLogin
        ? this.$i18n('email.status.login')
        : this.$i18n('email.status.notification');
    }
  }
};
</script>

<style module>
.root {
  background-color: #fff196;
  color: #684200;
  font-size: 14px;
  line-height: 18px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-width: 270px;
  padding: 12px 20px 16px;
  cursor: pointer;

  &:hover {
    background-color: #ffef85;
  }
}

.text {
  margin-bottom: 5px;
}

.link {
  color: #684200;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>

<i18n lang="json">
{
  "email.status.login": {
    "ru_RU": "Рабочая почта для входа не&nbsp;подтверждена",
    "en_US": "Work email for log in is not&nbsp;confirmed"
  },
  "email.status.notification": {
    "ru_RU": "Почта для уведомлений не&nbsp;подтверждена",
    "en_US": "Email for notifications is not confirmed"
  },
  "link": {
    "ru_RU": "Подтвердить почту",
    "en_US": "Confirm email"
  }
}
</i18n>

import { makeI18n } from '../../../shared/lib/i18n';
import langOrg from '../organization-settings-router/orgRoutes.lang.json';
import lang from '../settings-router/routes.lang.json';

export default {
  company: {
    title: makeTranslate('settings.tab.org', langOrg),
    text: makeTranslate('settings.tab.org.text', langOrg),
    routeTitle: makeTranslate('settings.tab.org', langOrg),
    icon: 'settings-organization'
  },
  'company-account': {
    title: makeTranslate('settings.org.company.title', langOrg),
    tabTitle: makeTranslate('settings.org.company.title', langOrg),
    routeTitle: makeTranslate('settings.tab.org', langOrg),
    text: makeTranslate('settings.org.company.text', langOrg),
    icon: 'settings-organization',
    iconBar: 'org-info'
  },
  users: {
    title: makeTranslate('settings.tab.users', langOrg),
    routeTitle: makeTranslate('settings.tab.users', langOrg),
    text: makeTranslate('settings.tab.users.text', langOrg),
    icon: 'settings-users'
  },
  recruiters: {
    title: makeTranslate('settings.users.recruiters.title', langOrg),
    tabTitle: makeTranslate('settings.users.recruiters.title', langOrg),
    routeTitle: makeTranslate('settings.tab.users', langOrg),
    text: makeTranslate('settings.users.recruiters.text', langOrg),
    icon: 'settings-users',
    iconBar: 'user-recruiters'
  },
  response: {
    title: makeTranslate('settings.tab.response', langOrg),
    routeTitle: makeTranslate('settings.tab.response', langOrg),
    text: makeTranslate('settings.tab.response.text', langOrg),
    icon: 'settings-response'
  },
  'career-site': {
    title: makeTranslate('settings.tab.response', langOrg),
    tabTitle: makeTranslate('settings.response.career.title', langOrg),
    routeTitle: makeTranslate('settings.tab.response', langOrg),
    text: makeTranslate('settings.tab.response.text', langOrg),
    icon: 'settings-response',
    iconBar: 'response-career-site'
  },
  business: {
    title: makeTranslate('settings.tab.business', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.tab.business.text', langOrg),
    icon: 'settings-business'
  },
  'resume-sources': {
    title: makeTranslate('settings.business.resume.title', langOrg),
    tabTitle: makeTranslate('settings.business.resume.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.resume.text', langOrg),
    iconBar: 'business-folder'
  },
  'interview-types': {
    title: makeTranslate('settings.business.interview-types.title', langOrg),
    tabTitle: makeTranslate('settings.business.interview-types.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.interview-types.text', langOrg),
    iconBar: 'interview-types'
  },
  funnel: {
    title: makeTranslate('settings.tab.funnel', langOrg),
    routeTitle: makeTranslate('settings.tab.funnel', langOrg),
    text: makeTranslate('settings.tab.funnel.text', langOrg),
    icon: 'settings-funnel'
  },
  pipeline: {
    title: makeTranslate('settings.funnel.pipeline.title', langOrg),
    tabTitle: makeTranslate('settings.funnel.pipeline.title', langOrg),
    routeTitle: makeTranslate('settings.tab.funnel', langOrg),
    text: makeTranslate('settings.funnel.pipeline.text', langOrg),
    iconBar: 'funnel-stages'
  },
  api: {
    title: makeTranslate('settings.tab.api', langOrg),
    routeTitle: makeTranslate('settings.tab.api', langOrg),
    text: makeTranslate('settings.tab.api.text', langOrg),
    icon: 'settings-api'
  },
  survey: {
    title: makeTranslate('settings.tab.templates', langOrg),
    routeTitle: makeTranslate('settings.tab.templates', langOrg),
    text: makeTranslate('settings.tab.templates.text', langOrg),
    icon: 'settings-templates'
  },
  'survey-type-q': {
    title: makeTranslate('settings.templates.survey-type-q.title', langOrg),
    tabTitle: makeTranslate('settings.templates.survey-type-q.title', langOrg),
    routeTitle: makeTranslate('settings.tab.templates', langOrg),
    text: makeTranslate('settings.templates.survey-type-q.text', langOrg),
    icon: 'settings-templates',
    iconBar: 'template-test'
  },
  themes: {
    title: makeTranslate('settings.tab.themes', langOrg),
    tabTitle: makeTranslate('settings.tab.themes', langOrg),
    routeTitle: makeTranslate('settings.tab.themes', langOrg),
    text: makeTranslate('settings.tab.themes.text', langOrg),
    icon: 'settings-themes'
  },
  system_division: {
    title: makeTranslate('settings.org.structure.title', langOrg),
    routeTitle: makeTranslate('settings.tab.org', langOrg),
    text: makeTranslate('settings.org.structure.text', langOrg),
    icon: 'org-structure'
  },
  security: {
    title: makeTranslate('settings.org.security.title', langOrg),
    routeTitle: makeTranslate('settings.tab.org', langOrg),
    text: makeTranslate('settings.org.security.text', langOrg),
    icon: 'org-afety'
  },
  sms: {
    title: makeTranslate('settings.org.sms.title', langOrg),
    routeTitle: makeTranslate('settings.tab.org', langOrg),
    text: makeTranslate('settings.org.sms.text', langOrg),
    icon: 'org-sms'
  },
  telephony: {
    title: makeTranslate('settings.org.telefony.title', langOrg),
    routeTitle: makeTranslate('settings.tab.org', langOrg),
    text: makeTranslate('settings.org.telefony.text', langOrg),
    icon: 'org-phone'
  },
  notifications: {
    title: makeTranslate('settings.org.notifications.title', langOrg),
    routeTitle: makeTranslate('settings.tab.org', langOrg),
    text: makeTranslate('settings.org.notifications.text', langOrg),
    icon: 'org-notifications'
  },
  'hiring-managers': {
    title: makeTranslate('settings.user.managers.title', langOrg),
    routeTitle: makeTranslate('settings.tab.users', langOrg),
    text: makeTranslate('settings.user.managers.text', langOrg),
    icon: 'user-hiring-managers'
  },
  'vacancy-request': {
    title: makeTranslate('settings.business.vacancy-request.title', langOrg),
    tabTitle: makeTranslate('settings.business.vacancy-request.title', langOrg),
    text: makeTranslate('settings.business.vacancy-request.text', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    icon: 'business-request'
  },
  'business-request': {
    title: makeTranslate('settings.business.request.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.request.text', langOrg),
    icon: 'business-request'
  },
  'business.vacancy': {
    title: makeTranslate('settings.business.vacancy.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.vacancy.text', langOrg),
    icon: 'business-vacancy'
  },
  vacancy: {
    title: makeTranslate('settings.business.vacancy.title', langOrg),
    tabTitle: makeTranslate('settings.business.vacancy.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.vacancy.text', langOrg),
    icon: 'business-vacancy'
  },
  'additional-applicant-info': {
    title: makeTranslate('settings.business.applicant.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.applicant.text', langOrg),
    icon: 'business-applicant'
  },
  additional_dictionaries: {
    title: makeTranslate('settings.business.dictionary.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.dictionary.text', langOrg),
    icon: 'business-book'
  },
  additional_dictionaries_inner: {
    title: makeTranslate('settings.business.dictionary.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.dictionary.text', langOrg),
    icon: 'business-book'
  },
  dictionary: {
    title: makeTranslate('settings.business.dictionary.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.dictionary.text', langOrg),
    icon: 'settings-organization'
  },
  'business-offer': {
    title: makeTranslate('settings.business.offer.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.offer.text', langOrg),
    icon: 'business-thumbs-up'
  },
  'business-tag': {
    title: makeTranslate('settings.business.tag.title', langOrg),
    routeTitle: makeTranslate('settings.tab.business', langOrg),
    text: makeTranslate('settings.business.tag.text', langOrg),
    icon: 'business-tag'
  },
  'pipeline-statuses': {
    title: makeTranslate('settings.funnel.vacancy_status.title', langOrg),
    routeTitle: makeTranslate('settings.tab.funnel', langOrg),
    text: makeTranslate('settings.funnel.vacancy_status.text', langOrg),
    icon: 'funnel-funnels'
  },
  'time-on-status': {
    title: makeTranslate('settings.funnel.time_status.title', langOrg),
    routeTitle: makeTranslate('settings.tab.funnel', langOrg),
    text: makeTranslate('settings.funnel.time_status.text', langOrg),
    icon: 'funnel-clock'
  },
  'rejection-reasons': {
    title: makeTranslate('settings.funnel.rejection_reason.title', langOrg),
    routeTitle: makeTranslate('settings.tab.funnel', langOrg),
    text: makeTranslate('settings.funnel.rejection_reason.text', langOrg),
    icon: 'funnel-refusal'
  },
  'survey-type-r': {
    title: makeTranslate('settings.templates.survey-type-r.title', langOrg),
    routeTitle: makeTranslate('settings.tab.templates', langOrg),
    text: makeTranslate('settings.templates.survey-type-r.text', langOrg),
    icon: 'template-rating'
  },
  'survey-type-a': {
    title: makeTranslate('settings.templates.survey-type-a.title', langOrg),
    routeTitle: makeTranslate('settings.tab.templates', langOrg),
    text: makeTranslate('settings.templates.survey-type-a.text', langOrg),
    icon: 'template-feedback'
  },
  'email-templates': {
    title: makeTranslate('settings.templates.email-templates.title', langOrg),
    routeTitle: makeTranslate('settings.tab.templates', langOrg),
    text: makeTranslate('settings.templates.email-templates.text', langOrg),
    icon: 'template-letter'
  },
  'im-templates': {
    title: makeTranslate('settings.templates.messengers.title', langOrg),
    routeTitle: makeTranslate('settings.tab.templates', langOrg),
    text: makeTranslate('settings.templates.messengers.text', langOrg),
    icon: 'template-messengers'
  },
  'sms-templates': {
    title: makeTranslate('settings.templates.sms.title', langOrg),
    routeTitle: makeTranslate('settings.tab.templates', langOrg),
    text: makeTranslate('settings.templates.sms.text', langOrg),
    icon: 'template-sms'
  },
  profile: {
    title: makeTranslate('settings.tab.my_profile', lang),
    text: makeTranslate('settings.tab.my_profile.text', lang),
    icon: 'settings-profile'
  },
  social: {
    title: makeTranslate('settings.tab.jobsites', lang),
    text: makeTranslate('settings.tab.jobsites.text', lang),
    icon: 'settings-jobsites'
  },
  magic: {
    title: makeTranslate('settings.tab.magic-button', lang),
    text: makeTranslate('settings.tab.magic-button.text', lang),
    icon: 'settings-magic-button'
  },
  connections: {
    title: makeTranslate('settings.tab.connections', lang),
    text: makeTranslate('settings.tab.connections.text', lang),
    icon: 'settings-calendar'
  },
  integrations: {
    title: makeTranslate('settings.tab.integrations', lang),
    text: makeTranslate('settings.tab.integrations.text', lang),
    icon: 'settings-integrations'
  }
};

function makeTranslate(translate, lang) {
  return makeI18n(lang)(translate);
}

import { App, Plugin } from 'vue';

import { trlMessage } from '@/shared/lib/i18n';
import { appConfig } from '@/shared/lib/config/app-config';

export interface I18NOptions {
  messages?: Partial<Record<'ru' | 'en', Record<string, string>>>;
  lang?: 'ru' | 'en';
}

const VueL10n: Plugin = {
  install(
    Vue: App,
    { lang = appConfig.get('lang'), messages = { [lang]: window.Translations } }: I18NOptions = {}
  ) {
    const getLanguage = () => lang;

    function $trlMessage(
      message: string,
      payload?: Record<string, unknown>,
      defaultValue = message
    ) {
      const language = getLanguage();
      // eslint-disable-next-line no-restricted-syntax
      return trlMessage(message, payload, defaultValue, language, {}, messages[language]);
    }

    Vue.provide('$trlMessage', $trlMessage);

    Vue.config.globalProperties.$trlMessage = $trlMessage;
  }
};

export default VueL10n;

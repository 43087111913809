import store from '@/app/store';
import Paywall from '@/modules/main/components/paywall.vue';
import FeedbackButton from '@/components/page-layout/feedback-button.vue';
import { AppHelper } from './app-helper';

function registerPaywall(mountElement: HTMLElement | null) {
  if (!mountElement) {
    return;
  }

  const { jsonData, jsonValues, jsonErrors } = mountElement.dataset;

  const props = {
    jsonData,
    jsonValues,
    jsonErrors
  };

  return AppHelper.createApp(Paywall, {
    selector: mountElement,
    props,
    route: {
      name: 'paywall-component',
      params: props
    }
  });
}

function registerFeedback(mountElement: HTMLElement | null) {
  if (!mountElement) {
    return;
  }

  return AppHelper.createApp(FeedbackButton, {
    store,
    selector: mountElement,
    route: {
      name: 'feedback-button-component'
    }
  });
}

export const RegisterGlobalComponents = {
  register(): void {
    registerPaywall(document.getElementById('paywall-mount-element'));
    registerFeedback(document.getElementById('feedback-mount-element'));
  }
};

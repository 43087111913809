<template>
  <div>
    <slot v-if="!showMessage" :on-click="handleClick" />
    <div v-else :class="$style.message">
      {{ $trlMessage('dictionaries.outdated.message') }}
      <button @click="handleReload">{{ $trlMessage('dictionaries.outdated.button') }}</button>
    </div>
    <notifier-message v-on="pollerListeners" />
  </div>
</template>

<script>
import { MessageEvent } from '@/shared/types/poller-message';
import { getMethodsById, DIVISION_DICTIONARY } from '@/shared/api/dictionary-partial';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message';

export default {
  name: 'OutdatedPaginationWarning',
  components: { NotifierMessage },
  emits: ['action'],
  data() {
    return {
      showMessage: false,
      dictionariesUpdated: false
    };
  },
  computed: {
    pollerListeners() {
      return {
        [MessageEvent.job]: this.handleDictionaryUpdated
      };
    },
    partialMethods() {
      return getMethodsById(DIVISION_DICTIONARY);
    }
  },
  methods: {
    handleDictionaryUpdated(_, message) {
      const messageInfo = this.partialMethods.checkMessage(message);
      if (!messageInfo.matched) {
        return;
      }
      if (messageInfo.type === MessageEvent.dictionaryUpdated) {
        this.dictionariesUpdated = true;
      }
    },
    handleClick() {
      if (this.dictionariesUpdated) {
        this.showMessage = true;
      } else {
        this.$emit('action');
      }
    },
    handleReload() {
      window.location.reload();
    }
  }
};
</script>

<style module>
.message {
  color: $red-400;

  button {
    all: unset;
    text-decoration: underline;
    font: inherit;
    cursor: pointer;
  }
}
</style>

<i18n lang="json">{}</i18n>

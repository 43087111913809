<script>
import { defineComponent, h } from 'vue';

import { poller } from '@/shared/lib/poller-instance';
import { appConfig } from '@/shared/lib/config/app-config';
import { XSRFHelper } from '@/shared/lib/util/xsrf-helper';

export default defineComponent({
  name: 'NpsElementWrapper',
  props: {
    initialAnsweredId: {
      type: [Number, String],
      required: true
    },
    surveyApiPath: {
      type: String,
      required: true
    },
    answerApiPath: {
      type: String,
      required: true
    },
    surveyStatusApiPath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      answeredId: this.initialAnsweredId
    };
  },
  mounted() {
    poller.onMessage((message) => {
      if (message.event === 'feedback_survey:answered') {
        this.answeredId = message.data?.survey_id;
      }
    });
  },
  render() {
    return h(window.HuntflowNps, {
      lang: appConfig.get('locale'),
      surveyApiPath: this.surveyApiPath,
      answerApiPath: this.answerApiPath,
      surveyStatusApiPath: this.surveyStatusApiPath,
      token: XSRFHelper.getToken(),
      answeredId: this.answeredId
    });
  }
});
</script>

<i18n lang="json">{}</i18n>

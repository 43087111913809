<template>
  <div data-qa="state" :class="rootCssClass">
    <div v-if="status" data-qa="status" :class="$style.stateStatus">{{ statusTitle }}</div>
    <div data-qa="email" :class="$style.stateEmail" :title="state.email">{{ state.email }}</div>
    <div ref="reasonBlock" data-qa="reason" :class="$style.reason">
      {{ state.reason }}
    </div>
    <button
      v-if="isLongReason"
      :class="$style.showFullButton"
      type="button"
      data-qa="showFullReason"
      @click="toggleFullReason"
    >
      {{ buttonText }}
      <base-icon type="dropdown-arrow-alt" :class="$style.icon" />
    </button>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useI18N } from '@/shared/lib/use-i18n';
import { VacancyRequestStatus } from '@/shared/types/vacancy-request-status';
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'VacancyRequestStateModal',
  components: { BaseIcon },
  props: {
    state: {
      type: Object,
      required: true
    },
    first: {
      type: Boolean
    }
  },
  setup(props) {
    const i18n = useI18N();

    const status = props.state.status?.toLocaleLowerCase() ?? false; // TODO: хорошо бы к одному наименованию привести (надо пингануть бэк)
    const statusTitle =
      status === VacancyRequestStatus.PENDING && props.first
        ? i18n('approve.pending_first')
        : i18n(`approve.${status}`);
    const changedDateStr = props.state.changed
      ? props.state.changed.split(' ')[0].split('-').reverse().join('.')
      : undefined; // TODO: date formatting stuff

    const isFullReasonShown = ref(false);
    const toggleFullReason = () => {
      isFullReasonShown.value = !isFullReasonShown.value;
    };
    const buttonText = computed(() => {
      return isFullReasonShown.value ? i18n('less') : i18n('show_full');
    });

    const reasonBlock = ref(null);
    const isLongReason = ref(false);
    onMounted(() => {
      if (!reasonBlock.value) {
        return;
      }
      isLongReason.value =
        reasonBlock.value.scrollHeight >
        parseFloat(getComputedStyle(reasonBlock.value).lineHeight) * 3;
    });

    return {
      statusTitle: [statusTitle, changedDateStr].filter(Boolean).join(', '),
      status,
      buttonText,
      isLongReason,
      isFullReasonShown,
      toggleFullReason,
      reasonBlock
    };
  },
  computed: {
    rootCssClass() {
      return {
        [this.$style.state]: true,
        [this.$style.fullReason]: this.isFullReasonShown,
        [this.$style.stateApproved]: this.status === VacancyRequestStatus.APPROVED,
        [this.$style.stateRejected]: this.status === VacancyRequestStatus.REJECTED,
        [this.$style.statePending]: this.status === VacancyRequestStatus.PENDING,
        [this.$style.statePendingFirst]: this.status === VacancyRequestStatus.PENDING && this.first
      };
    }
  }
};
</script>

<i18n lang="json">
{
  "show_full": {
    "ru_RU": "Показать полностью",
    "en_US": "Show more"
  },
  "less": {
    "ru_RU": "Свернуть",
    "en_US": "Show less"
  },
  "approve.pending": {
    "ru_RU": "Далее",
    "en_US": "Next"
  },
  "approve.pending_first": {
    "ru_RU": "Ожидание согласования",
    "en_US": "Pending"
  },
  "approve.approved": {
    "ru_RU": "Согласовано",
    "en_US": "Approved"
  },
  "approve.rejected": {
    "ru_RU": "Отказ",
    "en_US": "Rejected"
  }
}
</i18n>

<style module>
.state {
  composes: form-group from global;
  composes: form-group_actions from global;
  color: $textColor;
  font-size: 16px;
  line-height: 22px;
}

.state.fullReason .icon {
  transform: rotate(180deg);
}

.statePending {
  color: #999999;
}

.stateApproved {
  color: $btnGreen;
}

.stateRejected {
  color: $red-400;
}

.statePendingFirst {
  color: $yellowColor;
}

.stateStatus {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
}

.stateEmail {
  @mixin ellipsis;
  font-weight: 500;
}

.reason {
  position: relative;
}

.state:not(.fullReason) .reason {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.showFullButton {
  @mixin reset-button-styles;
  display: block;
  margin-top: 3px;
  cursor: pointer;

  &:hover {
    color: $red-400;
  }
}
</style>

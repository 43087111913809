import { PageHelper } from '@/shared/lib/page-helper.ts';
import {
  CALENDARS_ROUTE_NAME,
  MAGIC_BUTTON_ROUTE_NAME,
  INTEGRATIONS_ROUTE_NAME,
  PERSONAL_ROUTE_NAME,
  SOCIAL_ROUTE_NAME
} from './constants';

const commonRoutes = [
  {
    name: PERSONAL_ROUTE_NAME,
    path: '/profile',
    component: () => PageHelper.lazyLoadView(import('../pages/personal'))
  },
  {
    name: CALENDARS_ROUTE_NAME,
    path: '/connections',
    component: () => PageHelper.lazyLoadView(import('../pages/calendar'))
  },
  {
    name: MAGIC_BUTTON_ROUTE_NAME,
    path: '/magic',
    component: () => PageHelper.lazyLoadView(import('../pages/magic-button'))
  },
  {
    name: SOCIAL_ROUTE_NAME,
    path: '/social',
    component: () => PageHelper.lazyLoadView(import('../pages/social'))
  },
  {
    name: INTEGRATIONS_ROUTE_NAME,
    path: '/integrations',
    component: () => PageHelper.lazyLoadView(import('../pages/integrations/integrations.vue'))
  }
];

export default commonRoutes;

<template>
  <validation-provider
    :default-errors="initialErrors"
    :default-values="initialValues"
    :resolver="resolver"
    @submit="onSubmit"
  >
    <template #default="{ handleSubmit }">
      <form-layout
        ref="form"
        :class="$style.wrapper"
        :disabled="loading"
        method="post"
        @submit="handleSubmit"
      >
        <form-template>
          <template #controls>
            <fields-layout>
              <validation-field hidden name="_xsrf" />

              <validation-field hide-errors name="email" :label="$i18n('email.caption')">
                <template #default="{ id, modelValue, onChange, name, invalid }">
                  <base-input
                    :id="id"
                    :value="modelValue"
                    :name="name"
                    data-qa="account-login-email"
                    :invalid="invalid"
                    type="text"
                    autofocus
                    big
                    @inputValue="onChange"
                  />
                </template>
              </validation-field>
            </fields-layout>
          </template>
          <template #footer>
            <base-button
              size="l"
              color="black"
              type="submit"
              data-qa="account-login-submit"
              :loading="loading"
            >
              {{ $trlMessage('button.remember') }}
            </base-button>

            <div :class="$style.error">
              <validation-errors all />
            </div>
          </template>
        </form-template>
      </form-layout>
    </template>
  </validation-provider>
</template>

<script>
import { object, string } from 'yup';

import BaseButton from '@/components/button/button.vue';
import BaseInput from '@/components/input/input.vue';
import {
  ValidationProvider,
  ValidationField,
  ValidationErrors,
  yupResolver
} from '../../../../shared/ui/validate-form';
import FormLayout from '@/shared/ui/form-layout/form-layout.vue';
import FieldsLayout from '@/shared/ui/fields-layout/fields-layout.vue';
import FormTemplate from '../../components/form-template/form-template.vue';

export default {
  name: 'RememberPure',
  components: {
    FieldsLayout,
    FormLayout,
    ValidationErrors,
    ValidationField,
    ValidationProvider,
    BaseButton,
    BaseInput,
    FormTemplate
  },
  props: {
    initialErrors: {
      type: Object,
      default: () => ({})
    },
    initialValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    resolver() {
      return yupResolver(
        object({
          email: string().email().required(this.$i18n('email.required'))
        })
      );
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$refs.form.$el.submit();
    }
  }
};
</script>

<style module>
.wrapper {
  --title-font-weight: 500;
}
.error {
  margin-top: 15px;
}
</style>

<i18n lang="json">
{
  "email.caption": {
    "ru_RU": "Зарегистрированный адрес эл. почты",
    "en_US": "Enter your email to reset password"
  },
  "email.required": {
    "ru_RU": "Введите адрес эл. почты",
    "en_US": "Enter email"
  }
}
</i18n>

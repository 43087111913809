<template>
  <analytics-sender analytics-feature="checkbox_salary" :class="$props.class" :style="style">
    <template #default="{ send }">
      <div>
        <base-dropdown
          :disabled="isAvailable"
          :content-class="$style.dropdownContent"
          @shown="send"
        >
          <template #default="{ toggle }">
            <div :class="$style.checkboxes">
              <base-checkbox
                v-if="isNew"
                :selected="checked"
                :true-value="trueValue"
                :false-value="falseValue"
                :tabindex="isAvailable ? 0 : -1"
                v-bind="$attrs"
                @change="
                  handleChange($event);
                  isAvailable ? send() : toggle();
                "
              >
                <template v-if="!isAvailable" #extra-icon>
                  <base-icon color="white" height="12" type="unavailable" />
                </template>
                <slot />
              </base-checkbox>
              <old-checkbox
                v-else
                :checked="checked"
                :true-value="trueValue"
                :false-value="falseValue"
                :tabindex="isAvailable ? 0 : -1"
                v-bind="$attrs"
                @change-value="
                  handleChange($event);
                  isAvailable ? send() : toggle();
                "
              >
                <template v-if="!isAvailable" #extra-checkbox-icon>
                  <base-icon color="white" height="12" type="unavailable" />
                </template>
              </old-checkbox>
            </div>

            <div v-if="showWarning" :class="$style.warning">
              <svg width="16" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.8 12.541 8.85.601C8.381-.2 7.617-.2 7.15.602L.2 12.542C-.269 13.344.113 14 1.048 14H14.95c.934 0 1.317-.656.85-1.459Zm-7.772-.124a1.141 1.141 0 0 1-1.146-1.136c0-.628.513-1.137 1.146-1.137.633 0 1.146.509 1.146 1.137 0 .627-.513 1.136-1.146 1.136Zm1.37-7.426-.163 3.016a1.207 1.207 0 0 1-1.193 1.12h-.027a1.223 1.223 0 0 1-1.205-1.12l-.195-3.016a1.046 1.046 0 0 1 1.06-1.121h.65a1.06 1.06 0 0 1 1.074 1.12Z"
                  fill="currentColor"
                /></svg
              >{{ $trlMessage('hide_salary.promo.warning') }}
            </div>
          </template>
          <template #content>
            <dropdown-promo
              :service-kind-code="serviceKindCode"
              :title="$trlMessage('hide_salary.promo.title')"
              data-qa="hide-salary-promo"
              description-template="hide_salary.promo.descr"
              reason="promo.tariff_upgrade.reason.hide-salary"
            />
          </template>
        </base-dropdown>
      </div>
    </template>
  </analytics-sender>
</template>

<script>
import tariffs, { ServiceKindCodes } from '../../../shared/lib/tariffs';
import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown';
import OldCheckbox from '@/components/form-fields/checkbox/checkbox.vue';
import BaseCheckbox from '@/shared/ui/base-checkbox/base-checkbox.vue';
import BaseIcon from '@/components/icon.vue';
import DropdownPromo from '@/components/promo/dropdown-promo.vue';
import AnalyticsSender from '@/components/hf/analytics-sender/analytics-sender.vue';

export default {
  name: 'HideSalaryCheckbox',
  components: {
    AnalyticsSender,
    DropdownPromo,
    BaseIcon,
    BaseDropdown,
    OldCheckbox,
    BaseCheckbox
  },
  inheritAttrs: false,
  props: {
    checked: {
      type: [Array, Number, String, Boolean],
      default: null
    },
    warnAboutReset: {
      type: Boolean,
      default: false
    },
    isNew: Boolean,
    trueValue: BaseCheckbox.props.trueValue,
    falseValue: BaseCheckbox.props.falseValue,

    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change-value'],
  data() {
    return {
      showWarning: false
    };
  },
  computed: {
    serviceKindCode() {
      return ServiceKindCodes.hide_salary;
    },
    isAvailable() {
      return tariffs.isTariffAvailable(this.serviceKindCode);
    }
  },
  mounted() {
    this.showWarning = this.checked && !this.isAvailable && this.warnAboutReset;
  },
  methods: {
    handleChange(value) {
      if (this.isAvailable) {
        this.$emit('change-value', value);
      }
    }
  }
};
</script>

<style module>
.checkboxes {
  display: inline-block;
}

.warning {
  font-size: 14px;
  line-height: 18px;
  color: #ec7e00;

  svg {
    margin-right: 6px;
  }
}

.dropdownContent {
  width: $bigWidth;
}
</style>

<i18n lang="json">{}</i18n>

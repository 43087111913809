import { nanoid } from 'nanoid';
import { trlMessage } from '@/shared/lib/i18n';
import { FileAPI } from '@/shared/api/file';

function size(pow: number) {
  return Math.pow(1024, pow);
}

const sizes = {
  KB: size(1),
  MB: size(2),
  GB: size(3),
  TB: size(4)
};

function validate(file: File) {
  if (!file.size) {
    return trlMessage('error.unknown');
  }
  if (file.size >= 15 * sizes.MB) {
    return trlMessage('file.attach.size_error', {
      name: file.name
    });
  }
}

function filter(files: File[] | FileList, callback: (file: File) => boolean) {
  const fileList = Array.from(files);
  return fileList.filter(callback);
}

function fileInfo(file: File) {
  return {
    temporalId: nanoid(),
    size: file.size,
    name: file.name,
    content_type: file.type
  };
}

export const FileHelper = {
  sizes,
  validate,
  filter,
  fileInfo,
  upload: (...args: Parameters<typeof FileAPI.upload>) => FileAPI.upload(...args)
};


    import { makeI18n } from '@/shared/lib/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/modules/vacancy-request/components/view/state.vue', {"show_full":{"ru_RU":"Показать полностью","en_US":"Show more"},"less":{"ru_RU":"Свернуть","en_US":"Show less"},"approve.pending":{"ru_RU":"Далее","en_US":"Next"},"approve.pending_first":{"ru_RU":"Ожидание согласования","en_US":"Pending"},"approve.approved":{"ru_RU":"Согласовано","en_US":"Approved"},"approve.rejected":{"ru_RU":"Отказ","en_US":"Rejected"}}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n("src/modules/vacancy-request/components/view/state.vue", baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  
<script>
import { h } from 'vue';
import { normalizeChildren } from '@/shared/lib/util/component-helpers';

export default {
  name: 'ResourceAction',
  props: {
    // TODO deprecate it
    tag: {
      type: String,
      default: 'div'
    },
    promise: {
      validator: (p) => p && typeof p.then === 'function' && typeof p.catch === 'function',
      default: null
    },
    // по хорошему сделать дефолтное значение 400 мс
    minimalLoading: {
      type: Number,
      default: null
    }
  },
  emits: ['success', 'error'],
  data() {
    return {
      loading: false
    };
  },
  watch: {
    promise: {
      handler(promise) {
        this.loading = true;
        if (!promise) {
          this.loading = false;
          return;
        }

        Promise.all([promise, new Promise((resolve) => setTimeout(resolve, this.minimalLoading))])
          .then(([data]) => {
            if (promise === this.promise) {
              this.$emit('success', data);
              this.loading = false;
            }
          })
          .catch((err) => {
            if (promise === this.promise) {
              this.$emit('error', err);
              this.loading = false;
            }
          });
      },
      immediate: true
    }
  },
  render() {
    const children = normalizeChildren(this, {
      loading: this.loading
    });
    return children.length <= 1 ? children[0] : h(this.tag, children);
  }
};
</script>

<i18n lang="json">{}</i18n>

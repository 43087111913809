<template>
  <div v-if="isVisible" :class="classes" :data-qa="dataQa" :style="style">
    <base-label data-qa="label" :class="$style.label">
      <slot name="label" :label="label">
        <span :class="$style.title" v-html="label" />
      </slot>
    </base-label>
    <view-file-list v-if="shouldShowFiles" :files="files" :class="$style.files" />
    <slot />
  </div>
</template>

<script>
import BaseLabel from '@/components/label/label';
import ViewFileList from './view-file-list';

export default {
  name: 'ViewField',
  components: {
    BaseLabel,
    ViewFileList
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    delimiter: Boolean,
    isVisible: {
      type: Boolean,
      default: true
    },
    schema: {
      type: Object,
      default: undefined
    },
    dataQa: {
      type: String,
      required: true
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  computed: {
    files() {
      return this.schema?.metadata?.files ?? [];
    },
    shouldShowFiles() {
      return this.files.length > 0;
    },
    classes() {
      return [this.$style.wrapper, { [this.$style.delimiter]: this.delimiter }, this.class];
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';

.wrapper {
  margin-bottom: var(--field-gap, 10px);
}
.files {
  position: relative;
  left: -8px;
  font-weight: 500;
}
.delimiter {
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid @borderColor;
}
.label {
  display: var(--label-display, block);
  margin-bottom: var(--label-gap, 3px);
  font-size: var(--label-font-size, 16px);
}
.title {
  font-weight: var(--title-font-weight, normal);
  text-transform: var(--title-text-transform, initial);
}
.title::after {
  content: var(--title-after-content, '');
}
</style>

<i18n lang="json">{}</i18n>

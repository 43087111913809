import { stringToIndex } from '@/shared/lib/util/search';
import { compareByOrder } from '@/shared/lib/compators/compators';

const initialState = {
  templates: (window.SmsTemplates ?? []).map((item) => ({
    ...item,
    _index: stringToIndex(item.name)
  }))
};

// TODO: дропнуть в пользу `frontend/src/api/sms-template.ts`
const gettersConfig = {
  templates: (state) => state.templates.sort(compareByOrder),
  // TODO: consts
  commonTemplates: (state, getters) =>
    (getters.templates ?? []).filter((template) => template.type === 'common'),
  reminderTemplates: (state, getters) =>
    (getters.templates ?? []).filter((template) => template.type === 'reminder')
};

export default {
  namespaced: true,
  state: initialState,
  getters: gettersConfig
};

<template>
  <base-loader
    :class="$style.container"
    :loading="loading"
    @loading="$emit('loading')"
    @loaded="$emit('loaded')"
  >
    <template #loader>
      <content-loader />
    </template>
    <template #default>
      <div data-qa="popup-layout" :class="$style.layout">
        <slot name="heading" :title="title">
          <popup-heading v-if="hasTitle">
            <render-slot :slot-content="titleSlot" data-qa="title">
              {{ title }}
            </render-slot>
          </popup-heading>
        </slot>

        <slot name="body">
          <popup-body
            v-if="hasBody"
            :single-column="singleColumn"
            :padding-disabled="paddingDisabled"
            :class="$style.body"
          >
            <template #prepend>
              <render-slot :slot-content="prependSlot" />
            </template>
            <template #main>
              <render-slot :slot-content="mainSlot" />
            </template>
            <template #side>
              <render-slot :slot-content="sideSlot" />
            </template>
            <template #append>
              <render-slot :slot-content="appendSlot" />
            </template>
          </popup-body>
        </slot>
        <div :class="{ [$style.stickyFooter]: stickyFooter }">
          <popup-footer v-if="showFooter && hasFooter">
            <error-wrapper>
              <slot name="error" />
            </error-wrapper>
            <render-slot :slot-content="footerLayoutSlot">
              <layout-buttons-line>
                <render-slot :slot-content="footerSlot" />
              </layout-buttons-line>
            </render-slot>
          </popup-footer>
        </div>
      </div>
    </template>
  </base-loader>
</template>

<script>
import { computed } from 'vue';
import BaseLoader from '@/components/loader/base-loader';
import ContentLoader from '@/components/loader/content-loader.vue';
import LayoutButtonsLine from '@/components/layout/buttons-line';
import RenderSlot from '@/components/render-slot/render-slot.vue';
import { SlotChecker } from '@/shared/lib/util/slot-checker';
import PopupHeading from './heading.vue';
import PopupBody from './body.vue';
import PopupFooter from './footer.vue';
import ErrorWrapper from './error-wrapper.vue';

export default {
  name: 'PopupLayout',
  components: {
    BaseLoader,
    ContentLoader,
    RenderSlot,
    ErrorWrapper,
    PopupHeading,
    PopupBody,
    PopupFooter,
    LayoutButtonsLine
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    singleColumn: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    stickyFooter: {
      type: Boolean,
      default: true
    },
    paddingDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['loading', 'loaded'],
  setup(props) {
    const titleSlot = SlotChecker.useSlotContent('title', { title: props.title });

    const hasTitle = computed(() => Boolean(props.title) || Boolean(titleSlot.value));

    const footerSlot = SlotChecker.useSlotContent('footer');
    const footerLayoutSlot = SlotChecker.useSlotContent('footer_layout');

    const prependSlot = SlotChecker.useSlotContent('prepend');
    const mainSlot = SlotChecker.useSlotContent('main');
    const sideSlot = SlotChecker.useSlotContent('side');
    const appendSlot = SlotChecker.useSlotContent('append');

    const hasFooter = computed(() => Boolean(footerSlot.value) || Boolean(footerLayoutSlot.value));
    const hasBody = computed(
      () =>
        Boolean(prependSlot.value) ||
        Boolean(mainSlot.value) ||
        Boolean(sideSlot.value) ||
        Boolean(appendSlot.value)
    );

    return {
      hasTitle,
      titleSlot,
      footerSlot,
      footerLayoutSlot,
      hasFooter,
      prependSlot,
      mainSlot,
      sideSlot,
      appendSlot,
      hasBody
    };
  }
};
</script>

<style module>
.container {
  --content-loader-height: var(--popup-layout-min-height, 150px);
}
.layout {
  min-height: var(--popup-layout-min-height, auto);
  display: flex;
  flex-direction: column;
}
.body {
  flex: 1 0 0;
}
.stickyFooter {
  position: sticky;
  bottom: 0;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <base-label
    :data-qa="dataQa"
    :class="[$style.label, { [$style.textOverflowHidden]: textOverflowHidden }, $props.class]"
    :style="style"
    :title="$attrs.title"
  >
    <input
      ref="checkbox"
      v-model="computedModel"
      :class="$style.input"
      type="checkbox"
      v-bind="$attrs"
      :value="value"
      :true-value="trueValue"
      :false-value="falseValue"
    />
    <slot name="checkbox">
      <span :class="$style.checkbox">
        <span :class="$style.extraCheckboxIcon">
          <slot name="extra-icon" />
        </span>
      </span>
    </slot>
    <div :class="$style.content">
      <slot />
    </div>
  </base-label>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';

export default {
  name: 'BaseCheckbox',
  components: { BaseLabel },
  inheritAttrs: false,
  props: {
    selected: {
      type: [Array, Number, String, Boolean],
      default: null
    },
    value: {
      type: [Number, String, Boolean],
      default: ''
    },
    trueValue: {
      type: [Number, String, Boolean],
      default: true
    },
    textOverflowHidden: {
      type: Boolean,
      default: false
    },
    falseValue: {
      type: [Number, String, Boolean],
      default: false
    },
    dataQa: {
      type: String,
      default: undefined
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change'],
  computed: {
    computedModel: {
      get() {
        return this.selected;
      },
      set(value) {
        this.$refs.checkbox.checked = this.selected === this.trueValue;
        this.$emit('change', value);
      }
    }
  }
};
</script>

<style module>
.label {
  position: relative;
  align-items: baseline;
  display: inline-flex;
  font-weight: normal;
}
.textOverflowHidden {
  overflow: hidden;
  white-space: nowrap;
}
.label input.input {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #bfbfbf;
  position: relative;
  border-radius: 2px;
  flex: none;
  top: 3px;
}
.checkbox::before {
  content: '';
  display: flex;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  bottom: 50%;
  margin-bottom: -8px;
  width: 16px;
  height: 16px;
  transform: scale(0);
  background: linear-gradient(0deg, #0374ff, #0374ff), #ffffff;
}
.input:checked ~ .checkbox::before {
  transform: scale(0.5);
}
.input:disabled ~ .checkbox {
  background-color: #eaeaea;
}
.input:disabled ~ .checkbox::before {
  background: #bfbfbf;
}
.input:disabled ~ .content {
  color: #999999;
}
.content {
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: auto;
}
.extraCheckboxIcon {
  position: absolute;
  left: -5px;
  top: -7px;
  display: inline-flex;
}
</style>

<i18n lang="json">{}</i18n>

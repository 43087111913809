
    import { makeI18n } from '@/shared/lib/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/components/page-layout/help-info.vue', {"feedback":{"ru_RU":"Помощь","en_US":"Support"},"helpUrlText":{"ru_RU":"Подробное иллюстрированное руководство по&nbsp;использованию Хантфлоу:<br /><a>{linkText}</a>","en_US":"We are here to help:<br /><a>{linkText}</a>"},"supportEmailText":{"ru_RU":"Вопросы по&nbsp;работе с&nbsp;программой:<br /><a>{linkText}</a>","en_US":"Questions about working with the program:<br /><a>{linkText}</a>"},"salesEmailText":{"ru_RU":"Коммерческие вопросы:<br /><a>{linkText}</a>","en_US":"Commercial questions:<br /><a>{linkText}</a>"}}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n("src/components/page-layout/help-info.vue", baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  
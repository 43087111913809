<template>
  <div>
    <base-dropdown>
      <template #default="{ toggle, shown }">
        <slot name="button" :onToggle="toggle" :show="shown"></slot>
      </template>

      <template #content="{ hide }">
        <ul :class="$style.list">
          <li>
            <button :class="$style.listBtn" @click="handleClearFontSize(hide)">
              {{ $trlMessage('wysiwyg.font_size.default_value') }}
            </button>
          </li>
          <li v-for="size in $options.sizes" :key="size.value">
            <button :class="$style.listBtn" @click="handleSelectFontSize(size, hide)">
              {{ size.value }}
            </button>
          </li>
        </ul>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown';

const SIZES = [
  { value: 10 },
  { value: 12 },
  { value: 14 },
  { value: 16 },
  { value: 18 },
  { value: 24 },
  { value: 36 }
];

export default {
  name: 'FontSize',
  sizes: SIZES,
  components: {
    BaseDropdown
  },
  emits: ['change'],
  methods: {
    handleSelectFontSize(size, hideSizeDropdown) {
      const { value } = size;
      this.$emit('change', `${value}px`);
      hideSizeDropdown();
    },
    handleClearFontSize(hideSizeDropdown) {
      this.$emit('change', null);
      hideSizeDropdown();
    }
  }
};
</script>

<style module>
.list {
  list-style: none;
  margin: 0;
  padding: 0;
  composes: small from '../../../../shared/ui/css/widths.module.css';
}

.listBtn {
  @mixin reset-button-styles;
  display: block;
  position: relative;
  width: 100%;
  padding: 12px 10px 13px 35px;
  text-align: left;

  &:hover {
    background-color: #f6f6f6;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 35px;
    right: 0;
    border-bottom: 1px solid $borderColor;
  }
}

.list li:last-child .listBtn {
  &::after {
    display: none;
  }
}
</style>

<i18n lang="json">{}</i18n>

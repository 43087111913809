<template>
  <div>
    <slot
      :items="items"
      :loading="loadingByLevel.null"
      :loading-by-level="loadingByLevel"
      :can-load-more="canLoadMoreByLevel.null"
      :can-load-more-by-level="canLoadMoreByLevel"
      :cursor-by-level="cursorByLevel"
      :next="next"
      :search-mode="searchMode"
      :query="query"
      :set-query="setQuery"
    />
  </div>
</template>

<script>
import { getMethodsById } from '@/shared/api/dictionary-partial';
import { deepField, isLabelField, parentField } from '@/components/base-list/composables/constants';
import { getItemDeep } from '@/components/base-list/composables/use-flat-list';

export default {
  name: 'CursorTreePaginator',
  props: {
    dictionaryId: {
      type: String,
      required: true
    },
    collapsible: Boolean,
    parentChildMap: {
      type: Object,
      default: () => ({})
    },
    manualReset: Boolean,
    additionalItems: {
      type: Array,
      default: () => []
    },
    showInactive: Boolean,
    inactiveSelectable: {
      type: Boolean,
      default: true
    },
    onlyAvailable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      query: '',
      searchMode: false,
      loadingByLevel: {},
      cursorByLevel: {},
      itemsByLevel: {},
      promises: new Map()
    };
  },
  computed: {
    partialMethods() {
      return getMethodsById(this.dictionaryId);
    },
    canLoadMoreByLevel() {
      return Object.entries(this.parentChildMap).reduce(
        (acc, [id]) => {
          acc[id] = this.canLoadMore(id);
          return acc;
        },
        {
          null: this.canLoadMore(null)
        }
      );
    },
    items() {
      const items = [...this.additionalItems];
      items.push(...this.getFlatItems(this.itemsByLevel.null));
      if (this.loadingByLevel.null && items.length) {
        items.push({
          [deepField]: getItemDeep(null, this.parentChildMap) + 1,
          id: `loading-${null}`,
          [parentField]: null,
          [isLabelField]: true
        });
      }
      return items;
    }
  },
  watch: {
    parentChildMap(hash, oldHash) {
      if (!Object.keys(oldHash).length || !this.manualReset) {
        return this.reset();
      }
    }
  },
  mounted() {
    if (Object.keys(this.parentChildMap).length) {
      this.reset();
    }
  },
  beforeUnmount() {
    clearTimeout(this.searchTimeout);
  },
  methods: {
    canLoadMore(id) {
      return !(this.loadingByLevel[id] || (this.itemsByLevel[id] && !this.cursorByLevel[id]));
    },
    getFlatItems(levelItems = []) {
      const items = [];
      levelItems.forEach((item) => {
        const id = item.id;
        items.push(item);
        if (this.itemsByLevel[id]?.length) {
          items.push(...this.getFlatItems(this.itemsByLevel[id]));
        }
        if (this.loadingByLevel[id]) {
          items.push({
            [deepField]: getItemDeep(id, this.parentChildMap) + 1,
            id: `loading-${id}`,
            [parentField]: id,
            [isLabelField]: true
          });
        }
      });
      return items;
    },
    reFetch(parentId = null) {
      const action = this.searchMode
        ? this.getSearch
        : this.collapsible
          ? this.getTree
          : this.getList;

      this.loadingByLevel[parentId] = true;

      return this.reFetchLevel(parentId, (cursor) => {
        return action(parentId, cursor);
      })
        .then(({ next_page_cursor, items }) => {
          this.cursorByLevel[parentId] = next_page_cursor;
          this.loadingByLevel[parentId] = false;
          this.itemsByLevel[parentId] = items;
        })
        .finally(() => {
          this.loadingByLevel[parentId] = false;
        });
    },
    fetchNext(parentId = null) {
      const cursor = this.cursorByLevel[parentId];
      if (!cursor && !this.itemsByLevel[parentId]?.length) {
        this.cursorByLevel[parentId] = undefined;
        this.loadingByLevel[parentId] = true;
        this.itemsByLevel[parentId] = [];
      }

      this.loadingByLevel[parentId] = true;

      const action = this.searchMode
        ? this.getSearch
        : this.collapsible
          ? this.getTree
          : this.getList;

      const promise = action(parentId, cursor)
        .then(({ next_page_cursor, items }) => {
          this.cursorByLevel[parentId] = next_page_cursor;
          this.itemsByLevel[parentId].push(...items);
          this.loadingByLevel[parentId] = false;
        })
        .finally(() => {
          this.promises.delete(promise);

          if (!promise.canceled) {
            this.loadingByLevel[parentId] = false;
          }
        });

      this.promises.set(promise, promise);
      return promise;
    },
    getTree(parentId, next_page_cursor) {
      return this.partialMethods
        .getTree({
          parent_id: parentId,
          include_inactive: this.showInactive,
          next_page_cursor,
          only_available: this.onlyAvailable
        })
        .then(this.prepareResult);
    },
    getSearch(parentId, next_page_cursor) {
      return this.partialMethods
        .getSearch({
          query: this.query,
          include_inactive: this.showInactive,
          next_page_cursor,
          only_available: this.onlyAvailable
        })
        .then(this.prepareResult)
        .then((result) => {
          result.items.forEach((item) => {
            item.disabled = !item.name_highlight;
          });
          return result;
        });
    },
    getList(parentId, next_page_cursor) {
      return this.partialMethods
        .getList({
          include_inactive: this.showInactive,
          next_page_cursor,
          only_available: this.onlyAvailable
        })
        .then(this.prepareResult);
    },
    prepareResult(result) {
      if (this.inactiveSelectable) {
        return result;
      }
      result.items.forEach((item) => {
        if (!item.active) {
          item.available = false;
        }
      });
      return result;
    },
    async reFetchLevel(parentId = null, callback) {
      const items = [];
      let next_page_cursor;
      do {
        const result = await callback(next_page_cursor);
        items.push(...result.items);
        next_page_cursor = result.next_page_cursor;
      } while (next_page_cursor && this.itemsByLevel[parentId].length > items.length);

      return { items, next_page_cursor };
    },
    next(parentId = null) {
      if (this.canLoadMoreByLevel[parentId] || !this.itemsByLevel[parentId]) {
        this.fetchNext(parentId);
      }
    },
    reset() {
      this.loadingByLevel = {};
      this.cursorByLevel = {};
      this.itemsByLevel = {};
      this.searchMode = !!this.query;
      Array.from(this.promises.values()).forEach((promise) => {
        promise?.cancel();
      });
      this.promises.clear();
      return this.fetchNext();
    },
    setQuery(query) {
      if (query === this.query) {
        return;
      }
      this.query = query;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.reset();
      }, 300);
    },
    handleArchiveOperations(ids, active, withChildren = true) {
      const idSet = new Set(ids);
      this.items.forEach((item) => {
        if (withChildren) {
          const parent = this.parentChildMap[item.id];
          if (idSet.has(parent)) {
            idSet.add(item.id);
          }
        }
        if (idSet.has(item.id)) {
          item.active = active;
          if (!this.inactiveSelectable) {
            item.available = active;
          }
        }
      });
    },
    operationHandle(operation, data, { forceReFetch = false } = {}) {
      if (operation === 'archive') {
        return this.handleArchiveOperations(data.ids, false);
      } else if (operation === 'restore') {
        this.handleArchiveOperations([data.id], true); // сначала помечаем активным пункт из операции и его детей
        const path = [];
        let parent = this.parentChildMap[data.id];
        while (parent) {
          path.push(parent);
          parent = this.parentChildMap[parent];
        }
        return this.handleArchiveOperations(path, true, false); // а потом всех его предков по дереву
      }
      if (this.searchMode) {
        return this.reFetch();
      }
      switch (operation) {
        case 'add':
          if (forceReFetch || !this.cursorByLevel[data.parent]) {
            return this.reFetch(data.parent);
          }
          return;
        case 'rename': {
          const parent = this.parentChildMap[data.id];
          if (forceReFetch) {
            return this.reFetch(parent);
          }
          const item = this.itemsByLevel[parent].find(({ id }) => id === data.id);
          Object.assign(item, data);
          return;
        }
        case 'unite':
        case 'move': {
          return this.reset();
        }
        case 'remove': {
          const parent = this.parentChildMap[data.id];
          return this.reFetch(parent);
        }
        default:
          throw new Error(`unknown operation ${operation}`);
      }
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

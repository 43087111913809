<template>
  <component
    :is="tag"
    type="button"
    data-qa="attach-button"
    :disabled="disabled"
    :class="className"
    @click="disabled ? $emit('disabled-click') : $emit('click')"
  >
    <base-icon type="clip" :class="$style.icon" />
    <span :class="$style.name">
      <slot name="caption">
        {{ $i18n('attach') }}
      </slot>
    </span>
  </component>
</template>

<i18n lang="json">
{
  "attach": {
    "ru_RU": "Прикрепить файл",
    "en_US": "Attach file"
  }
}
</i18n>

<script>
import { defineComponent } from 'vue';
import BaseIcon from '@/components/icon.vue';

export default defineComponent({
  name: 'AttachmentAddButton',
  components: { BaseIcon },
  props: {
    disabled: Boolean
  },
  emits: ['click', 'disabled-click'],
  computed: {
    tag() {
      return this.disabled ? 'div' : 'button';
    },
    className() {
      return {
        [this.$style.button]: true,
        [this.$style.disabled]: this.disabled,
        [this.$style.gray]: true,
        [this.$style.xs]: true
      };
    }
  }
});
</script>

<style module src="@/components/button/button.module.css"></style>

<template>
  <base-quotas-modal
    :open="open"
    :fetch-method="getAllRequests"
    paginate-by-cursor
    @change="$emit('change', $event)"
    @request-removed="handleRequestRemoved"
    @close="handleClose"
  >
    <template #caption>
      {{ $trlMessage('pending_request.modal.title') }}
    </template>
    <template #quota="{ quota: currentQuota, schemaMap, schemasFields, handleShowQuota, remove }">
      <quota-item
        :class="$style.quota"
        data-qa="quota"
        :quota="currentQuota"
        :schema-map="schemaMap"
        :schemas-fields="schemasFields"
        @show-quota="
          handleShowQuota($event, removedRequests.includes(currentQuota.id));
          checkQuotas(remove);
        "
      >
        <template #before>
          <request-list-item-status :quota="currentQuota" data-qa="status" :class="$style.status" />
        </template>
        <template #after>
          <base-button
            v-if="currentQuota.next_decision_link"
            data-qa="copy-button"
            :class="$style.copyButton"
            @click="copyApproveLink(currentQuota)"
          >
            {{ $trlMessage('pending_request.modal.copy_link') }}
          </base-button>
        </template>
      </quota-item>
    </template>
    <template #empty>
      <div :class="$style.empty">
        {{ $trlMessage('pending_request.modal.no_requests') }}
      </div>
    </template>
    <template #footer>
      <notifier-message v-on="pollerListeners" />
    </template>
  </base-quotas-modal>
</template>

<script>
import { BaseQuotasModal } from '@/components/quotas-modal';
import RequestListItemStatus from '@/components/hf/request-list-item/status';
import BaseButton from '@/components/button/button';
import QuotaItem from '@/components/quota-list/quota-item';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message';
import { notification } from '@/shared/lib/notification/notification';
import vacancyRequestsAPI from '@/shared/api/vacancy-request';
import { MessageEvent } from '@/shared/types/poller-message';
import { VacancyRequestStatus } from '@/shared/types/vacancy-request-status';

export default {
  name: 'PendingRequestsModal',
  components: {
    BaseQuotasModal,
    RequestListItemStatus,
    BaseButton,
    QuotaItem,
    NotifierMessage
  },
  props: {
    open: BaseQuotasModal.props.open
  },
  emits: ['change'],
  data() {
    return {
      quotas: [],
      hiddenQuotas: [],
      processedRequests: [],
      removedRequests: []
    };
  },
  computed: {
    pollerListeners() {
      return {
        [MessageEvent.vacancyRequestEdit]: (data) => this.handleRequestChanges('edit', data),
        [MessageEvent.vacancyRequestRemove]: (data) => this.handleRequestChanges('remove', data)
      };
    }
  },
  methods: {
    copyApproveLink(quota) {
      if (this.processedRequests.includes(quota.id) || this.removedRequests.includes(quota.id)) {
        notification.notify({
          content: this.$trlMessage('pending_request.modal.notification.no_pending_approval'),
          isError: true
        });
        return;
      }
      navigator.clipboard.writeText(quota.next_decision_link).then(() => {
        notification.notify({
          content: this.$trlMessage('pending_request.modal.notification.link_copied')
        });
      });
    },
    handleRequestChanges(eventName, data) {
      switch (eventName) {
        case 'edit': {
          this.quotas.find((quota) => quota.id === data.id).states = data.states;
          const traverser = (status) => (state) => state.status?.toLocaleLowerCase?.() === status;
          const passedOrRejected =
            data.states.every(traverser(VacancyRequestStatus.APPROVED)) ||
            data.states.some(traverser(VacancyRequestStatus.REJECTED));
          if (passedOrRejected) {
            this.processedRequests.push(data.id);
          }
          break;
        }

        case 'remove':
          this.removedRequests.push(data.id);
          break;
      }
    },
    getAllRequests(cursor) {
      return vacancyRequestsAPI.getPendingRequests({ cursor }).then((response) => {
        this.quotas = response.items;
        return response;
      });
    },
    checkQuotas(removeQuota) {
      for (let i = this.quotas.length - 1; i >= 0; i--) {
        const id = this.quotas[i].id;
        if (this.processedRequests.includes(id) || this.removedRequests.includes(id)) {
          this.quotas.splice(i, 1);
          removeQuota(i);
        }
      }
    },
    handleRequestRemoved(data, removeQuota) {
      const index = this.quotas.findIndex((quota) => quota.id === data.id);
      this.quotas.splice(index, 1);
      removeQuota(index);
    },
    handleClose() {
      this.processedRequests = [];
      this.removedRequests = [];
    }
  }
};
</script>

<style lang="less" module>
.quota {
  flex-direction: column;
  align-items: flex-start;
}

.status {
  margin-bottom: 8px;
}

.copyButton {
  margin-top: calc(8px - var(--field-gap));
}

.empty {
  padding: 20px 0;
}
</style>

<i18n lang="json">{}</i18n>

import { nextTick } from 'vue';
import debounce from 'lodash/debounce';
import { emitter } from '@/shared/lib/emitter/emitter';
import { CancelablePromise } from '@/shared/api/utils/cancelable-promise';

// В условии задачи написана цифра 1000ms,
// но в реальности спустя 1000ms только-только
// успевает отобразиться скелетон, поэтому цифру чуть увеличил
const CENTRAL_ZONES_DELAY = 1500;
// Используется, чтобы отличать ситуацию когда
// promise'ы были добавлены и уже завершились,
// или они еще не были добавлены
const ZONE_STATE_PRISTINE = -1;
const ZONE_STATE_FULFILLED = 0;

/* Зоны:
 * 1. Шапка
 * 2. Сайдбар
 * 3. Список вакансий
 * 4. Статусы
 * 5. Картина дня - заголовок (изначально он не был отдельной зоной, но это нужно для правильного показа скелетонов)
 * 6. Картина дня - фильтры
 * 7. Картина дня - контент/сводка
 * ┌──────────────────────────┐
 * │            1             │
 * ├─────┬────────────────────┤
 * │     ├──────── 4 ─────────┤
 * │  2  ├──────── 5 ─────────┤
 * ├─────┤         6          │
 * │  3  ├────────────────────┤
 * │     │                    │
 * │     │         7          │
 * │     │                    │
 * └─────┴────────────────────┘
 */
export enum SkeletonZones {
  HEADER = 'header',
  SIDEBAR_GENERAL = 'sidebar-general',
  SIDEBAR_VACANCIES = 'sidebar-vacancies',
  STATUSES = 'statuses',
  DASHBOARD_HEADING = 'dashboard-heading',
  DASHBOARD_FILTERS = 'dashboard-filters',
  DASHBOARD_CONTENT = 'dashboard-content'
}
export enum SkeletonEvents {
  CONTENT_APPEARING = 'content:appearing'
}
type PromiseType = Promise<unknown> | CancelablePromise<unknown>;

const CENTRAL_ZONES = [
  SkeletonZones.STATUSES,
  SkeletonZones.DASHBOARD_HEADING,
  SkeletonZones.DASHBOARD_FILTERS,
  SkeletonZones.DASHBOARD_CONTENT
];
// ZONE_STATE_PRISTINE используется, чтобы отличать ситуацию когда
// promise'ы были добавлены и уже завершились,
// или они еще не были добавлены
const pendingPromises: Record<SkeletonZones, number> = {
  [SkeletonZones.HEADER]: ZONE_STATE_PRISTINE,
  [SkeletonZones.SIDEBAR_GENERAL]: ZONE_STATE_PRISTINE,
  [SkeletonZones.SIDEBAR_VACANCIES]: ZONE_STATE_PRISTINE,
  [SkeletonZones.STATUSES]: ZONE_STATE_PRISTINE,
  [SkeletonZones.DASHBOARD_HEADING]: ZONE_STATE_PRISTINE,
  [SkeletonZones.DASHBOARD_FILTERS]: ZONE_STATE_PRISTINE,
  [SkeletonZones.DASHBOARD_CONTENT]: ZONE_STATE_PRISTINE
};
const initialTime = Date.now();

// Чтобы при загрузке страницы скелетоны появились одновременно
let debounceDelay = 200;
const initCallbacks: Array<() => void> = [];
const flushInitCallbacks = () => {
  // Ждем nextTick, чтобы не было ситуации, что callback запущен,
  // а <TransitionGroup> в скелетоне не стал делать emit событий
  nextTick(() => {
    initCallbacks.forEach((cb) => cb());
    initCallbacks.length = 0;
  });
};
const debouncedFlush = debounce(
  () => {
    debounceDelay = 0;
    flushInitCallbacks();
  },
  debounceDelay,
  { maxWait: 1000 } // Чтобы бесконечно не ждать зависшие блоки
);
const init = (cb: () => void): void => {
  initCallbacks.push(cb);
  if (debounceDelay) {
    // Delay нужен только при инициализации
    debouncedFlush();
  } else {
    flushInitCallbacks();
  }
};

// Практически monkey-patching 🐒
const patchPromise = (zone: SkeletonZones, promise: PromiseType): PromiseType => {
  if (pendingPromises[zone] === ZONE_STATE_PRISTINE) {
    pendingPromises[zone] = 1;
  } else {
    pendingPromises[zone]++;
  }
  promise.finally(() => {
    if (--pendingPromises[zone] === ZONE_STATE_FULFILLED) {
      flushSubscriptions(zone);
    }
  });
  return promise;
};

const subscriptions: Record<SkeletonZones, Array<() => void>> = {
  [SkeletonZones.HEADER]: [],
  [SkeletonZones.SIDEBAR_GENERAL]: [],
  [SkeletonZones.SIDEBAR_VACANCIES]: [],
  [SkeletonZones.STATUSES]: [],
  [SkeletonZones.DASHBOARD_HEADING]: [],
  [SkeletonZones.DASHBOARD_FILTERS]: [],
  [SkeletonZones.DASHBOARD_CONTENT]: []
};

let isFontsLoaded = false;
const pendingZones: SkeletonZones[] = [];
// Шрифты могут не загрузиться на момент,
// когда все зоны уже готовы, и произойдет FOIT
const onFontsLoaded = () => {
  isFontsLoaded = true;
  pendingZones.forEach((zone) => flushSubscriptions(zone));
};
// Проверка на Safari
if (navigator.vendor.includes('Apple')) {
  // TODO: убрать когда в Safari заработает 'loadingdone'
  // Safari как всегда... - 'loadingdone' там не срабатывает,
  // и хоть 'fonts.ready' может сработать раньше нужного,
  // но городить логику из timeout'ов для проверки шрифта не резон
  document.fonts.ready.then(onFontsLoaded);
} else {
  document.fonts.addEventListener('loadingdone', onFontsLoaded, { once: true });
}

const queue: Array<() => void> = [];
const runQueue = () => {
  queue.forEach((cb) => cb());
  queue.length = 0;
  maxQueueSize = 0;
};
let maxQueueSize = 0;

let timeout: ReturnType<typeof setTimeout>;
const flushSubscriptions = (zone: SkeletonZones) => {
  if (!isFontsLoaded) {
    pendingZones.push(zone);
    return;
  }

  // Специальное условие загрузки для зон 3, 4, 5
  // Сначала пытаемся загрузить их все сразу:
  // Если за 1000ms мы смогли загрузить сразу все зоны, то показываем всю страницу
  // Если не смогли, то отображаем по мере загрузки
  const showingDelay = initialTime + CENTRAL_ZONES_DELAY - Date.now();
  if (showingDelay > 0) {
    clearTimeout(timeout);
    timeout = setTimeout(runQueue, showingDelay);
  }

  subscriptions[zone].forEach((cb) => {
    if (CENTRAL_ZONES.includes(zone) && showingDelay > 0) {
      queue.push(cb);
      // Момент, когда мы понимаем, что
      // в центральной зоне всё загружено
      if (queue.length === maxQueueSize) {
        clearTimeout(timeout);
        runQueue();
      }
      return;
    }
    cb();
  });

  // Автоматически очищаем подписки, поскольку
  // при обновлении страницы они будут добавлены вновь
  subscriptions[zone] = [];
};

const subscribe = (zone: SkeletonZones, cb: () => void): void => {
  if (CENTRAL_ZONES.includes(zone)) {
    maxQueueSize++;
  }
  subscriptions[zone].push(cb);
  // Если onLoad() был добавлен позже,
  // чем сработали promise'ы
  if (pendingPromises[zone] === ZONE_STATE_FULFILLED) {
    flushSubscriptions(zone);
  }
};

const on = (eventName: SkeletonEvents, cb: () => void): void => {
  emitter.on(`skeleton.${eventName}`, cb);
};

const off = (eventName: SkeletonEvents, cb: () => void): void => {
  emitter.off(`skeleton.${eventName}`, cb);
};

const emit = (eventName: SkeletonEvents, zone: SkeletonZones): void => {
  emitter.emit(`skeleton.${eventName}`, zone);
};

export const SkeletonHelper = {
  init,
  patchPromise,
  subscribe,
  on,
  off,
  emit
};

<script>
import { computed, ref } from 'vue';

import { IndexSearch } from '@/shared/lib/util/index-search';
import { normalizeChildren } from '@/shared/lib/util/component-helpers';

export default {
  name: 'LocalItemsFilter',
  props: {
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    excludeIds: {
      type: Array,
      default: () => []
    },
    defaultQuery: {
      type: String,
      default: ''
    },
    highlight: Boolean
  },
  setup(props) {
    const query = ref('');

    const indexSearchRef = computed(() => {
      return new IndexSearch(excludeArray(props.items, props.excludeIds), {
        fields: props.fields,
        highlight: props.highlight
      });
    });

    const actualItems = computed(() => {
      return indexSearchRef.value.search(props.defaultQuery || query.value);
    });

    function setQuery(q) {
      query.value = q;
    }

    function excludeArray(array, excludeIds = []) {
      // Мапа, ибо перфоманс
      const map = new Map();
      array.forEach((item) => {
        map.set(item.id, item);
      });
      excludeIds.forEach((id) => {
        if (map.get(id)) {
          map.delete(id);
        }
      });
      return [...map.values()];
    }

    return {
      query,
      actualItems,
      setQuery
    };
  },
  render() {
    const children = normalizeChildren(this, {
      items: this.actualItems,
      query: this.query,
      setQuery: this.setQuery
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>

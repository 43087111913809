<template>
  <component
    :is="component"
    :type="isButton ? 'button' : undefined"
    :disabled="isButton ? disabled : undefined"
    :class="className"
    :href="isLink ? href : undefined"
  >
    <template v-if="loading">
      <base-loader size="xs" />

      <base-attachment-remove-button :has-error @click="handleRemoveUploadingClick" />
    </template>

    <template v-else>
      <base-icon v-if="icon" :type="icon" :class="$style.icon" />
      <span ref="title" :class="$style.titleText">
        <slot name="title" :title="title">
          <span v-html="title"></span>
        </slot>
      </span>

      <base-attachment-remove-button
        v-if="canRemove"
        :has-error
        @click="handleRemoveAttachmentClick"
      />
    </template>
  </component>
</template>

<script>
import BaseIcon from '@/components/icon.vue';
import BaseLoader from '@/components/loader/loader.vue';
import BaseAttachmentRemoveButton from './base-attachment-remove-button.vue';

export default {
  name: 'BaseAttachment',
  components: {
    BaseIcon,
    BaseLoader,
    BaseAttachmentRemoveButton
  },
  props: {
    icon: {
      type: String,
      default: undefined
    },
    loading: Boolean,
    title: {
      type: String,
      default: ''
    },
    canRemove: {
      type: Boolean,
      default: true
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledLike: {
      type: Boolean,
      default: false
    },
    updateOnMount: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: undefined
    },
    // ассоциированное с прикреплением значение,
    // не выводится, но используется для анимации при его изменении
    value: {
      type: [Object, File],
      default: undefined
    }
  },
  emits: ['remove', 'cancel', 'update'],
  computed: {
    clickable() {
      return this.isLink || Boolean(this.$attrs.onClick);
    },
    component() {
      if (this.loading) {
        return 'span';
      }
      if (this.href) {
        return 'a';
      }
      if (this.tag) {
        return this.tag;
      }
      return 'span';
    },
    isButton() {
      return this.component === 'button';
    },
    isLink() {
      return this.component === 'a';
    },
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.clickable]: this.clickable,
        [this.$style.error]: this.hasError,
        [this.$style.disabledLike]: this.disabledLike
      };
    }
  },
  watch: {
    value: {
      handler() {
        this.shouldEmitUpdate = this.shouldEmitUpdate || this.updateOnMount;
        if (!this.shouldEmitUpdate) {
          this.shouldEmitUpdate = true;
          return;
        }
        this.$nextTick(() => {
          this.$emit('update', this.$el);
          this.highlight();
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    highlight() {
      const node = this.$refs.title;
      if (!node) {
        return;
      }
      const className = this.$style.animatedTitle;
      node.addEventListener('animationend', () => node.classList.remove(className), {
        once: true
      });
      node.classList.add(className);
    },
    handleRemoveAttachmentClick() {
      this.$emit('remove', this.value.temporalId);
    },
    handleRemoveUploadingClick(event) {
      this.$emit('cancel', this.value.temporalId);
      this.$emit('remove', event);
    }
  }
};
</script>

<style module>
.root {
  @mixin ellipsis;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  min-width: 0;
  padding: 2px 8px;
  margin: 0;
  border-radius: 24px;
  background-color: var(--bg-primary-default, #fff);
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: $black;
  min-height: 28px;
  text-decoration: none;
  cursor: default;
}

.clickable {
  cursor: pointer;
  &:hover {
    background-color: $black-6;
  }
}

.icon {
  flex: none;
}

.root[type='button'],
.root[href] {
  cursor: pointer;

  .titleText {
    color: $linkColor;

    &:hover {
      color: $linkHoverColor;
    }
  }
}

.disabledLike {
  color: $gray-700;
}

.root[type='button']:hover {
  color: $red-400;
}

.disabledLike[type='button']:disabled,
.root[type='button']:disabled {
  color: $gray-700;
  &:hover {
    color: $gray-700;
  }
  .icon {
    color: inherit;
  }
}

.root[type='button']:disabled {
  cursor: default;
}

.titleText {
  @mixin ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.error {
  background-color: rgba(255, 0, 0, 0.06);
  color: $red-500;
}

@keyframes baseAttachmentHighlight {
  0% {
    color: $blueColor;
  }
  50% {
    color: $blueColor;
  }
  100% {
    color: $black;
  }
}

.animatedTitle {
  animation: baseAttachmentHighlight 0.8s;
}
</style>

<i18n lang="json">{}</i18n>

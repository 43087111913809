import { DictionaryStore } from '@/shared/lib/dictionary/dictionary';
import { DIVISION_DICTIONARY, REGION_DICTIONARY } from '@/shared/api/dictionary-partial';
import { LegacySchemaType } from './legacy-schema-type.ts';

export const isRoot = (field) => typeof field.schema === 'object';
export const isComplex = (field) => field.type === LegacySchemaType.COMPLEX;

export const isDictionary = (field) => field.type === LegacySchemaType.DICTIONARY;
export const isDivision = (field) => field.type === LegacySchemaType.DIVISION;
export const isRegion = (field) => field.type === LegacySchemaType.REGION;
export const isHtml = (field) => field.type === LegacySchemaType.HTML;
export const isInteger = (field) => field.type === LegacySchemaType.INTEGER;
export const isSelect = (field) => field.type === LegacySchemaType.SELECT;
export const isString = (field) => field.type === LegacySchemaType.STRING;
export const isText = (field) => field.type === LegacySchemaType.TEXT;
export const isDate = (field) => field.type === LegacySchemaType.DATE;
export const isHidden = (field) => field.type === LegacySchemaType.HIDDEN;
export const isUri = (field) => field.type === LegacySchemaType.URL;
export const isContract = (field) => field.type === LegacySchemaType.CONTRACT;
export const isReason = (field) => field.type === LegacySchemaType.REASON;
export const isIncome = (field) => field.type === LegacySchemaType.INCOME;
export const isCompensation = (field) => field.type === LegacySchemaType.COMPENSATION;
export const isPositionStatus = (field) => field.type === LegacySchemaType.POSITION_STATUS;

export const isMultiple = (field) => Boolean(field.multiple);
export const isDictionaryLike = (field) =>
  [isDictionary, isDivision, isRegion].some((predicate) => predicate(field));
export const isNumberLike = (field) =>
  [isDictionaryLike, isInteger].some((predicate) => predicate(field));
export const isComplexLike = (field) =>
  [isComplex, isContract, isReason, isIncome, isCompensation, isPositionStatus].some((predicate) =>
    predicate(field)
  );
export const isRequired = (field) => field.required && !isComplexLike(field);

export const getDictionaryId = (field) => {
  if (isDivision(field)) {
    return DIVISION_DICTIONARY;
  }
  if (isRegion(field)) {
    return REGION_DICTIONARY;
  }
  return field.dictionary;
};

export const getSortedFieldNames = (entries) => {
  return [...entries].sort(([, a], [, b]) => a.order - b.order).map(([key]) => key);
};

export const getRequiredFieldNames = (entries) => {
  return entries.filter(([, field]) => isRequired(field)).map(([key]) => key);
};

const getDictionaryIds = (fieldsObj) => {
  const fields = Object.values(fieldsObj);
  const dictionaryIds = [];

  while (fields.length) {
    const field = fields.pop();
    if (isComplexLike(field)) {
      fields.push(...Object.values(field.fields));
      continue;
    }
    if (!isDictionary(field)) {
      continue;
    }
    dictionaryIds.push(field.dictionary);
  }

  return dictionaryIds;
};

export const preloadDictionaries = (legacySchema) => {
  const ids = getDictionaryIds(legacySchema.schema);
  return Promise.all(
    ids.map((id) => {
      return DictionaryStore.loadDictionary(id).then((dictionary) => [id, dictionary]);
    })
  ).then((entries) => {
    return Object.fromEntries(entries);
  });
};

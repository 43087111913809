import '@/app/polyfills';
import '@less/main.less';
import './helpers/global-preferences';

import { rememberBackUrlHash } from '../../shared/lib/back-url-restore';
import LoginForm from '@/modules/auth/pages/login/login-pure.vue';
import RememberForm from '@/modules/auth/pages/remember/remember-pure.vue';
import RestoreForm from '@/modules/auth/pages/restore/restore-pure.vue';
import MergeForm from '@/modules/auth/pages/merge/merge-pure.vue';
import RevertForm from '@/modules/auth/pages/revert/revert-form.vue';

import { initLoginPage, MAX_REPEAT_PASSWORD } from './helpers/login-page';

rememberBackUrlHash();

const Pages = [
  {
    component: LoginForm,
    id: '#login-form',
    props: () => ({
      isLogin: Boolean(window.FORM_INFO.isLogin),
      isCustomLogin: Boolean(window.FORM_INFO.isCustomLogin),
      settingsAuth: window.FORM_INFO.settingsAuth,
      invite: window.FORM_INFO.invite,
      methods: window.FORM_INFO.methods?.items || []
    })
  },
  {
    component: RememberForm,
    id: '#remember-form'
  },
  {
    component: RestoreForm,
    id: '#restore-form',
    props: () => ({
      maxRepeatPassword: MAX_REPEAT_PASSWORD
    })
  },
  {
    component: MergeForm,
    id: '#merge-form'
  },
  {
    component: RevertForm,
    id: '#revert-form'
  }
];

const currentPage = Pages.find((el) => document.querySelector(el.id));
if (currentPage) {
  initLoginPage(currentPage.id, currentPage.component, currentPage.props);
}

<template>
  <resource-action :tag="tag" :promise="promise" @success="handleSuccess">
    <template #default="{ loading }">
      <slot
        :loading="loading"
        :set-fields="setSelectedFields"
        :schemas="schemas"
        :schemas-fields="schemasFields"
        :selected-schemas-fields="selectedSchemasFields"
      />
    </template>
  </resource-action>
</template>

<script>
import jsonPointer from 'json-pointer';
import { StorageHelper } from '@/shared/lib/util/storage-helper';
import { AdditionalSchemaKeys } from '@/components/hf/request-list-item/additional-schema-keys';
import ResourceAction from '@/components/resource-action/resource-action';
import { SchemaConverterAPI } from '@/shared/api/schema-converter';
import { setObjectTemplateComponents } from '@/components/vjsf/helpers/set-object-template-components';
import { VacancyRequestHelpers } from '@/components/vjsf/helpers/vacancy-request-helpers';

function getStorageKey(schemaId) {
  return `vacancy-request-${schemaId}-fields`;
}

const DEFAULT_FIELDS = [AdditionalSchemaKeys.watcher, '/account_division'];

export default {
  name: 'SchemasResource',
  components: {
    ResourceAction
  },
  props: {
    tag: ResourceAction.props.tag,
    schema: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      schemas: [],
      selectedSchemasFields: {}
    };
  },
  computed: {
    promise() {
      const promise = this.schema
        ? Promise.resolve([this.schema])
        : SchemaConverterAPI.getVacancyRequestSchemasClient({ onlyActive: false });

      return promise.then((schemas) =>
        schemas.map((schema) => ({
          ...schema,
          ui_schema: setObjectTemplateComponents(schema.ui_schema)
        }))
      );
    },
    selectedSchemasFieldsKey() {
      return JSON.stringify(this.selectedSchemasFields);
    },
    schemasFields() {
      return this.schemas.reduce((acc, vacancyRequestSchema) => {
        const fieldPaths = [
          {
            id: AdditionalSchemaKeys.watcher,
            title: this.$trlMessage('vacancy_request.popup.watcher')
          },
          {
            id: AdditionalSchemaKeys.takenBy,
            title: this.$trlMessage('vacancy_request.popup.taken')
          },
          ...VacancyRequestHelpers.getFilterFields(vacancyRequestSchema)
        ];
        acc[vacancyRequestSchema.id] = fieldPaths;
        return acc;
      }, {});
    }
  },
  watch: {
    schemasFields(schemas) {
      this.selectedSchemasFields = Object.entries(schemas).reduce((acc, [id, fields]) => {
        const selectedFields = StorageHelper.getJSON(getStorageKey(id)) || DEFAULT_FIELDS;
        acc[id] = fields.filter(({ id }) => selectedFields.includes(id));
        return acc;
      }, {});
    },
    selectedSchemasFieldsKey() {
      this.schemas.forEach((vacancyRequestSchema) => {
        const schemaFields = this.schemasFields[vacancyRequestSchema.id];
        const selectedSchemaFieldIds = this.selectedSchemasFields[vacancyRequestSchema.id].map(
          ({ id }) => id
        );
        schemaFields.forEach(({ id }) => {
          if (Object.values(AdditionalSchemaKeys).includes(id)) {
            return;
          }
          const field = jsonPointer.get(vacancyRequestSchema.ui_schema, id + '/ui:options');
          if (field) {
            field.isVisible = selectedSchemaFieldIds.includes(id);
          }
        });
      });
    }
  },
  methods: {
    handleSuccess(schemas) {
      this.schemas = schemas;
    },
    setSelectedFields({ id, fields }) {
      StorageHelper.setJSON(
        getStorageKey(id),
        fields.map(({ id }) => id)
      );
      this.selectedSchemasFields[id] = fields;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

<template>
  <slot name="default" :trigger="getTrigger" />
  <layer-portal
    v-if="isShown"
    ref="layer"
    tabindex="0"
    data-ignore-outside-click
    :content-class="[$style.tooltip, contentClass]"
    :content-style="contentStyle"
    v-bind="$attrs"
  >
    <template #default>
      <slot name="content" :hide="hide" />
    </template>
  </layer-portal>
</template>

<script>
import { computePosition, autoUpdate, offset, flip, shift } from '@floating-ui/dom';
import { ref, watch, nextTick, computed, defineComponent, onBeforeUnmount } from 'vue';

import LayerPortal from '@/shared/ui/layer-portal/layer-portal.vue';

export default defineComponent({
  name: 'BaseTooltip',
  components: {
    LayerPortal
  },
  inheritAttrs: false,
  props: {
    shown: Boolean,
    disabled: Boolean,
    placement: {
      type: String,
      default: 'bottom-start'
    },
    distance: {
      type: Number,
      default: 0
    },
    shift: {
      type: Number,
      default: 0
    },
    boundary: {
      type: Element,
      default: undefined
    },
    overflowPadding: {
      type: [Number, Object],
      default: 0
    },
    contentClass: LayerPortal.props.contentClass,
    flip: {
      type: Boolean,
      default: true
    },
    willChange: {
      type: String,
      default: 'transform'
    },
    strategy: {
      type: String,
      default: 'absolute'
    },
    middlewares: {
      type: Array,
      default: () => []
    },
    preventOverflowOnCrossAxis: {
      type: Boolean,
      default: false
    },
    interactive: Boolean
  },
  emits: ['update:shown', 'shown', 'hidden', 'dispose', 'update'],
  setup(props, { emit }) {
    const trigger = ref(null);
    const layer = ref(null);
    const isShown = ref(false);
    const contentStyle = ref({});
    const isFocus = ref(false);
    const stopAutoUpdate = ref();

    const content = computed(() => {
      return layer.value?.getContent();
    });

    const middlewares = computed(() => {
      const middlewares = [
        offset({
          mainAxis: props.distance,
          crossAxis: props.shift
        })
      ];

      if (props.flip) {
        middlewares.push(
          flip({
            padding: props.overflowPadding,
            boundary: props.boundary
          })
        );
      }
      middlewares.push(
        shift({
          crossAxis: props.preventOverflowOnCrossAxis ?? false,
          padding: props.overflowPadding,
          boundary: props.boundary
        })
      );

      return middlewares.concat(props.middlewares);
    });

    const setDefaultContentStyle = () => {
      contentStyle.value = {};
    };

    watch(trigger, (target, oldTarget) => {
      if (target === oldTarget) {
        return;
      }
      if (oldTarget) {
        oldTarget.removeEventListener('mouseenter', mouseEnter);
        oldTarget.removeEventListener('mouseleave', mouseLeave);
      }
      if (target) {
        target.addEventListener('mouseenter', mouseEnter);
        target.addEventListener('mouseleave', mouseLeave);
      }
    });

    watch(
      () => props.disabled,
      (disabled) => {
        if (disabled) {
          dispose();
        } else {
          init();
        }
      }
    );

    watch(
      () => props.shown,
      (shown) => {
        if (shown) {
          show();
        } else {
          hide();
        }
      }
    );

    const mouseEnter = (event) => {
      window.removeEventListener('mousemove', mouseMove);
      show(event);
    };

    const mouseLeave = (event) => {
      if (!props.interactive) {
        hide(event);
        return;
      }
      window.addEventListener('mousemove', mouseMove, {
        passive: true
      });
    };

    const mouseMove = (event) => {
      if (trigger.value?.contains(event.target) || content.value?.contains(event.target)) {
        return;
      }

      window.removeEventListener('mousemove', mouseMove);
      hide();
    };

    const show = (event) => {
      if (props.disabled || isShown.value) {
        return;
      }
      if (event instanceof Event) {
        event.preventDefault();
      }
      // подписываемся на все события скроллов, т.к. у нас всегда контент выносится в конец боди,
      // при вложении нескольких подобных элементов друг в друга последующие перестают позиционироваться,
      // т.к. находятся не в скролящямся элементе
      window.addEventListener('scroll', updatePosition, {
        capture: true,
        passive: true
      });

      isShown.value = true;
      nextTick(() => {
        stopAutoUpdate.value = autoUpdate(trigger.value, content.value, updatePosition, {
          ancestorScroll: false
        });
        emit('update:shown', true);
        emit('shown');
      });
    };

    const hide = (event) => {
      if (!isShown.value) {
        return;
      }
      if (event instanceof Event) {
        event.preventDefault();
      }
      window.removeEventListener('scroll', updatePosition, {
        capture: true,
        passive: true
      });
      stopAutoUpdate.value?.();
      isShown.value = false;
      setDefaultContentStyle();
      emit('update:shown', false);
      emit('hidden');
    };

    const updatePosition = () => {
      return computePosition(trigger.value, content.value, {
        strategy: props.strategy,
        placement: props.placement,
        middleware: middlewares.value
      }).then((data) => {
        const { x, y, strategy } = data;

        contentStyle.value = {
          ...contentStyle.value,
          position: strategy,
          top: '0',
          left: '0',
          transform: `translate3d(${Math.round(x)}px, ${Math.round(y)}px, 0)`,
          /**
           * Свойство willChange настраиваемое, чтобы исправить проблему
           * с размытием контента в конкретных dropdown'ах;
           * Подробная хронология и описание тут:
           * https://huntflow.atlassian.net/browse/SRV-22656
           */
          willChange: props.willChange
        };

        nextTick(() => {
          emit('update');
        });
      });
    };

    onBeforeUnmount(() => {
      trigger.value?.removeEventListener('mouseenter', mouseEnter);
      trigger.value?.removeEventListener('mouseleave', mouseLeave);
      dispose();
    });

    const dispose = () => {
      hide();
      emit('dispose');
    };

    const init = () => {
      setDefaultContentStyle();
      if (props.shown) {
        show();
      }
    };

    return {
      getTrigger: (value) => {
        trigger.value = value?.$el || value;
      },
      hide,
      show,
      isShown,
      isFocus,
      contentStyle,
      layer,
      trigger
    };
  }
});
</script>

<style module>
.tooltip {
  width: max-content;
  background-color: $black-90;
  color: #fff;
  border-radius: 8px;
  font-size: 14px;
  opacity: 0.9;
  line-height: 20px;
  white-space: normal;
  padding: 8px 12px;
}
</style>

<i18n lang="json">{}</i18n>

import { reactive } from 'vue';
import keyBy from 'lodash/keyBy';
import { IUserPermissions } from '@/shared/types/user-permissions';
import { UserPermissionType } from '@/shared/types/user-permission-type';
import { MemberType } from '@/shared/types/member-type';

class UserPermissions {
  #permissions: Readonly<IUserPermissions>;
  constructor(userPermissions: IUserPermissions) {
    this.#permissions = reactive(userPermissions);
  }

  get<T extends keyof IUserPermissions>(key: T) {
    if (!this.#permissions) {
      console.warn('Trying to get key from undefined permissions object in UserPermissions');
      return undefined;
    }
    return this.#permissions[key];
  }

  get id() {
    return this.#permissions?.id;
  }

  get nick() {
    return this.#permissions?.nick;
  }

  get isOwner() {
    return isOwner(this.#permissions);
  }

  get isWatcher() {
    return isWatcher(this.#permissions);
  }

  get isManager() {
    return isManager(this.#permissions);
  }

  get accountMemberId() {
    return this.#permissions?.account_member_id;
  }

  get hideSalary() {
    return shouldHideSalary(this.#permissions.permissions);
  }

  get hideVacancies() {
    return shouldHideVacancies(this.#permissions.permissions);
  }

  get vacancyPermissions() {
    return getVacancyPermissions(this.#permissions.permissions);
  }

  addPermission(permission: IUserPermissions['permissions'][number]) {
    this.#permissions.permissions.push(permission);
  }

  testPermission(fn: (permission: IUserPermissions['permissions'][number]) => boolean) {
    return this.#permissions.permissions.some(fn);
  }

  hasVacancyPermission(vacancyId: number) {
    const predicate = (p: IUserPermissions['permissions'][number]) =>
      p.permission === UserPermissionType.vacancy && p.vacancy === vacancyId;
    return this.testPermission(predicate);
  }
}

function getVacancyPermissions(permissions: IUserPermissions['permissions']) {
  return keyBy(
    permissions.filter(({ permission }) => permission === UserPermissionType.vacancy),
    'vacancy'
  );
}

function isOwner(userPermissions: IUserPermissions) {
  return userPermissions.member_type === MemberType.OWNER;
}

function isWatcher(userPermissions: IUserPermissions) {
  return userPermissions.member_type === MemberType.WATCHER;
}

function isManager(userPermissions: IUserPermissions) {
  return userPermissions.member_type === MemberType.MANAGER;
}

function shouldHideSalary(permissions: IUserPermissions['permissions']) {
  return permissions.some(({ permission }) => permission === UserPermissionType.hideSalary);
}

function shouldHideVacancies(permissions: IUserPermissions['permissions']) {
  return permissions.some(({ permission }) => permission === UserPermissionType.hideVacancies);
}

export const userPermissions = new UserPermissions(window.UserPermissions);

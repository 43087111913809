<template>
  <span>
    <slot name="shortForm" :value="title" :full-form="fullForm" :has-full-form="hasFullForm">{{
      title
    }}</slot
    ><!--
   --><slot v-if="showEllipsis" name="ellipsis" :value="fullForm">
      <ellipsis-tooltip :value="fullForm" />
    </slot>
  </span>
</template>

<script>
import { ref, computed, watch } from 'vue';

import EllipsisTooltip from '@/components/ellipsis-tooltip/ellipsis-tooltip.vue';
import { VacancyNamingHelper } from '@/shared/lib/util/vacancy-naming';

export default {
  name: 'VacancyName',
  components: {
    EllipsisTooltip
  },
  props: {
    useCustomConfig: {
      type: Boolean,
      default: true
    },
    fieldDivider: {
      type: String,
      default: VacancyNamingHelper.DIVIDER
    },
    vacancy: {
      type: Object,
      required: true
    },
    ellipsis: {
      type: Boolean,
      default: true
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const shortFormRef = ref(undefined);
    const fullFormRef = ref(undefined);
    const hasFullFormRef = computed(() => fullFormRef.value?.length > 0);
    // watch нужен, если мы хотим обрабатывать редактирование
    // никак по-другому это не инвалидировать
    watch(
      () => props.vacancy,
      () => {
        const result = VacancyNamingHelper.computeVacancyName(props.vacancy, {
          useCustomConfig: props.useCustomConfig
        });
        shortFormRef.value = result.shortForm;
        fullFormRef.value = result.fullForm;
      },
      {
        deep: true,
        immediate: true
      }
    );
    return {
      shortForm: shortFormRef,
      fullForm: fullFormRef,
      hasFullForm: hasFullFormRef,
      showEllipsis: computed(() => props.ellipsis && !props.full),
      title: computed(() => {
        if (props.full && hasFullFormRef.value) {
          return fullFormRef.value.join(props.fieldDivider);
        }
        return shortFormRef.value ? shortFormRef.value.join(props.fieldDivider) : '';
      })
    };
  }
};
</script>

<i18n lang="json">{}</i18n>

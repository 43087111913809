<template>
  <progress-pure :progress="progress" :days-remaining="daysRemaining" :icon-width="iconWidth" />
</template>

<script>
import ProgressPure from '@/components/deadline-progress/deadline-progress-pure.vue';

export default {
  name: 'VacancyWorkdaysProgress',
  components: {
    ProgressPure
  },
  props: {
    workdaysInWork: {
      type: Number,
      required: true
    },
    workdaysBeforeDeadline: {
      type: Number,
      required: true
    },
    workdaysAfterDeadline: {
      type: Number,
      default: undefined
    },
    iconWidth: ProgressPure.props.iconWidth
  },
  computed: {
    totalDays() {
      return this.workdaysInWork + Math.max(0, this.workdaysBeforeDeadline);
    },
    daysRemaining() {
      if (this.workdaysAfterDeadline) {
        return -this.workdaysAfterDeadline;
      }
      return this.workdaysBeforeDeadline;
    },
    progress() {
      // Если число отрицательное, то дедлайн прошел
      // возвращение 2 - не важно, главное больше 1 чтобы красным было
      // Как считать тут правильно пока не допер(
      if (this.workdaysBeforeDeadline < 0 || this.workdaysAfterDeadline > 0) {
        return 2;
      }

      // чтобы на 0 не делить
      if (this.totalDays === 0) {
        return 1;
      }

      return this.workdaysInWork / this.totalDays;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

import { ApiLayer } from '@/shared/api/utils/api-layer';
import { AxiosClient, axiosApp } from '@/shared/api/utils/client/axios';
import {
  EmailTemplateList,
  MailBoxList,
  SignList,
  Sign,
  EmailTemplateCreate,
  EmailTemplate
} from '@/shared/types/mail';

class MailLayer extends ApiLayer<AxiosClient> {
  fetchMailBoxes() {
    return this.methods.get<MailBoxList>('/account/email');
  }

  /**
   * Получение списка шаблонов
   */
  fetchTemplateList() {
    return this.methods.get<EmailTemplateList>('/mail/template');
  }

  /**
   * Создание шаблона
   * @param template
   */
  createTemplate(template: EmailTemplateCreate) {
    return this.methods.post<EmailTemplate>(`/mail/template`, template);
  }

  /**
   * Обновление шаблона
   * @param templateId
   * @param template
   */
  updateTemplate(templateId: number, template: EmailTemplate) {
    return this.methods.put<Sign>(`/mail/template/${templateId}`, template);
  }

  /**
   * Удаление шаблона
   * @param templateId
   */
  deleteTemplate(templateId: number) {
    return this.methods.delete<Sign>(`/mail/template/${templateId}`);
  }

  /**
   * Получение списка подписей
   */
  fetchSignList() {
    return this.methods.get<SignList>('/mail/sign').catch((error) => {
      console.log(error);
    });
  }

  /**
   * Обновление подписи
   * @param signId
   * @param sign
   */
  updateSign(signId: number, sign: Sign) {
    return this.methods.put<Sign>(`/mail/sign/${signId}`, sign);
  }
}

export const MailApi = new MailLayer(axiosApp);

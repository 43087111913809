<template>
  <resource-action
    :tag="tag"
    :promise="vacanciesPromise"
    @success="handleSuccess"
    @error="handleError"
  >
    <template #default="{ loading }">
      <slot :vacancies="vacancies" :loading="loading" />
    </template>
  </resource-action>
</template>

<script>
import debounce from 'lodash/debounce';
import { VacancyState } from '@/shared/types/vacancy-state';
import { trlMessage } from '../../../shared/lib/i18n';
import { CancelablePromise } from '@/shared/api/utils/cancelable-promise';
import { VacancyAPI } from '@/shared/api/vacancy';
import { VacancyNamingHelper } from '@/shared/lib/util/vacancy-naming';
import ResourceAction from '@/components/resource-action/resource-action';

const GROUP_CAPTIONS = [
  trlMessage('vacancy.list.my'),
  trlMessage('vacancy.list.other'),
  trlMessage('vacancy.list.closed')
];

export default {
  name: 'VacancyResource',
  components: { ResourceAction },
  props: {
    tag: ResourceAction.props.tag,
    query: {
      type: String,
      default: ''
    },
    value: {
      type: [Array, String, Number],
      default: undefined
    },
    showOther: {
      type: Boolean,
      default: true
    },
    showClosed: {
      type: Boolean,
      default: true
    },
    parent: {
      type: [Boolean, Number],
      default: undefined
    },
    squashToParent: {
      type: Boolean,
      default: undefined
    },
    ignoreVacanciesId: {
      type: Array,
      default: () => []
    },
    showGroupLabel: {
      type: Boolean,
      default: true
    },
    additionalItems: {
      type: Array,
      default: () => []
    },
    additionalFilter: {
      type: Object,
      default: () => ({})
    },
    sort: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change:vacancies'],
  data() {
    return {
      vacanciesPromise: null,
      vacanciesByGroup: []
    };
  },
  computed: {
    hash() {
      return JSON.stringify({
        showOther: this.showOther,
        showClosed: this.showClosed,
        ...this.commonFilter
      });
    },
    commonFilter() {
      return {
        q: this.query,
        ignore: this.ignoreVacanciesId,
        parent: this.parent,
        squash_to_parent: this.squashToParent,
        ...this.additionalFilter
      };
    },
    vacancies() {
      const result = [];
      const showGroupLabel = this.showGroupLabel;
      const queryNormalized = this.query.toLowerCase();

      result.push(
        ...this.additionalItems.filter(({ position }) =>
          position.toLowerCase().includes(queryNormalized)
        )
      );

      this.vacanciesByGroup.forEach((items = [], index) => {
        let groupItems = items;
        if (this.sort) {
          groupItems = [...items];
          groupItems.sort((a, b) => {
            const aSortKey = getSortKey(a);
            const bSortKey = getSortKey(b);
            if (aSortKey === bSortKey) {
              return aSortKey.frame_begin > bSortKey.frame_begin;
            }
            return aSortKey < bSortKey;
          });
        }
        if (showGroupLabel) {
          result.push({
            id: GROUP_CAPTIONS[index],
            position: GROUP_CAPTIONS[index],
            items: groupItems
          });
        } else {
          result.push(...groupItems);
        }
      });

      return result;
    }
  },
  watch: {
    hash() {
      this.debouncedFetchVacancies();
    },
    vacanciesByGroup(value) {
      this.$emit('change:vacancies', value);
    }
  },
  mounted() {
    this.fetchVacancies();
    this.debouncedFetchVacancies = debounce(function () {
      return this.fetchVacancies();
    }, 250);
  },
  methods: {
    fetchVacancies() {
      this.vacanciesPromise?.cancel();
      const commonFilter = this.commonFilter;

      let promises;
      if (this.commonFilter.id?.length === 0) {
        promises = [Promise.resolve([]), Promise.resolve([]), Promise.resolve([])];
      }

      promises = promises || [
        VacancyAPI.searchListByFilter({
          mine: true,
          state: [VacancyState.open, VacancyState.hold],
          ...commonFilter
        }).then(this.prepareVacanciesFetch),
        this.showOther
          ? VacancyAPI.searchListByFilter({
              mine: false,
              state: [VacancyState.open, VacancyState.hold],
              ...commonFilter
            }).then(this.prepareVacanciesFetch)
          : Promise.resolve([]),
        this.showClosed
          ? VacancyAPI.searchListByFilter({
              state: [VacancyState.closed],
              ...commonFilter
            }).then(this.prepareVacanciesFetch)
          : Promise.resolve([])
      ];

      this.vacanciesPromise = CancelablePromise.resolve(
        Promise.all(promises).then(this.prepareVacanciesGroups),
        () => {
          promises.forEach((promise) => promise.cancel?.());
        }
      );
    },
    prepareVacanciesFetch({ items }) {
      if (typeof this.parent !== 'number') {
        return items;
      }

      // кажется подобные вещи нужны только в случае если мы выбираем только дочки по вакансии
      return items.map((vacancy) => {
        const nameInfo = VacancyNamingHelper.computeVacancyName(vacancy);

        return {
          ...vacancy,
          nameInfo
        };
      });
    },
    prepareVacanciesGroups(groups) {
      if (typeof this.parent !== 'number') {
        return groups;
      }
      if (!groups.some((vacancies) => vacancies.length)) {
        return groups;
      }

      const nameInfoMap = groups.reduce((acc, vacancies) => {
        vacancies.forEach((vacancy) => {
          acc[vacancy.id] = vacancy.nameInfo;
        });
        return acc;
      }, {});

      const prefixCount = VacancyNamingHelper.computeCommonPrefixCount(Object.values(nameInfoMap));

      groups.forEach((vacancies) => {
        vacancies.forEach((vacancy) => {
          const { fullForm, shortForm } = vacancy.nameInfo;
          const nameParts = fullForm.length ? fullForm : shortForm;
          vacancy.position = VacancyNamingHelper.removePrefix(nameParts, prefixCount).join(
            VacancyNamingHelper.UNBEARABLE_DIVIDER
          );
        });
      });

      return groups;
    },
    handleSuccess(groups) {
      this.vacanciesByGroup = groups;
    },
    handleError(error) {
      console.log(error);
    }
  }
};

function getSortKey(item) {
  return [
    [VacancyState.open, VacancyState.hold, VacancyState.closed].findIndex(
      (state) => item.state === state
    ),
    item.priority,
    item.position
  ].join('_');
}
</script>

<i18n lang="json">{}</i18n>

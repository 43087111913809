import sortBy from 'lodash/sortBy';
import chunk from 'lodash/chunk';
import fast_array_intersect from 'fast_array_intersect';
import TrieSearch from '@/shared/lib/trie-search/trie-search';

export const States = {
  OPEN: 'OPEN',
  HOLD: 'HOLD',
  CLOSED: 'CLOSED'
};

// convert string to searchable form
export const stringToIndex = (str) => {
  const s = str || '';
  return s
    .toLowerCase()
    .replace(/"|'|«|»|’|‘/g, '')
    .split(/[\\\-\s~()/_]+/)
    .filter(Boolean);
};

export const listToIndex = (...args) => {
  let result = [];
  args.filter(Boolean).forEach((item) => {
    result = result.concat(stringToIndex(item));
  });

  return result;
};

/**
 * Filter array from selected items
 *
 * @param values
 * @param selected
 * @param idField
 * @returns {Array}
 */
export const filterSelected = (values, selected, idField = 'id') => {
  const result = values.filter((item) => !selected.includes(item[idField]));
  result.forEach((item) => {
    item._disabled = Boolean(item._alwaysDisabled);
  });

  return result;
};

export const createSearch =
  (displayField = 'title', idField = 'id') =>
  (values, query, selected) => {
    if (!query) return filterSelected(values, selected, idField);
    if (selected && selected.length) {
      values = filterSelected(values, selected, idField);
    }

    const queryIndex = stringToIndex(query);

    return sortBy(
      values.filter((item) => {
        item._rel = 1000;
        return queryIndex.every((q) =>
          (item._index || []).some((value) => {
            const found = value.indexOf(q) === 0;
            if (found) {
              const pos = item[displayField].toLowerCase().indexOf(q);
              item._rel = pos > -1 ? Math.min(pos, item._rel) : item._rel;
            }
            return found;
          })
        );
      }),
      '_rel',
      displayField
    );
  };

export function buildSearchIndex(items, { withChunks, chunkSize, idField } = {}) {
  chunkSize = chunkSize ?? 10000;
  withChunks = withChunks ?? true;
  idField = idField ?? 'id';

  const result = {
    byIds: new Map(),
    search: new TrieSearch()
  };

  function handleItem(item) {
    result.byIds.set(item[idField], item);
    (item._index || []).forEach((part) => {
      result.search.map(part, item[idField]);
    });
  }

  if (withChunks) {
    return Promise.all(
      chunk(items, chunkSize).map((chunkItems, chunkI) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            chunkItems.forEach(handleItem);
            resolve();
          }, chunkI * 150);
        });
      })
    ).then(() => {
      return result;
    });
  } else {
    items.forEach(handleItem);
    return result;
  }
}

export const createDeepSearch = (idField = 'id') => {
  return (values, query, selected, searchIndex) => {
    if (!query) {
      return filterSelected(values, selected, idField);
    }

    const queryIndex = stringToIndex(query);
    const alreadyFound = new Set();

    const findValue = (parent) => {
      const div = searchIndex.byIds.get(parent);
      if (div) {
        if (!alreadyFound.has(parent)) {
          div._disabled = true;
          alreadyFound.add(parent);
          results.push(div);
        }

        if (div.parent) {
          findValue(div.parent);
        }
      }
    };

    const results = [];
    const queryIds = queryIndex.map((queryPart) => searchIndex.search.search(queryPart));
    const ids = queryIds.length === 1 ? queryIds[0] : fast_array_intersect(queryIds);
    for (const id of ids) {
      const item = searchIndex.byIds.get(id);
      if (!item._alwaysDisabled) {
        item._disabled = false;
      }

      if (!alreadyFound.has(id)) {
        results.push(item);

        alreadyFound.add(id);

        if (item.parent) {
          findValue(item.parent);
        }
      }
    }
    return sortBy(results, 'order');
  };
};

export const changeUrl = (url, params = { replace: true, trigger: true }) => {
  const href = location.href.replace(/(javascript:|#).*$/, '');
  if (params.replace) {
    history.replaceState({}, null, href + '#/' + url);
  } else {
    history.pushState({}, null, href + '#/' + url);
  }
  window.dispatchEvent(new Event('hashchange'));
};

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios, { AxiosClient } from '@/shared/api/utils/client/axios';
import { VacancyApplicantLink } from '@/shared/types/vacancy-applicant-link';

interface ConnectPayload {
  status: number;
  sms?: null; // доописать тип
  comment?: string;
  vacancy?: number;
  mentions?: []; // доописать тип
  applicant: number;
  from_recommendations?: boolean;
  vacancy_id?: number;
}

interface FrameMeta {
  vacancy_frame: {
    id: number;
    vacancy: number;
    frame_begin: string;
    frame_end: string | null;
    hired_applicants: number[];
    removed: null;
    workdays_in_work: number;
    workdays_before_deadline: number | null;
    workdays_after_deadline: number | null;
    next: null;
    is_first: boolean;
    is_reopened_parent: boolean;
  };
  applicant: {
    id: number;
    is_hired_in_frame: boolean;
  };
}

class VacancyApplicantLayer extends ApiLayer<AxiosClient> {
  create(data: ConnectPayload) {
    return this.methods.post<VacancyApplicantLink>(`/vacancy/applicant`, data);
  }

  update(linkId: number, data: Record<string, unknown>) {
    return this.methods.put(`/vacancy/applicant/${linkId}`, data);
  }

  splitOnChild(vacancyId: number, data: any) {
    return this.methods.put(`/vacancy/applicant/split/${vacancyId}`, data);
  }

  delete(linkId: number, params?: { reject_with: string }) {
    return this.methods.delete(`/vacancy/applicant/${linkId}`, {
      params
    });
  }

  fetchFrameMeta(vacancyId: number, applicantId: number) {
    return this.methods.get<FrameMeta>(
      `/vacancy/${vacancyId}/frame/current/applicant/${applicantId}`
    );
  }
}

export const VacancyApplicantAPI = new VacancyApplicantLayer(axios);

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { inject } from 'vue';
import BaseRow from './row';
import BaseIcon from '@/components/icon.vue';
import { removeLayerSymbol } from '@/shared/ui/base-layer/constants';


export default /*@__PURE__*/_defineComponent({
  ...{
  name: 'LayoutHeader'
},
  __name: 'header',
  props: {
  title: {
    type: String,
    default: ''
  },
  allowClose: {
    type: Boolean,
    default: true
  }
},
  setup(__props) {





const removeLayer: any = inject(removeLayerSymbol);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseRow), {
    class: _normalizeClass(_ctx.$style.row)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        _renderSlot(_ctx.$slots, "default", {
          titleClass: _ctx.$style.primaryTitle,
          title: __props.title
        }, () => [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.primaryTitle),
            "data-qa": "title"
          }, _toDisplayString(__props.title), 3)
        ]),
        _cache[1] || (_cache[1] = _createTextVNode()),
        (_unref(removeLayer) && __props.allowClose)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.closeButton),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(removeLayer) && _unref(removeLayer)(...args)))
            }, [
              _createVNode(BaseIcon, { type: "x" })
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], undefined, true),
    _: 3
  }, 8, ["class"]))
}
}

})
import { StorageHelper } from '@/shared/lib/util/storage-helper';

const LS_KEY = 'back_url_hash';

export function rememberBackUrlHash(): void {
  const query = new URLSearchParams(window.location.search);

  if (query.get('backurl')) {
    StorageHelper.set(LS_KEY, window.location.hash);
  }
}

export function restoreBackUrlHash(): void {
  const hash = StorageHelper.getString(LS_KEY);
  if (hash !== undefined) {
    window.location.hash = hash;
    StorageHelper.remove(LS_KEY);
  }
}

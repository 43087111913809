<template>
  <vue-validation-errors :name="name" :class="$props.class" :style="style">
    <template #default="{ errors, submitted }">
      <slot name="nude" :errors="errors" :submitted="submitted">
        <template v-if="all">
          <base-error v-for="(error, index) in errors" :key="index" v-bind="$attrs">
            <slot :message="error.message" :type="error.type" :submitted="submitted">
              {{ error.message }}
            </slot>
          </base-error>
        </template>
        <base-error v-else-if="errors.length" v-bind="$attrs">
          <slot :message="errors[0].message" :type="errors[0].type" :submitted="submitted">
            {{ errors[0].message }}
          </slot>
        </base-error>
      </slot>
    </template>
  </vue-validation-errors>
</template>

<script>
import { ValidationErrors as VueValidationErrors } from 'vue-validate-form';

import BaseError from '@/components/error/error.vue';

export default {
  compatConfig: {
    INSTANCE_ATTRS_CLASS_STYLE: false
  },
  name: 'ValidationErrors',
  components: { VueValidationErrors, BaseError },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: undefined
    },
    all: Boolean,
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

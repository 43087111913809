<template>
  <div :class="[$style.wrapper, $props.class]" :style="style">
    <slot :open="open" :remove="removeFile" :upload="uploadFiles" />
    <input
      ref="input"
      tabindex="-1"
      :class="$style.input"
      v-bind="$attrs"
      type="file"
      @change="attachFiles"
    />
  </div>
</template>

<script>
import { notification } from '@/shared/lib/notification/notification';
import { FileHelper } from '@/shared/lib/util/file-helper';

export default {
  name: 'BaseFile',
  inheritAttrs: false,
  props: {
    value: {
      type: [File, FileList, Array],
      default: null
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change'],
  methods: {
    open() {
      this.$refs.input.click();
    },
    attachFiles(event) {
      this.uploadFiles(event.target.files);
      event.target.value = '';
    },
    uploadFiles(files) {
      files = FileHelper.filter(files, (file) => {
        const error = FileHelper.validate(file);
        if (error) {
          notification.notify({
            content: error,
            isError: true
          });
        }
        return !error;
      });
      this.$emit('change', files);
    },
    removeFile() {
      this.$emit('change', null);
    }
  }
};
</script>

<style module>
.wrapper {
  position: relative;
  flex: 1;
}
.input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  top: 0;
}
</style>

<i18n lang="json">{}</i18n>

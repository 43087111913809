import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios, { AxiosClient } from '@/shared/api/utils/client/axios';
import { ApplicantExternal } from '@/shared/types/applicant-external';

class ApplicantExternalLayer extends ApiLayer<AxiosClient> {
  fetchById(applicantId: number, responseId: number) {
    return this.methods.get<ApplicantExternal>(`/applicant/${applicantId}/external/${responseId}`);
  }

  delete(applicantId: number, responseId: number) {
    return this.methods.delete<Record<string, never>>(
      `/applicant/${applicantId}/external/${responseId}`
    );
  }
}

export const ApplicantExternalAPI = new ApplicantExternalLayer(axios);

<template>
  <select-item
    :bordered="bordered"
    :active="active"
    :icon="icon"
    :highlight="highlight"
    :selected="selected"
    :title="title"
    :use-gap="useGap"
  >
    <template #default>
      <base-icon v-if="isHold" data-qa="icon-pause" type="pause-2" :class="$style.pause" />
      <base-icon
        v-if="item.hidden"
        data-qa="icon-eye-slash"
        type="eye-slash"
        :class="$style.hidden"
      />
      <base-icon
        v-if="item.priority && !isHold"
        data-qa="icon-flag"
        type="small-priority"
        :class="$style.flag"
      />
      <span v-html="caption"></span>
    </template>
    <template #hint>
      <slot name="hint" />
    </template>
    <template #subtitle>
      <span v-html="subtitle"></span>
    </template>
  </select-item>
</template>

<script>
import BaseIcon from '@/components/icon';
import SelectItem from './select-item';
import { VacancyState } from '@/shared/types/vacancy-state';
import { VacancyNamingHelper } from '@/shared/lib/util/vacancy-naming';

export default {
  name: 'VacancyItem',
  components: { SelectItem, BaseIcon },
  props: {
    disabled: SelectItem.props.disabled,
    icon: SelectItem.props.icon,
    active: SelectItem.props.active,
    bordered: SelectItem.props.bordered,
    highlight: SelectItem.props.highlight,
    selected: SelectItem.props.selected,
    useGap: SelectItem.props.useGap,
    item: {
      type: Object,
      required: true
    },
    subtitleField: {
      type: String,
      default: 'company'
    }
  },
  computed: {
    isHold() {
      return this.item.state === VacancyState.hold;
    },
    caption() {
      return this.item.position;
    },
    subtitle() {
      if (!this.subtitleField) return;

      if (this.item.multiple) {
        return this.$trlMessage('autocomplete.multivacancy');
      }

      if (this.item.parent) {
        const nameInfo = VacancyNamingHelper.computeVacancyName(this.item);
        return nameInfo.fullForm.length
          ? nameInfo.fullForm.join(VacancyNamingHelper.DIVIDER)
          : nameInfo.shortForm.join(VacancyNamingHelper.DIVIDER);
      }

      return this.item[this.subtitleField];
    },
    title() {
      return this.subtitle ? `${this.caption} – ${this.subtitle}` : this.caption;
    }
  }
};
</script>

<style module>
.icon {
  margin-right: 3px;
  width: 16px;
  height: auto;
}
.pause {
  composes: icon;
  margin-left: 1px;
  margin-right: 2px;
  color: #929097;
}
.hidden {
  composes: icon;
}
.flag {
  composes: icon;
  width: 11px;
}
</style>

<i18n lang="json">{}</i18n>

import pickBy from 'lodash/pickBy';
import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios, { AxiosClient } from '@/shared/api/utils/client/axios';
import { CancelablePromise } from '@/shared/api/utils/cancelable-promise';
import { RecruitmentEvaluationState } from '@/shared/types/recruitment-evaluation-state';
import { Respondent } from '@/shared/types/respondent';

interface RecruitmentEvaluationSettings {
  emailHint: string;
  enabled: boolean;
}

interface RecruitmentEvaluationAnswerRequestsFilter {
  recruitmentEvaluationId: number;
  page: number;
  count: number;
}

interface RecruitmentEvaluationAnswerRequest {
  recruitmentEvaluationId: number;
  respondentId: number;
  name: string;
  email: string;
}

interface SurveyTypeR {
  active: boolean;
  created: string;
  id: number;
  name: string;
  type: 'type_r';
  updated: string;
}

export interface RecruitmentEvaluationAnswer {
  created: string;
  id: number;
  recruitment_evaluation_id: number;
  respondent: Respondent;
  state: RecruitmentEvaluationState;
  survey: SurveyTypeR;
}

interface RecruitmentEvaluationAnswerRequests {
  count: number;
  items: RecruitmentEvaluationAnswer[];
  page: number;
}

interface RecruitmentEvaluationSettings {
  account: number;
  created: string;
  data: string;
  id: number;
  type: string;
}

interface RecruitmentEvaluationParams {
  answer_request_email_hint: string;
  created: string;
  id: number;
  organization_account: number;
  updated: string;
}

interface RecruitmentEvaluationAnswerPayload {
  comment: string;
  stars: number;
}

interface RecruitmentEvaluationResponse {
  params: RecruitmentEvaluationParams;
  setting: RecruitmentEvaluationSettings;
}

class SurveyLayer extends ApiLayer<AxiosClient> {
  fetchAnswerRequests({
    recruitmentEvaluationId,
    page,
    count
  }: Partial<RecruitmentEvaluationAnswerRequestsFilter> = {}): CancelablePromise<RecruitmentEvaluationAnswerRequests> {
    return this.methods.get('/surveys/type_r/answer_requests', {
      params: {
        ...(recruitmentEvaluationId === undefined
          ? {}
          : { recruitment_evaluation_id: recruitmentEvaluationId }),
        ...(page === undefined ? {} : { page }),
        ...(count === undefined ? {} : { count })
      }
    });
  }

  createAnswerRequest({
    recruitmentEvaluationId,
    respondentId,
    name,
    email
  }: Partial<RecruitmentEvaluationAnswerRequest> = {}): CancelablePromise<RecruitmentEvaluationAnswer> {
    return this.methods.post(
      '/surveys/type_r/answer_requests',
      pickBy(
        {
          recruitment_evaluation_id: recruitmentEvaluationId,
          respondent_personal_account_id: respondentId,
          name,
          email
        },
        Boolean
      )
    );
  }

  fetchAnswerById(answerId: number): CancelablePromise<RecruitmentEvaluationAnswer> {
    return this.methods.get(`/surveys/type_r/answers/${answerId}`);
  }

  publishAnswer(code: string, data: RecruitmentEvaluationAnswerPayload, token: string) {
    return this.methods.post(`/recruitment_evaluations/${code}/publish`, data, {
      baseURL: '/',
      params: {
        token
      }
    });
  }

  fetchSettings(): CancelablePromise<RecruitmentEvaluationResponse> {
    return this.methods.get('/settings/recruitment_evaluation');
  }

  updateSettings({
    enabled,
    emailHint
  }: RecruitmentEvaluationSettings): CancelablePromise<{ status: string }> {
    return this.methods.put('/settings/recruitment_evaluation', {
      enabled,
      params: {
        answer_request_email_hint: emailHint
      }
    });
  }
}

export const SurveyTypeRAPI = new SurveyLayer(axios);

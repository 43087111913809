import { DateTime, CompareOptions } from '@/shared/lib/util/date-time-helper';
export const DatePickerUtils = {
  isDisabled(
    date: DateTime,
    { min, max }: { min?: DateTime; max?: DateTime },
    { unit, timeZone }: CompareOptions = {}
  ): boolean {
    if (min && min.isAfter(date, { unit, timeZone })) {
      return true;
    }
    if (max && max.isBefore(date, { unit, timeZone })) {
      return true;
    }
    return false;
  }
};

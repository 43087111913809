import '@/shared/lib/feature-toggle/feature-toggle';
import { configureCompat } from 'vue';

import registerNpsElement from '@/modules/nps/register-nps-element';
import { RegisterGlobalComponents } from './register-global-components';

configureCompat({
  MODE: 3,

  INSTANCE_EVENT_EMITTER: true,
  PRIVATE_APIS: true,
  OPTIONS_DATA_MERGE: true,
  TRANSITION_CLASSES: true
});

registerNpsElement();
RegisterGlobalComponents.register();

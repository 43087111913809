<template>
  <div>
    <resource-paginator :action="getFetchMethod" paginate-by-cursor>
      <template #default="{ loading, next, canLoadMore }">
        <div data-qa="request_list">
          <menu-chapter
            icon="edit-2-20"
            :show-left-arrow="true"
            :rotate-arrow="collapsed"
            @click="$emit('toggle')"
          >
            {{ $i18n('requests') }}
          </menu-chapter>

          <transition
            :enter-class="$style.enter"
            :enter-active-class="$style.enterActive"
            :enter-to-class="$style.enterTo"
            :leave-class="$style.leave"
            :leave-active-class="$style.leaveActive"
            :leave-to-class="$style.leaveTo"
          >
            <div v-if="showRequestsList" :style="requestsListStyle">
              <div v-if="vacancyRequests.length || pendingRequestsCount">
                <ul :class="$style.list">
                  <li
                    v-if="isShowPendingRequestsButton"
                    key="toggle-button"
                    :class="[$options.style.item, $style.pendingRequestsItem]"
                  >
                    <button
                      type="button"
                      :class="$style.pendingRequestsLink"
                      @click="togglePendingRequests"
                    >
                      <span :class="$style.requestText">
                        {{ $trlMessage('vacancy_request.status.pending') }}
                        &nbsp;
                        <span>({{ pendingRequestsCount }})</span>
                        <base-icon type="arrow-right" />
                      </span>
                    </button>
                  </li>
                  <menu-vacancy
                    v-for="item in vacancyRequests"
                    :key="item.id"
                    :vacancy="item"
                    :account="account"
                    @click="handleSelectVacancyRequest(item.id)"
                  />
                </ul>

                <more-button
                  v-if="loading || canLoadMore"
                  :loading="loading"
                  :class="$style.moreBtn"
                  size="full"
                  @click="next"
                >
                  {{ $trlMessage('button.show_more') }}
                </more-button>
              </div>
              <div v-else :class="$style.notFound">{{ $i18n('requests.not.found') }}</div>
            </div>
          </transition>
          <menu-divider />
        </div>
      </template>
    </resource-paginator>

    <vacancy-request
      :vacancy-request="selectedVacancyRequest"
      :is-resolved="isResolved"
      @update-request-values="handleUpdateRequestValues"
      @close="handleSelectVacancyRequest(undefined)"
    />

    <pending-requests-modal :open="showPendingRequests" @change="showPendingRequests = $event" />

    <notifier-message v-on="pollerListeners" />
  </div>
</template>

<script>
import { MessageEvent } from '@/shared/types/poller-message';
import { VacancyRequestStatus } from '@/shared/types/vacancy-request-status';
import vacancyRequestsAPI from '@/shared/api/vacancy-request';
import { SchemaConverterAPI } from '@/shared/api/schema-converter';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { appConfig } from '@/shared/lib/config/app-config';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message';
import BaseIcon from '@/components/icon';
import MenuChapter from '@/modules/sidebar/shares/menu-chapter.vue';
import MenuDivider from '@/modules/sidebar/shares/menu-divider.vue';
import PendingRequestsModal from '@/components/pending-requests-modal/pending-requests-modal.vue';
import MoreButton from '@/components/more-button/more-button.vue';
import ResourcePaginator from '@/components/resource-paginator/resource-paginator.vue';
import VacancyRequest from '../../vacancy-request/vacancy-request';
import MenuVacancy from '@/modules/sidebar/parts/menu-vacancy.vue';
import style from '../sidebar.module.less';
import { SkeletonHelper, SkeletonZones } from '@/shared/lib/util/skeleton-helper';

const PENDING_REQUESTS_LINK_HEIGHT = 28;
const PENDING_REQUESTS_LINK_MARGIN = 5;
const VACANCY_REQUEST_ROW_HEIGHT = 64;
const MORE_BUTTON_HEIGHT = 25;
const NOT_FOUND_HEIGHT = 29;

export default {
  style,
  name: 'VacancyRequestList',
  components: {
    VacancyRequest,
    NotifierMessage,
    PendingRequestsModal,
    BaseIcon,
    ResourcePaginator,
    MoreButton,
    MenuChapter,
    MenuDivider,
    MenuVacancy
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle'],
  data() {
    return {
      // Показываем попап заявки на вакансию, если мы перешли из email
      // Взятие заявки в работу
      selectedId: window.ShowVacancyRequest
        ? parseInt(window.ShowVacancyRequest.vacancy_request_id, 10)
        : undefined,
      vacancyRequests: [],
      showPendingRequests: false,
      pendingRequestsCount: 0,
      selectedVacancyRequest: undefined,
      // Небольшая оптимизация если открываем заявку по прямой ссылке.
      // В этом случае делается запрос за данными и второй запрос при открытии окна заявки будет лишним
      isResolved: false,
      isMoreButtonShown: false
    };
  },
  computed: {
    account: () => appConfig.get('account'),
    pollerListeners() {
      return {
        [MessageEvent.vacancyRequestAdd]: this.handleRequestUpdate,
        [MessageEvent.vacancyRequestEdit]: this.handleRequestUpdate,
        [MessageEvent.vacancyRequestRemove]: this.handleRequestRemove
      };
    },
    isHasPendingRequestsPermission() {
      return userPermissions.isOwner || userPermissions.get('can_receive_vacancy_requests');
    },
    isShowPendingRequestsButton() {
      return !!this.pendingRequestsCount && this.isHasPendingRequestsPermission;
    },
    showRequestsList() {
      return !this.collapsed;
    },
    requestsListStyle() {
      let height = 0;
      if (this.isShowPendingRequestsButton) {
        height += PENDING_REQUESTS_LINK_HEIGHT;
        if (this.vacancyRequests.length) {
          height += PENDING_REQUESTS_LINK_MARGIN;
        }
      }
      if (this.isMoreButtonShown) {
        height += MORE_BUTTON_HEIGHT;
      }
      if (!this.vacancyRequests.length) {
        height += NOT_FOUND_HEIGHT;
      }
      height += this.vacancyRequests.length * VACANCY_REQUEST_ROW_HEIGHT;
      return {
        '--max-height': `${height}px`
      };
    }
  },
  watch: {
    selectedId: {
      immediate: true,
      handler(id) {
        if (!id) {
          this.selectedVacancyRequest = undefined;
          return;
        }

        const selectedVacancyRequest = this.vacancyRequests.find(
          (vacancyRequest) => vacancyRequest.id === id
        );
        if (selectedVacancyRequest) {
          this.isResolved = false;
          this.selectedVacancyRequest = selectedVacancyRequest;
          return;
        }
        vacancyRequestsAPI.getById(id).then((data) => {
          this.isResolved = true;
          this.selectedVacancyRequest = data;
        });
      }
    }
  },
  mounted() {
    SchemaConverterAPI.getVacancyRequestSchemasClient().then((items) => {
      this.accountVacancyRequestIds = items.map(({ id }) => id);
    });

    SkeletonHelper.patchPromise(SkeletonZones.SIDEBAR_GENERAL, this.getPendingRequestsCount());
  },
  methods: {
    getFetchMethod(next_page_cursor) {
      return SkeletonHelper.patchPromise(
        SkeletonZones.SIDEBAR_GENERAL,
        vacancyRequestsAPI
          .getList({ count: 20, paginate_by_cursor: true, next_page_cursor })
          .then((response) => {
            // 'values' указан явно для того, чтобы реактивность была,
            // и после вызова 'handleUpdateRequestValues' попап
            // реагировал на обновление и отображал заявку
            response.items = response.items.map((item) => ({ ...item, values: item.values ?? {} }));
            this.vacancyRequests = this.vacancyRequests.concat(
              // Убираем заявки, которые уже были добавлены в список из poller'а
              response.items.filter(({ id }) => !this.vacancyRequests.find((r) => r.id === id))
            );
            this.isMoreButtonShown = Boolean(next_page_cursor);
            return response;
          })
      );
    },
    getPendingRequestsCount() {
      if (!this.isHasPendingRequestsPermission) {
        return Promise.resolve();
      }
      return vacancyRequestsAPI.getPendingRequestsCount().then(({ total }) => {
        this.pendingRequestsCount = total;
      });
    },
    handleSelectVacancyRequest(id) {
      this.selectedId = id;
    },
    handleUpdateRequestValues(values) {
      const request = this.vacancyRequests.find(({ id }) => id === this.selectedId);
      if (request) {
        request.values = values;
      }
    },
    handleRequestUpdate(vacancyRequest) {
      const isMine = this.account.id === vacancyRequest.account_info.id;

      // DEV-10231
      const isDomesticRequest = !!this.accountVacancyRequestIds?.includes(
        vacancyRequest.account_vacancy_request
      );
      if (!isDomesticRequest) {
        return;
      }
      this.getPendingRequestsCount().then(() => {
        if (
          !isMine &&
          this.isHasPendingRequestsPermission &&
          [VacancyRequestStatus.PENDING, VacancyRequestStatus.REJECTED].includes(
            vacancyRequest.status
          )
        ) {
          // При добавлении и отклонении заявки на вакансию
          // эта заявка попадет в попап "На согласовании (...)",
          // поэтому в список заявок в сайдбаре ее помещать не нужно
          return;
        }
        const index = this.vacancyRequests.findIndex((vr) => vr.id === vacancyRequest.id);
        if (index >= 0) {
          this.vacancyRequests.splice(index, 1, vacancyRequest);
        } else {
          this.vacancyRequests.push(vacancyRequest);
        }

        if (this.selectedVacancyRequest?.id === vacancyRequest.id) {
          this.selectedVacancyRequest = vacancyRequest;
        }
      });
    },
    handleRequestRemove(vacancyRequest) {
      this.getPendingRequestsCount();

      const index = this.vacancyRequests.findIndex((vr) => vr.id === vacancyRequest.id);
      if (index >= 0) {
        this.vacancyRequests.splice(index, 1);
      }
    },
    togglePendingRequests() {
      this.showPendingRequests = !this.showPendingRequests;
    }
  }
};
</script>

<style module>
.enterActive,
.leaveActive {
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.45, 0, 0.55, 1);
}

.leaveActive {
  max-height: var(--max-height);
}
.enter,
.leaveTo {
  max-height: 0;
}

.enterTo {
  max-height: var(--max-height);
}

h3.heading {
  padding: 5px 15px 5px 0;
  border-bottom: 3px solid $sidebarHeaderTextColor;
  transition:
    color 300ms ease,
    border-color 300ms ease;
  margin-bottom: 10px;

  &:hover {
    color: $sidebarTextColorHover;
    border-bottom-color: $sidebarTextColorHover;
  }
}

.pendingRequestsItem:not(:last-child) {
  margin-bottom: 6px;
}

.pendingRequestsLink {
  background: none;
  border: none;
  margin: -4px 0 0 30px;
  font-size: 14px;
  display: block;
  cursor: pointer;
}

.moreBtn {
  --more-button-text-color: $sidebarHeaderTextColor;
  --more-button-line-color: $sidebarHeaderTextColor;
  padding-top: 0;
  margin-left: 8px;
  display: flex; /** Remove whitespaces */
}

.list {
  margin: 0;
  padding: 0;
}

.requestText {
  letter-spacing: -0.005em;
  display: flex;
  align-items: center;
  line-height: 24px;
  color: $sidebarTextColor;

  svg {
    margin-left: 4px;
  }

  span {
    opacity: 0.56;
  }
}

.notFound {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-regular-weight, normal);
  color: $sidebarTextColor;
  margin: 7px 30px 8px 34px;
}
</style>

<i18n lang="json">
{
  "requests": {
    "ru_RU": "Заявки",
    "en_US": "Requests"
  },
  "requests.not.found": {
    "ru_RU": "Заявок нет",
    "en_US": "No requests"
  }
}
</i18n>

import { nanoid } from 'nanoid';

import { MailApi } from '@/shared/api/mail';
import { stringToIndex } from '@/shared/lib/util/search';
import { notification } from '@/shared/lib/notification/notification';
import { trlMessage } from '../../../shared/lib/i18n';
import { compareByOrder } from '@/shared/lib/compators/compators';

const initialState = {
  mailBoxes: window.AccountEmails ?? [],
  templates: (window.MailTemplates ?? []).map((item) => ({
    ...item,
    _index: stringToIndex(item.name)
  })),
  signs: window.MailSigns
};

const gettersConfig = {
  isMailboxesInfoFetched: (state) => Boolean(state.mailBoxes),
  mailBoxes: (state) => state.mailBoxes,
  templates: (state) => state.templates.sort(compareByOrder),
  signs: (state) => state.signs,
  variables: (_, __, ___, rootGetters) => rootGetters['templateVariables/mail'],
  signVariables: (state, getters) => {
    const validSignVariables = ['User.Name', 'User.Phone', 'User.Position', 'Organization.Name'];
    return getters.variables.filter((variable) => validSignVariables.includes(variable));
  },
  canSendEmail: (state, getters) => {
    const mailBoxes = getters.mailBoxes;
    return mailBoxes?.some((mailBox) => mailBox.send) ?? false;
  }
};

const mutations = {
  setMailBoxes(state, mailBoxes) {
    state.mailBoxes = mailBoxes;
  },
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setSigns(state, signs) {
    state.signs = signs;
  },
  removeTemplate(state, id) {
    const index = state.templates?.findIndex((template) => template.id === id);
    if (index >= 0) {
      state.templates.splice(index, 1);
    }
  }
};

const actions = {
  fetchMailBoxes({ commit }) {
    return MailApi.fetchMailBoxes()
      .then((response) => {
        commit('setMailBoxes', response.items);
      })
      .catch((err) => {
        showError(err);
      });
  },
  fetchTemplates({ commit }) {
    return MailApi.fetchTemplateList()
      .then((response) => {
        commit('setTemplates', response.items);
      })
      .catch((err) => {
        showError(err);
      });
  },
  fetchSigns({ commit }) {
    return MailApi.fetchSignList()
      .then((response) => {
        commit('setSigns', response.items);
      })
      .catch((err) => {
        showError(err);
      });
  },
  updateSign({ dispatch }, { id, sign }) {
    return MailApi.updateSign(id, sign)
      .then(() => {
        showSuccess(trlMessage('settings.mail_templates.success.update_sign'));
      })
      .then(() => dispatch('fetchSigns'))
      .catch((err) => {
        showError(err, trlMessage('settings.mail_templates.error.update_sign'));
      });
  },
  createTemplate({ dispatch }, template) {
    return MailApi.createTemplate(template)
      .then(() => {
        showSuccess(trlMessage('settings.mail_templates.success.create_template'));
      })
      .then(() => dispatch('fetchTemplates'))
      .catch((err) => {
        showError(err, trlMessage('settings.mail_templates.error.create_template'));
      });
  },
  updateTemplate({ dispatch }, { id, template }) {
    return MailApi.updateTemplate(id, template)
      .then(() => {
        showSuccess(trlMessage('settings.mail_templates.success.update_template'));
      })
      .then(() => dispatch('fetchTemplates'))
      .catch((err) => {
        showError(err, trlMessage('settings.mail_templates.error.update_template'));
      });
  },
  deleteTemplate({ commit }, id) {
    return MailApi.deleteTemplate(id)
      .then(() => commit('removeTemplate', id))
      .then(() => {
        showSuccess(trlMessage('settings.mail_templates.success.remove_template'));
      })
      .catch((err) => {
        showError(err, trlMessage('settings.mail_templates.error.remove_template'));
      });
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersConfig
};

const notifyId = nanoid();

function showError(err, message = trlMessage('error.unknown')) {
  notification.notify({
    id: notifyId,
    isError: true,
    content: message
  });
}

function showSuccess(message) {
  notification.notify({
    id: notifyId,
    isError: false,
    content: message
  });
}

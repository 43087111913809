export enum AttachmentType {
  FILE = 'file',
  EMAIL = 'email',
  EVENT = 'event',
  TELEGRAM = 'telegram',
  SMS = 'sms',
  OFFER = 'offer',
  SURVEY = 'survey',
  SURVEY_TYPE_Q = 'survey_type_q'
}

<template>
  <li :class="$style.vacancy" @click="$emit('click')">
    <span :class="[$style.statusIcon, $style[`statusIcon-${vacancy.status}`]]" />
    <div :class="$style.text">
      <span
        :class="$style.position"
        :title="vacancy.position"
        data-qa="sidebar-vacancy-request-title"
      >
        {{ vacancy.position }}
      </span>
      <span :class="$style.subText">
        <template v-if="account.id === vacancy.account">
          <!-- eslint-disable-next-line vue/no-restricted-syntax -->
          {{ $trlMessage(`vacancy_request.status.${vacancy.status}`) }}
        </template>
        <template v-else>
          {{ vacancy.account_info.name || vacancy.account_info.email }}
        </template>
      </span>
    </div>
  </li>
</template>

<script>
export default {
  name: 'MenuVacancy',
  components: {},
  props: {
    account: {
      type: Object,
      required: true
    },
    vacancy: {
      type: Object,
      required: true
    }
  },
  emits: ['click'],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style module>
.vacancy {
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px;
  color: $sidebarTextColor;

  &:hover {
    color: $sidebarTextColorHover;
    background-color: $sidebarTextBackgroundHover;
  }
}

.statusIcon {
  display: inline-block;
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
}

.statusIcon-rejected {
  background-color: $red;
}

.statusIcon-approved {
  background-color: $green;
}

.statusIcon-pending {
  background-color: $yellow;
}

.text {
  font-size: $secondaryFontSize;
  display: flex;
  flex-direction: column;
  line-height: $secondLineHeight;
  min-width: 0;
}

.position {
  @mixin ellipsis;
}

.subText {
  @mixin ellipsis;
  opacity: 0.55;
}
</style>

<i18n lang="json">{}</i18n>

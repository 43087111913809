<template>
  <hierarchy-collapsed
    ref="hierarchyCollapsed"
    :hierarchy-map="hierarchyMap"
    @collapsed="$emit('collapsed', $event)"
  >
    <template
      #default="{
        toggleCollapse,
        collapse,
        expand,
        collapsedState,
        collapsedAbility,
        filterCollapsedItems
      }"
    >
      <base-combobox
        :id="id"
        :value="value"
        :items="items"
        :loading="loading"
        :page-mode="pageMode"
        :multiple="multiple"
        :max-height="maxHeight"
        :item-size="itemSize"
        :hierarchy-map="hierarchyMap"
        :items-postprocessing="collapsible ? filterCollapsedItems : undefined"
        :show-inactive="showInactive"
        :only-leaf="onlyLeaf"
        :max-values="maxValues"
        :activity-toggle="activityToggle"
        :clearable="clearable"
        :query="query"
        :loop-pointer="loopPointer"
        :placeholder="placeholder"
        :caption-field="captionField"
        :focus="focus"
        :disable-auto-compress="disableAutoCompress"
        @change="$emit('change', $event)"
        @search="$emit('search', $event)"
        @reset="$emit('reset')"
        @toggle-activity-toggle="$emit('toggle-activity-toggle', $event)"
        @left-key="collapse($event && $event.id)"
        @right-key="expand($event && $event.id)"
      >
        <template #before="props">
          <slot name="before" v-bind="props" />
        </template>
        <template #before-search>
          <slot name="before-search" />
        </template>
        <template #reset="props">
          <slot name="reset" v-bind="props" />
        </template>
        <template #search="props">
          <slot name="search" v-bind="props" />
        </template>
        <template #after-search>
          <slot name="after-search" />
        </template>
        <template #not-found>
          <slot name="not-found" />
        </template>
        <template #loading>
          <slot name="loading" />
        </template>
        <template #item-header="props">
          <slot name="item-header" v-bind="props" />
        </template>
        <template #item-header-title="props">
          <slot name="item-header-title" v-bind="props" />
        </template>
        <template #item="props">
          <collapsed-item-wrapper
            :collapsible="collapsible"
            :collapsed-ability="collapsedAbility[props.item.id]"
            :collapsed="collapsedState[props.item.id]"
            :previous-collapsed-ability="collapsedAbility[props.previousItem.id]"
            :previous-collapsed="collapsedState[props.previousItem.id]"
            :next-collapsed-ability="collapsedAbility[props.nextItem.id]"
            :next-collapsed="collapsedState[props.nextItem.id]"
            @toggle="toggleCollapse(props.item.id)"
          >
            <template
              #default="{
                canCollapsed,
                collapsed,
                previousCanCollapsed,
                previousCollapsed,
                nextCanCollapsed,
                nextCollapsed,
                toggle
              }"
            >
              <slot
                name="item"
                v-bind="props"
                :can-collapsed="canCollapsed"
                :collapsed="collapsed"
                :multiple="multiple"
                :has-selected-children="props.hasSelectedChildren"
                :disabled="props.disabled || disabled"
                :toggle-collapse="toggle"
                :previous-can-collapsed="previousCanCollapsed"
                :previous-collapsed="previousCollapsed"
                :next-can-collapsed="nextCanCollapsed"
                :next-collapsed="nextCollapsed"
              >
                <select-collapsed-item
                  :highlight="props.highlight"
                  :selected="props.selected"
                  :disabled="props.disabled"
                  :deep="props.deep"
                  :active="props.active"
                  :available="props.available"
                  :can-collapsed="canCollapsed"
                  :collapsed="collapsed"
                  :multiple="multiple"
                  :has-selected-children="props.hasSelectedChildren"
                  :collapsible="collapsible"
                  :use-gap="useGap"
                  @click="toggle"
                  @select="props.toggle"
                >
                  <template #default>
                    <slot name="item-title" v-bind="props" />
                  </template>
                  <template #hint>
                    <slot name="item-hint" v-bind="props" />
                  </template>
                  <template #subtitle>
                    <slot name="item-subtitle" v-bind="props" />
                  </template>
                </select-collapsed-item>
              </slot>
            </template>
          </collapsed-item-wrapper>
        </template>
        <template #after="props">
          <slot name="after" v-bind="props" />
        </template>
        <template #after-list="props">
          <slot name="after-list" v-bind="props" />
        </template>
      </base-combobox>
    </template>
  </hierarchy-collapsed>
</template>

<script>
import HierarchyCollapsed from '@/components/hierarchy/hierarchy-collapsed';
import BaseCombobox from '@/components/base-combobox/base-combobox';
import SelectCollapsedItem from '@/components/list-item/select-collapsed-item';
import CollapsedItemWrapper from './collapsed-item-wrapper';

export default {
  name: 'CollapsedCombobox',
  components: { CollapsedItemWrapper, HierarchyCollapsed, BaseCombobox, SelectCollapsedItem },
  props: {
    id: BaseCombobox.props.id,
    loading: BaseCombobox.props.loading,
    pageMode: BaseCombobox.props.pageMode,
    items: BaseCombobox.props.items,
    itemSize: BaseCombobox.props.itemSize,
    showInactive: BaseCombobox.props.showInactive,
    hierarchyMap: BaseCombobox.props.hierarchyMap,
    loopPointer: BaseCombobox.props.loopPointer,
    multiple: BaseCombobox.props.multiple,
    value: BaseCombobox.props.value,
    maxValues: BaseCombobox.props.maxValues,
    onlyLeaf: BaseCombobox.props.onlyLeaf,
    clearable: BaseCombobox.props.clearable,
    focus: BaseCombobox.props.focus,
    query: BaseCombobox.props.query,
    // eslint-disable-next-line vue/require-prop-types
    maxHeight: {
      ...BaseCombobox.props.maxHeight,
      default: null
    },
    placeholder: BaseCombobox.props.placeholder,
    activityToggle: BaseCombobox.props.activityToggle,
    captionField: BaseCombobox.props.captionField,
    disableAutoCompress: BaseCombobox.props.disableAutoCompress,
    useGap: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    collapsible: Boolean
  },
  emits: ['collapsed', 'change', 'reset', 'search', 'toggle-activity-toggle'],
  methods: {
    toggleCollapse(id) {
      this.$refs.hierarchyCollapsed.toggleCollapse(id);
    },
    collapse(id) {
      this.$refs.hierarchyCollapsed.collapse(id);
    },
    expand(id) {
      this.$refs.hierarchyCollapsed.expand(id);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

import { AxiosError } from 'axios';
import { trlMessage } from '@/shared/lib/i18n';

type ErrorsVariant = Record<string, string | string[]>;
type Errors = Record<string, string[]>;

export type ErrorsType = AxiosError<{ errors: ErrorsVariant }>;

export function translateErrors(errors: ErrorsVariant): Errors {
  const translatedErrors: Errors = {};

  Object.entries(errors).reduce((acc, [key, valueVariant]) => {
    const values = Array.isArray(valueVariant) ? valueVariant : [valueVariant];
    // eslint-disable-next-line no-restricted-syntax
    acc[key] = values.map((err) => trlMessage(err));
    return acc;
  }, translatedErrors);

  return translatedErrors;
}

export function getErrors(error: ErrorsType): Errors {
  const errors: ErrorsVariant = error.response?.data?.errors ?? {
    common: 'error.unknown'
  };

  return translateErrors(errors);
}


    import { makeI18n } from '@/shared/lib/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/modules/settings/components/block-title.vue', {"id.copied":{"ru_RU":"ID {id} скопирован ({orgName})","en_US":"ID {id} copied ({orgName})"},"settings.no-tariff":{"ru_RU":"&nbsp· &nbsp{role}","en_US":"&nbsp· &nbsp{role}"},"settings.tariff":{"ru_RU":"&nbsp· &nbspТариф «{tariff}» до {end}&nbsp · &nbsp{role}","en_US":"&nbsp· &nbspPlan \"{tariff}\" until {end}&nbsp · &nbsp{role}"},"user.recruiter":{"ru_RU":"Рекрутер","en_US":"Recruiter"},"user.owner":{"ru_RU":"Управляющий рекрутер","en_US":"Owner"},"user.customer":{"ru_RU":"Заказчик","en_US":"Hiring manager"},"settings.title.main-block":{"ru_RU":"Мой Хантфлоу","en_US":"My Huntflow"}}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n("src/modules/settings/components/block-title.vue", baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  
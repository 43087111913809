<template>
  <News
    v-if="currentNews"
    :current-news="currentNews"
    :has-next-news="hasNextNews"
    :total-count="newsCount"
    @next="handleNext"
  />
</template>

<script>
import { NewsAPI } from '@/shared/api/news';
import News from './pure-news.vue';

export default {
  name: 'VNews',
  components: { News },
  data() {
    return {
      news: []
    };
  },
  computed: {
    currentNews() {
      return this.news[0];
    },
    hasNextNews() {
      return this.newsCount > 1;
    },
    newsCount() {
      return this.news.length;
    }
  },
  mounted() {
    // Потому что инициализация всего хантфлоу достаточно ресурсоемка
    // Чтобы анимация не лагала, делаем отложенно
    setTimeout(() => {
      NewsAPI.getList()
        .then((news) => {
          this.news = news.map(({ content, subject, theme, id }) => ({
            title: subject,
            text: content,
            theme,
            id
          }));
        })
        .catch(() => {
          // Ничего не делаем, так как не критично
        });
    }, 500);
  },
  methods: {
    handleNext() {
      if (this.currentNews.id) {
        NewsAPI.markViewed(this.currentNews.id);
      }
      this.news.shift();
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

<template>
  <base-dropdown
    :overflow-padding="{ top: 55, bottom: 15, right: 10 }"
    @shown="show = true"
    @hidden="show = false"
  >
    <template #default="{ toggle }">
      <div :class="$style.button" @click="toggle">
        <div :class="[$style.buttonInner, show ? $style.buttonActive : '']">
          <div :class="$style.title" data-qa="user-menu-label">
            {{ organization.name }}
          </div>
          <img
            v-if="isShowVerify"
            width="20"
            height="18"
            :class="$style.icon"
            :src="require('../email-verification/assets/attention-menu.svg')"
          />
          <base-icon type="chevron-down" width="20px" height="20px" :class="$style.iconDown" />
        </div>
      </div>
    </template>
    <template #content>
      <dropdown-menu-layout size="large" :footer-with-padding="false">
        <template #content>
          <email-status-full />
          <ul :class="$style.orgs">
            <organisation-item
              v-for="o in organizations"
              :key="o.id"
              :org-active="o.nick === organization.nick"
              :organization="o"
            />
          </ul>
        </template>
        <template #footer-layout>
          <ul :class="$style.actions">
            <li v-if="isSudo" :class="$style.action">
              <a
                href="/account/unsudo"
                :class="[$style.link, $style.warning]"
                data-qa="menu-unsudo"
                >{{ 'unsudo' }}</a
              >
            </li>
            <li v-if="showSettings" :class="$style.action">
              <a href="/settings" :class="$style.link" data-qa="menu-settings">
                <base-icon :classname="$style.icon" type="settings-outline" width="20" />{{
                  $i18n('settings')
                }}
              </a>
            </li>
            <li v-if="showSettings" :class="$style.action">
              <a href="/settings/profile" :class="$style.link">
                <base-icon :classname="$style.icon" type="circle-profile-settings" width="20" />{{
                  $i18n('my_profile')
                }}
              </a>
            </li>
            <li v-if="showHelpMenuItem" :class="$style.action">
              <a :href="fullHelpUrl" target="_blank" :class="$style.link">
                <base-icon :classname="$style.icon" type="info-outline" width="20" />
                {{ $i18n('help') }}
              </a>
            </li>
            <li v-if="showDocumentsRequestMenuItem" :class="$style.action">
              <button type="button" :class="$style.accountingBtn" @click="showDocumentsRequest">
                <base-icon :classname="$style.icon" type="accounting-documents" width="20" />
                {{ $i18n('accounting_documents') }}
              </button>
            </li>
            <li :class="$style.action">
              <a href="/account/logout" :class="$style.link" data-qa="menu-logout">
                <base-icon :classname="$style.icon" type="logout" color="#666666" />{{
                  $i18n('logout')
                }}
              </a>
            </li>
          </ul>
          <document-request-flow
            v-if="isDocumentRequestOpen"
            @close="isDocumentRequestOpen = false"
          />
        </template>
      </dropdown-menu-layout>
    </template>
  </base-dropdown>
</template>

<i18n lang="json">
{
  "my_profile": {
    "ru_RU": "Мой профиль",
    "en_US": "My profile"
  },
  "settings": {
    "ru_RU": "Настройки Хантфлоу",
    "en_US": "Huntflow settings"
  },
  "logout": {
    "ru_RU": "Выйти",
    "en_US": "Logout"
  },
  "unsudo": {
    "ru_RU": "Выйти из sudo",
    "en_US": "Exit sudo mode"
  },
  "add_organization": {
    "ru_RU": "Добавить организацию",
    "en_US": "Add Company"
  },
  "help": {
    "ru_RU": "Руководство пользователя",
    "en_US": "User manual"
  },
  "accounting_documents": {
    "ru_RU": "Бухгалтерские документы",
    "en_US": "Accounting documents"
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex';
import { userEmails } from '@/shared/lib/user-emails/user-emails';
import { appConfig } from '@/shared/lib/config/app-config';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import tariffs from '../../shared/lib/tariffs';
import DocumentRequestFlow from '@/modules/documents-request/flow.vue';
import { BaseIcon } from '@/components';
import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown.vue';
import OrganisationItem from '@/components/user-menu/organisation-item.vue';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';
import EmailStatusFull from '@/components/email-verification/main/email-status-full.vue';

const region = (
  appConfig.get('instanceRegion') ||
  appConfig.get('defaultRegion') ||
  ''
).toLocaleLowerCase();

export default {
  name: 'UserMenu',
  components: {
    DropdownMenuLayout,
    OrganisationItem,
    BaseIcon,
    BaseDropdown,
    DocumentRequestFlow,
    EmailStatusFull
  },
  props: {
    showSettings: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDocumentRequestOpen: false,
      show: false
    };
  },
  computed: {
    ...mapGetters('config', ['organization', 'organizations']),
    isSudo: () => appConfig.get('is_sudo'),
    lang: () => appConfig.get('lang'),
    isWatcher() {
      try {
        return userPermissions.isWatcher;
      } catch (e) {
        return false;
      }
    },
    isNeedVerify() {
      const emails = userEmails.getList();
      return emails.some((el) => !el.verified);
    },
    isShowVerify() {
      const excludeUrl = 'getting-started';
      const pathSegments = window.location.pathname.split('/').filter((segment) => segment);

      return pathSegments[pathSegments.length - 1] !== excludeUrl && this.isNeedVerify;
    },
    showHelpMenuItem() {
      return !this.isWatcher && this.lang === 'ru';
    },
    helpUrl() {
      return `huntflow.${region}/help`;
    },
    fullHelpUrl() {
      return `https://${this.helpUrl}`;
    },
    showDocumentsRequestMenuItem() {
      if (!this.showSettings) {
        return false;
      }
      if (tariffs.isDemo) {
        return false;
      }
      if (this.isWatcher) {
        return false;
      }
      const instance = appConfig.get('instanceRegion');
      return instance === 'KZ' || instance === 'RU';
    }
  },
  methods: {
    showDocumentsRequest() {
      this.isDocumentRequestOpen = true;
    }
  }
};
</script>

<style module>
.button {
  position: relative;
  height: 52px;
  border: 0 none;
  outline: none;
  max-width: 100%;
  padding: 0 12px;
  opacity: 1;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;

  transition: background-color 0.1s linear;

  &:hover {
    .buttonInner {
      background-color: $gray-900;
    }
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: $vantaBlackColor;
    opacity: 1;
  }
}

.buttonInner {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 12px 8px 16px;
  border-radius: 8px;
}

.buttonActive {
}

.title {
  font-weight: 500;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $whiteColor;

  transition: color 0.1s linear;
}

.orgs {
  list-style: none;
  margin: 0;
  padding: 8px 0;
}

.actions {
  list-style: none;
  margin: 0;
  padding: 8px 0;
}

.action:not(:first-child):last-child {
  border-top: 1px solid $delimiterColor;
  padding-top: 8px;
  margin-top: 8px;
  .icon {
    margin-left: 2px;
  }
}

.action {
  padding: 0 8px;
}

.icon {
  display: block;
}

.link,
.accountingBtn {
  display: flex;
  align-items: center;
  line-height: 24px;
  padding: 8px 0 8px 17px;
  gap: 10px;
  border-radius: 8px;

  text-decoration: none;
  color: $black;

  &:visited {
    color: $black;
  }

  &:hover {
    background: $black-7;
  }
}
.warning {
  color: $red-400;

  &:visited {
    color: $red-400;
  }
}

.accountingBtn {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.iconDown {
  color: $white;
}
</style>

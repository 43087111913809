import { immerable, produce } from 'immer';
import { ApiClient, CancelTokenSource } from './client';
import { ApiMethod, ResolverGeneric, ApiMemoize } from '@/shared/api/utils/api-memoize';
import { CancelablePromise } from '@/shared/api/utils/cancelable-promise';

export abstract class ApiLayer<Client extends ApiClient> {
  readonly client: Client;
  readonly cancelTokenSource?: CancelTokenSource;
  readonly [immerable]: boolean = true;

  constructor(apiClient: Client) {
    this.client = apiClient;

    return new Proxy(this, {
      get: (target, prop, receiver) => {
        const value = Reflect.get(target, prop, receiver);
        if (typeof value !== 'function') {
          return value;
        }

        const fn = value.bind(
          produce(target, (draft) => {
            draft.cancelTokenSource = this.client.createCancelTokenSource();
          })
        );
        Object.defineProperty(fn, 'name', {
          value: value.name,
          writable: false
        });
        return fn;
      }
    });
  }

  get methods(): ReturnType<Client['getMethods']> {
    if (!this.cancelTokenSource) {
      throw new Error('не указан cancelTokenSource');
    }
    return this.client.getMethods(this.cancelTokenSource) as any;
  }

  toCancelablePromise<T>(value: T | PromiseLike<T>) {
    return CancelablePromise.resolve(value, this.cancelTokenSource?.cancel);
  }

  filterCanceledRequest<E = unknown>(exception: E): Promise<E> | undefined {
    if (!this.client.isCancel(exception)) {
      return Promise.reject(exception);
    }
  }

  memoizeMethod<M extends ApiMethod>(method: M, callback?: ResolverGeneric<M>): M {
    Object.defineProperty(method, 'name', {
      value: `${this.constructor.name}/${method.name}`,
      writable: false
    });
    const memoize = new ApiMemoize(method, callback);
    return function (this: any, ...params: Parameters<M>): ReturnType<M> {
      return memoize.dispatchWithContext(this, ...params) as ReturnType<M>;
    } as unknown as M;
  }
}

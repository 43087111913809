<template>
  <base-promo size="dropdown" data-qa="applicant-limit-promo">
    <tariff-badge :tariff-group="unlimitedTariffGroup" />
    <h2 v-html="$trlMessage('applicants-limit.promo.title')" />
    <p
      v-html="
        $trlMessage('applicants-limit.promo.descr', {
          tariffName: unlimitedTariffGroup.name
        })
      "
    />

    <template #footer>
      <apply-button reason="promo.tariff_upgrade.reason.limit-candidates" />
    </template>
  </base-promo>
</template>

<script>
import BasePromo from '@/components/promo/promo.vue';
import ApplyButton from '@/components/promo/apply-button.vue';
import TariffBadge from '@/components/tariff-badge/tariff-badge.vue';
import tariffs, { ServiceKindCodes } from '../../../shared/lib/tariffs';

export default {
  name: 'ApplicantLimitTariffPromo',
  components: {
    BasePromo,
    ApplyButton,
    TariffBadge
  },
  computed: {
    unlimitedTariffGroup() {
      return tariffs.getUnlimitedGroup(ServiceKindCodes.applicant_limit);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

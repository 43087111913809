import { JsonHelper } from '@/shared/lib/util/json-helper';
import {
  AccountSetting,
  AccountSettingDataType,
  AccountSettingType
} from '@/shared/types/account-settings';
import { userPermissions } from './user-permissions';
import { appConfig } from './app-config';
export type { AccountSetting, AccountSettingDataType, AccountSettingType };

class AccountConfig {
  #settings: Record<AccountSettingType, AccountSetting>;

  constructor(settings: AccountSetting[]) {
    this.#settings = settings.reduce(
      (res, setting) => {
        const data = JsonHelper.safeJsonParse(<string>setting.data);
        res[setting.type] = { ...setting, data };
        return res;
      },
      {} as Record<AccountSettingType, AccountSetting>
    );
  }

  #get<Type extends AccountSettingType>(type: Type) {
    return this.#settings[type];
  }

  get<Type extends AccountSettingType>(type: Type) {
    const setting = this.#get(type);
    if (!setting) {
      return undefined;
    }
    return setting.data as AccountSettingDataType[Type];
  }

  getter<Type extends AccountSettingType>(type: Type) {
    return () => this.get(type);
  }

  set<Type extends AccountSettingType>(type: Type, value: AccountSettingDataType[Type]) {
    const setting = this.#get(type);
    if (!setting) {
      return;
    }
    setting.data = Object.assign({}, value);
  }

  has<Type extends AccountSettingType>(type: Type) {
    const setting = this.#get(type);
    return setting !== undefined;
  }
}

export const config = {
  settings: new AccountConfig(window.AccountSettings || []),
  appConfig,
  userPermissions
};

import('@/shared/lib/poller-instance').then(({ poller, MessageEvent }) => {
  poller.onMessage((message) => {
    switch (message.event) {
      case MessageEvent.setting: {
        const type = message.type.replace(':edit', '') as AccountSettingType;
        config.settings.set(type, message.data);
      }
    }
  });
});

<template>
  <div :class="$style.root" data-qa="verification-complete">
    <img :src="require('../assets/complete.svg')" :class="$style.image" />
    <h3 :class="$style.text">
      {{ text }}
    </h3>
    <button :class="$style.close" data-qa="close" @click="$emit('close')">
      {{ $trlMessage('button.close') }}
    </button>
  </div>
</template>

<script>
import { EmailType } from '@/shared/types/email-user';

export default {
  name: 'VerificationComplete',
  props: {
    type: {
      type: String,
      required: true
    },
    isChange: {
      type: Boolean
    }
  },
  emits: ['close'],
  computed: {
    text() {
      return this.type === EmailType.LOGIN
        ? this.$i18n('complete.login', { isChange: this.isChange })
        : this.$i18n('complete.notification', { isChange: this.isChange });
    }
  }
};
</script>

<style module>
.root {
  text-align: center;
  font-family: $fontFamilyGraphik;
}

.image {
  display: block;
  margin: 0 auto 16px;
}

.text {
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #373546;
  margin-bottom: 24px;
  margin-top: 0;
}

.close {
  @mixin reset-button-styles;

  margin: 0 auto;
  color: #9c9c9c;
  display: flex;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0;

  &:hover {
    color: #666666;
  }
}
</style>

<i18n lang="json">
{
  "complete.login": {
    "ru_RU": "Почта для входа {isChange, select, true {изменена} other {подтверждена}}",
    "en_US": "Login Email is {isChange, select, true {changed} other {confirmed}}"
  },
  "complete.notification": {
    "ru_RU": "Почта для уведомлений {isChange, select, true {изменена} other {подтверждена}}",
    "en_US": "Notifications Email is {isChange, select, true {changed} other {confirmed}}"
  }
}
</i18n>

<template>
  <ul :class="$style.files">
    <li v-for="(entry, index) in files" :key="index">
      <file-attachment :file="entry" view-mode :class="$style.file" />
    </li>
  </ul>
</template>

<script>
import FileAttachment from '@/shared/ui/file-attachment/file-attachment';

export default {
  name: 'ViewFileList',
  components: {
    FileAttachment
  },
  props: {
    files: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';

.files {
  margin: 0;
  padding: 0;
  list-style: none inside none;
}

.files a {
  color: var(--file-attachment-link-color, @linkColor);
  &:hover {
    color: @linkHoverColor;
  }
}
</style>

<i18n lang="json">{}</i18n>

import { ApiLayer } from './utils/api-layer';
import axios, { AxiosClient } from './utils/client/axios';
import { MemberType } from '@/shared/types/member-type';
import { CursorList, Job } from './utils/types';
import { Delete } from './utils/default-responses';
import { EditableUserFields, UserInvite, FullUserInvite } from '@/shared/types/user';
import { waitForJob } from '@/shared/lib/wait-for-job/wait-for-job';
import { enrichedColleague } from './colleague';

interface ListParams {
  member_type: MemberType;
  next_page_cursor?: string | null;
  count: number;
}

interface CountParams {
  member_type: MemberType;
}

class InvitationColleague extends ApiLayer<AxiosClient> {
  fetchList(params: ListParams) {
    return this.methods
      .get<CursorList<UserInvite>>('/settings/invite', {
        params
      })
      .then((res) => {
        res.items = res.items.map(enrichedColleague);
        return res;
      });
  }

  fetchCount(params: CountParams) {
    return this.methods
      .get<{ total: number }>(`/settings/invites/count`, {
        params
      })
      .then(({ total }) => total);
  }

  fetchById(inviteId: number) {
    return this.methods.get<FullUserInvite>(`/settings/invite/${inviteId}`);
  }

  create(invite: EditableUserFields) {
    return this.methods
      .post<Job>('/settings/invite', invite)
      .then(({ job }): Promise<FullUserInvite> => waitForJob(job))
      .then(enrichedColleague);
  }

  update(id: number, invite: EditableUserFields) {
    return this.methods
      .put<Job>(`/settings/invite/${id}`, invite)
      .then(({ job }): Promise<FullUserInvite> => waitForJob(job))
      .then(enrichedColleague);
  }

  remove(id: number) {
    return this.methods.delete<Delete>(`/settings/invite/${id}`);
  }
}

export const InvitationColleagueAPI = new InvitationColleague(axios);

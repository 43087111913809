<template>
  <form-field
    :label="computedLabel"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
    :files="files"
    :class="$props.class"
    :style="style"
  >
    <template #default="{ id: computedId, invalid }">
      <custom-select
        :id="computedId"
        :value="value"
        :name="computedId"
        :invalid="invalid"
        v-bind="fieldProps"
        @change="handleChange"
      >
        <base-option v-for="option in selectOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </base-option>
      </custom-select>
    </template>
  </form-field>
</template>

<script>
import CustomSelect from '@/components/select/select.vue';
import FormField from './form-field.vue';
import BaseOption from '@/components/select/base-option.vue';

const NULL_VALUE_LABEL = '';

export default {
  name: 'FormFieldSelect',
  components: { BaseOption, FormField, CustomSelect },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change'],
  computed: {
    files() {
      return this.schema.metadata?.files ?? [];
    },
    computedLabel() {
      return this.label || this.schema.title;
    },
    selectOptions() {
      const realValuePredicate = (value) => value || value === 0 || value === false;
      let predicate = realValuePredicate;
      if (!this.$attrs.required) {
        predicate = (value) => realValuePredicate(value) || value === null;
      }
      const options = this.schema.enum.filter(predicate);
      return options.map((value) => {
        const label =
          value === null ? NULL_VALUE_LABEL : (this.options.trl?.[value] ?? String(value));
        return { value, label };
      });
    },
    fieldProps() {
      return {
        ...this.options,
        ...this.$attrs
      };
    }
  },
  methods: {
    handleChange(event) {
      if (!event && !this.$attrs.required) {
        this.$emit('change', null);
        return;
      }
      const data = this.schema.type === 'number' ? Number(event) : event;
      this.$emit('change', data);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>


    import { makeI18n } from '@/shared/lib/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/components/email-verification/parts/pin-code.vue', {"wrong_verification_code":{"ru_RU":"Неправильный код","en_US":"Invalid code"},"pin.wrong.time":{"ru_RU":"Превышено число&nbsp;попыток.<br/>Следующая попытка через '<span>'{ time }'</span>'","en_US":"The maximum number of login attempts <br/>has been reached. Next attempt in '<span>'{ time }'</span>'"},"verification_code_expired":{"ru_RU":"Срок действия кода истек","en_US":"The code has expired"},"required":{"ru_RU":"Обязательное поле","en_US":"This field is required"}}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n("src/components/email-verification/parts/pin-code.vue", baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  
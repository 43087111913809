<template>
  <div :class="[$style.wrapper, $props.class]" :style="style">
    <component :is="component" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  name: 'VerticalClamp',
  inheritAttrs: false,
  props: {
    component: {
      type: Object,
      required: true
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  }
};
</script>

<style module>
.wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

<i18n lang="json">{}</i18n>

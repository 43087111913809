<template>
  <modal-wrapper :open="showVacancyRequest" @change="showVacancyRequest = $event">
    <template #default="{ hide }">
      <vacancy-request-view
        :loading="loading"
        :vacancy-request="vacancyRequest"
        :vacancy-request-schema="vacancyRequestSchema"
        @cancel="hide"
      >
        <template #main>
          <div v-if="isDeleted" :class="$style.warning">
            {{ $trlMessage('pending_request.modal.deleted_warning') }}
          </div>
        </template>
        <template #footer>
          <slot name="footer" :hide="hide">
            <base-button data-qa="close" @click="hide">
              {{ $trlMessage('button.close') }}
            </base-button>
            <base-button
              v-if="!isDeleted && vacancyRequest?.next_approve_link"
              data-qa="copy"
              @click="copyApproveLink"
            >
              {{ $trlMessage('pending_request.modal.copy_link') }}
            </base-button>
            <rejection
              v-if="isPending"
              :id="vacancyRequestId"
              :is-decline="false"
              :class="$style.rejection"
              @remove="
                $emit('request-removed');
                hide();
              "
            >
              <template #default="{ toggle }">
                <base-button color="red" data-qa="delete" @click="toggle">
                  {{ $trlMessage('button.remove') }}
                </base-button>
              </template>
            </rejection>
          </slot>
        </template>
      </vacancy-request-view>
    </template>
  </modal-wrapper>
</template>

<script>
import vacancyRequestAPI from '@/shared/api/vacancy-request';
import { SchemaConverterAPI } from '@/shared/api/schema-converter';
import ModalWrapper from '@/components/modal-next/modal-wrapper';
import BaseButton from '@/components/button/button';
import VacancyRequestView from '@/modules/vacancy-request/components/view/view.vue';
import { notification } from '@/shared/lib/notification/notification';
import Rejection from '@/modules/vacancy-request/modals/view/rejection';

export default {
  name: 'RequestView',
  components: { ModalWrapper, VacancyRequestView, BaseButton, Rejection },
  props: {
    vacancyRequestId: {
      type: Number,
      default: null
    },
    quota: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['change', 'request-removed'],
  data() {
    return {
      loading: false,
      vacancyRequest: undefined,
      vacancyRequestSchema: undefined
    };
  },
  computed: {
    showVacancyRequest: {
      get() {
        return !!this.vacancyRequestId;
      },
      set() {
        this.$emit('change', null);
      }
    },
    isPending() {
      return this.vacancyRequest?.status === 'pending';
    },
    isDeleted() {
      return this.quota._deleted;
    }
  },
  watch: {
    vacancyRequestId: {
      handler(id) {
        if (!id || this.isDeleted) {
          this.vacancyRequest = undefined;
          this.vacancyRequestSchema = undefined;
          return;
        }
        this.loading = true;
        vacancyRequestAPI
          .getById(id)
          .then((vacancyRequest) => {
            return SchemaConverterAPI.getVacancyRequestSchemaClient(
              vacancyRequest.account_vacancy_request
            ).then((vacancyRequestSchema) => {
              this.vacancyRequestSchema = vacancyRequestSchema;
              this.vacancyRequest = vacancyRequest;
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      immediate: true
    }
  },
  methods: {
    copyApproveLink() {
      navigator.clipboard.writeText(this.vacancyRequest.next_approve_link).then(() => {
        notification.notify({
          content: this.$trlMessage('pending_request.modal.notification.link_copied')
        });
      });
    }
  }
};
</script>

<style lang="less" module>
.warning {
  margin: 5px 0 10px;
}

.rejection {
  margin-left: auto;
}
</style>

<i18n lang="json">{}</i18n>

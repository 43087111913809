<template>
  <vjsf
    ref="vjsf"
    :class="$style.vjsf"
    :schema="resolvedSchema"
    :ui-schema="resolvedUiSchema"
    :form-data="formData"
    :no-validate="noValidate"
    :live-validate="liveValidate"
    :start-validate-mode="mode"
    :show-error-list="false"
    :widgets="computedWidgets"
    :custom-formats="computedFormats"
    :no-html5-validate="true"
    :omit-missing-fields="omitMissingFields"
    :disabled="disabled"
    :error-schema="errorSchema"
    @submit="handleSubmit"
    @change="handleChange"
  />
</template>

<script>
import Vjsf from 'vjsf';
import jsonSchemaTraverse from 'json-schema-traverse';
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';
import { validateSnils } from '@/shared/lib/util/validators/snils';
import { isEmailValid } from '@/shared/lib/util/validators/email';
import { isUrlValid } from '@/shared/lib/util/validators/url';
import { hideArchivedFields } from './helpers/hide-archived-fields';
import { widgets } from './widgets';
import { widgets as viewWidgets } from './view-widgets';

const EMPTY_VALUES = ['', null, undefined];

export default {
  name: 'VjsfForm',
  components: { Vjsf },
  props: {
    schema: {
      type: Object,
      default: () => ({})
    },
    uiSchema: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    liveValidate: {
      type: Boolean,
      default: true
    },
    noValidate: Boolean,
    customFormats: {
      type: Object,
      default: () => ({})
    },
    // не используется, можно дропнуть, всегда используется `onSubmit`
    mode: {
      type: String,
      default: 'onSubmit'
    },
    widgets: {
      type: Object,
      default: () => ({})
    },
    omitMissingFields: Boolean,
    viewMode: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    errorSchema: {
      type: Object,
      default: () => ({})
    },
    // для скрытия архивных полей со значением в сценарии создания
    forceArchiveHidding: {
      type: Boolean,
      default: false
    }
  },
  emits: ['dirty', 'submit', 'change'],
  data() {
    return {
      currentFormData: {}
    };
  },
  computed: {
    resolvedSchema() {
      const schema = cloneDeep(this.schema);
      const isSelect = (s) =>
        ['string', 'number', 'integer'].includes(s.type) && Array.isArray(s.enum);
      const pre = (...args) => {
        const [schema, , , , , parentSchema, property] = args;
        if (
          isSelect(schema) &&
          !parentSchema?.required?.includes(property) &&
          !schema.enum.includes(null)
        ) {
          schema.enum.push(null);
        }
      };
      jsonSchemaTraverse(schema, { cb: { pre } });
      return schema;
    },
    resolvedUiSchema() {
      return hideArchivedFields(
        {
          schema: this.schema,
          uiSchema: this.uiSchema,
          formData: this.formData
        },
        { force: this.forceArchiveHidding }
      );
    },
    computedWidgets() {
      return {
        ...(this.viewMode ? viewWidgets : widgets),
        ...this.widgets
      };
    },
    computedFormats() {
      return {
        snils: (value) => {
          if (!value) {
            return true;
          }
          return validateSnils(value);
        },
        email: (value) => {
          if (!value) {
            return true;
          }
          return isEmailValid(value);
        },
        uri: (value) => value === '' || isUrlValid(value),
        ...this.customFormats
      };
    },
    dirty() {
      const initialFormData = this.deepPrepare(
        Object.assign({}, this.formData),
        this.currentFormData
      );
      return !isEqual(initialFormData, this.currentFormData);
    }
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler(formData) {
        this.currentFormData = formData;
      }
    },
    dirty: {
      immediate: true,
      handler(flag) {
        this.$emit('dirty', flag);
      }
    }
  },
  methods: {
    submit() {
      this.$refs.vjsf.submit();
    },
    handleSubmit(formData, event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit('submit', formData);
    },
    handleChange(formData) {
      this.currentFormData = formData;
      this.$emit('change', formData);
    },
    /** дозаполняем дефолтные значения, т.к. комплексное поле добавляет пустой объект, а некоторые контролы по-умолчанию эмитят пустую строка вместо null
     * @param initialFormData - значения по-умолчанию
     * @param formData - используется для сравнения, каких ключей не хватает и для использованию дефолтных значений, при отсутствии в initialFormData, если они являются пустыми
     * @returns {Object}
     */
    deepPrepare(initialFormData, formData) {
      return transform(
        formData,
        (result, value, key) => {
          const isObject = isPlainObject(value);
          const keyIsExists = result.hasOwnProperty(key);
          const isEmpty = EMPTY_VALUES.includes(result[key]);
          const isNewEmpty = EMPTY_VALUES.includes(value);
          if (!keyIsExists) {
            if (isObject) {
              result[key] = {};
            } else {
              result[key] = isNewEmpty ? value : null;
            }
          } else if (keyIsExists && isNewEmpty && isEmpty) {
            // Если ключ есть, но он пустой и значение новое пустое (значения могут быть разными, но оба пустыми)
            result[key] = value;
          }

          if (isObject) {
            result[key] = this.deepPrepare(result[key], formData[key]);
          }
        },
        initialFormData
      );
    }
  }
};
</script>

<style lang="less" module>
.vjsf :global(fieldset) {
  margin: 0;
}
.vjsf :global(.form-group.field + .form-group.field) {
  margin-top: var(--field-margin, 16px);
}
</style>

<i18n lang="json">{}</i18n>

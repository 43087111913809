<template>
  <span v-if="isVisible" :class="$style.ellipsis"
    >&#65279;<!--
 --><base-tooltip placement="bottom-end" :content-class="$style.tooltip">
      <template #default="{ trigger }">
        <span :ref="trigger" :class="$style.target"
          >&#65279;<slot name="icon"><icon type="more" :class="$style.icon" /></slot
        ></span>
      </template>
      <template #content>
        <span v-if="!isArray" :class="$style.valueItem">{{ value }}</span>
        <template v-else>
          <p v-for="(valueItem, index) in value" :key="index" :class="$style.valueItem">{{
            valueItem
          }}</p>
        </template>
      </template>
    </base-tooltip></span
  >
  <span v-else :class="$style.ellipsis"> </span>
</template>

<script>
import { nanoid } from 'nanoid';
import Icon from '../icon.vue';
import BaseTooltip from '@/shared/ui/base-tooltip/base-tooltip.vue';

export default {
  name: 'EllipsisTooltip',
  components: {
    BaseTooltip,
    Icon
  },
  props: {
    value: {
      type: [String, Array],
      default: undefined
    }
  },
  data() {
    return {
      id: nanoid()
    };
  },
  computed: {
    isArray() {
      return Array.isArray(this.value);
    },
    isVisible() {
      return this.isArray ? Boolean(this.value.length) : this.value;
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font.less';

.tooltip {
  width: 350px;
}

.ellipsis {
  white-space: nowrap;
  & * {
    outline: none;
  }
  /*TODO: какой стыд!*/
  & :global([theme*='hf-default-tooltip']) {
    display: none;
  }
}
.ellipsis {
  & .icon {
    opacity: 0.25;
  }
  &:hover .icon {
    opacity: 0.5;
  }
}
.target {
  margin: 0px 7px;
  align-items: center;
  display: inline-flex;
}
.icon {
  position: relative;
  top: 1px;
}
.valueItem {
  display: block;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  .font_regular();
  white-space: break-spaces;
}
.valueItem + .valueItem {
  margin-top: 10px;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <div :class="classes" :data-qa="dataQa" :style="style">
    <base-label :class="$style.label" :for="id">
      <span :class="$style.title" v-html="label" />
      <form-field-error v-if="invalid && errorPlacement === 'top'" :error-schema="errorSchema" />
      <div v-if="description" :class="$style.description" v-html="description" />
      <file-attachment-list v-if="files.length" view-mode :files="files" :class="$style.files" />
    </base-label>
    <slot :id="id" :invalid="invalid" />
    <form-field-error v-if="invalid && errorPlacement === 'bottom'" :error-schema="errorSchema" />
    <field-hint v-if="!invalid" :class="$style.hint">
      <slot name="hint" />
    </field-hint>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import FieldHint from '@/shared/ui/field-hint/field-hint.vue';
import BaseLabel from '@/components/label/label.vue';
import FileAttachmentList from '@/shared/ui/file-attachment-list/file-attachment-list';
import FormFieldError from './form-field-error';

export default {
  name: 'FormField',
  components: {
    BaseLabel,
    FileAttachmentList,
    FormFieldError,
    FieldHint
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    delimiter: Boolean,
    errorSchema: {
      type: Array,
      default: () => []
    },
    files: {
      type: Array,
      default: () => []
    },
    dataQa: {
      type: String,
      required: true
    },
    errorPlacement: {
      validator(value) {
        return ['top', 'bottom'].includes(value);
      },
      default: 'bottom'
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  data() {
    return {
      id: nanoid(10)
    };
  },
  computed: {
    invalid() {
      return Boolean(this.errorSchema?.length);
    },
    classes() {
      return [this.$style.wrapper, { [this.$style.delimiter]: this.delimiter }, this.class];
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';

.wrapper {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.delimiter {
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid @borderColor;
}
.label {
  width: max-content;
}
.title {
  font-weight: var(--title-font-weight, 500);
}
.description {
  color: @gray-500;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 4px 0;
  font-weight: 400;
}

.description + .files {
  margin-top: -10px;
}

.files {
  margin-left: -7px;
  --file-attachment-link-color: @blueColor;
}

.hint:empty {
  display: none;
}
</style>

<i18n lang="json">{}</i18n>

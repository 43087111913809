<template>
  <div :class="$props.class" :style="style">
    <h2 v-if="title" :class="$style.title">{{ title }}</h2>
    <vjsf-form
      ref="vjsf"
      :schema="schema"
      :ui-schema="enrichedUiSchema"
      :form-data="formData"
      :no-validate="noValidate"
      :live-validate="liveValidate"
      :widgets="$options.widgets"
      :disabled="disabled"
      :style="formStyle"
      :class="$style.form"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import { VjsfForm } from '@/components/vjsf';
import { widgets } from './widgets';
import { ajustUISchema } from './helpers/ajust-ui-schema';

export default {
  name: 'SurveyForm',
  components: { VjsfForm },
  inheritAttrs: false,
  widgets,
  props: {
    title: {
      type: String,
      default: undefined
    },
    schema: {
      type: Object,
      default: () => ({})
    },
    uiSchema: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    liveValidate: {
      type: Boolean,
      default: true
    },
    noValidate: Boolean,
    theme: {
      type: Object,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  computed: {
    enrichedUiSchema() {
      return ajustUISchema(this.uiSchema, {
        formData: this.formData
      });
    },
    formStyle() {
      return {
        '--field-margin': '20px',
        '--title-font-weight': this.theme?.labelFontWeight
      };
    }
  },
  methods: {
    submit() {
      this.$refs.vjsf.submit();
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font.less';

.title {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  .font_regular();
  font-size: 20px;
  line-height: 26px;
  overflow-wrap: anywhere;
  word-wrap: break-all;
}

/* TODO: Get rid of external margins */
.title + .form {
  margin-top: 15px;
}
</style>

<i18n lang="json">{}</i18n>

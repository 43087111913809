<template>
  <span>
    <slot :year="year" />
  </span>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';

export default {
  name: 'YearContent',
  props: {
    date: {
      type: DateTimeHelper,
      required: true
    }
  },
  computed: {
    year() {
      return this.date.year;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

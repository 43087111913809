import mitt, { Emitter, EventType } from 'mitt';
import { SkeletonZones } from '@/shared/lib/util/skeleton-helper';

type VacancyBackboneModel = any;
type VacancyBackboneCollection = any;
type ApplicantBackboneModel = any;

type Events = {
  'search:loading': boolean;
  'call-start': {
    phone: string;
    to: string;
    // данные формата `key1:value1,key2:value2`. P.S.: очень странный формат, по хорошему бы сделать обычный объектом, а уже по месту преобразовывать
    customData: string;
  };
  'recommendation:startApply': number;
  'recommendation:apply': number;
  // legacy
  'vacancy.remove': VacancyBackboneModel;
  'applicant.remove': {
    applicant: ApplicantBackboneModel;
    vacancies: VacancyBackboneCollection;
  };
  'applicant.link': {
    applicantId: number;
    status: any;
    vacancy: VacancyBackboneModel;
    isBefore: boolean;
    isPrevious: boolean;
    skipWorkon: boolean;
  };
  'skeleton.content:appearing': SkeletonZones;
};

// Global emitter for any purpose
export const emitter = mitt<Events>();

// Local emitter
export function createEmitter<Events extends Record<EventType, unknown>>(): Emitter<Events> {
  return mitt<Events>();
}

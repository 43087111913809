<template>
  <span>
    <slot :month="month" />
  </span>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';

export default {
  name: 'MonthContent',
  props: {
    date: {
      type: DateTimeHelper,
      required: true
    }
  },
  computed: {
    month() {
      return this.date.toCustomFormat({
        month: 'long'
      });
    }
  }
};
</script>

<i18n lang="json">{}</i18n>

<template>
  <div :class="$props.class" :style="style">
    <base-label v-if="label" :class="$style.labelContent" :for="id">
      {{ label }}
    </base-label>
    <base-select :id="id" :value="value" :focus="focus" v-bind="$attrs">
      <template #default>
        <slot />
      </template>
    </base-select>
  </div>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';
import BaseSelect from '@/components/select/select.vue';

export default {
  name: 'VSelect',
  components: {
    BaseLabel,
    BaseSelect
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: undefined
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    focus: {
      type: Boolean,
      default: false
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  }
};
</script>

<style lang="less" module>
.labelContent {
  display: inline-flex;
  margin-bottom: 3px;
}
</style>

<i18n lang="json">{}</i18n>

<template>
  <select-item
    :bordered="bordered"
    :active="active"
    :icon="selectIcon"
    :highlight="highlight"
    :selected="selected"
    :title="item.name"
    :text-gap-y="textGapY"
    :min-height="minHeight"
    :deep="item.deep || 0"
    :use-gap="useGap"
    @click="$emit('click')"
  >
    <template v-if="item.id" #before-content>
      <base-icon type="circle-head" :class="$style.icon" />
    </template>
    <template #default>
      <span :class="isGroup && $style.groupCaption" v-html="fullCaption"></span>
    </template>
    <template #hint>
      <span v-html="hint"></span>
    </template>
    <template #subtitle>
      <slot name="subtitle" />
    </template>
  </select-item>
</template>

<script>
import { appConfig } from '@/shared/lib/config/app-config';
import BaseIcon from '@/components/icon';
import SelectItem from './select-item';

export default {
  name: 'ColleagueHeadItem',
  components: { SelectItem, BaseIcon },
  props: {
    disabled: SelectItem.props.disabled,
    selectIcon: SelectItem.props.icon,
    iconWidth: {
      type: String,
      default: '22px'
    },
    iconHeight: {
      type: String,
      default: '22px'
    },
    active: SelectItem.props.active,
    bordered: SelectItem.props.bordered,
    highlight: SelectItem.props.highlight,
    selected: SelectItem.props.selected,
    textGapY: SelectItem.props.textGapY,
    minHeight: SelectItem.props.minHeight,
    useGap: SelectItem.props.useGap,
    item: {
      type: Object,
      required: true
    },
    hintField: {
      type: String,
      default: undefined
    },
    userIdField: {
      type: String,
      default: 'id'
    },
    headPrefix: {
      type: String,
      default: 'group:'
    }
  },
  emits: ['click'],
  computed: {
    fullCaption() {
      if (this.isMyTeam) {
        return this.$i18n('my-team') + ', ' + this.caption;
      }
      if (this.isGroup) {
        return this.$i18n('team') + ': ' + this.caption;
      }
      return this.caption;
    },
    caption() {
      return this.item.name_highlight || this.item.name || this.item[this.hintField];
    },
    hint() {
      if (this.item.name && this.item[this.hintField]) {
        return this.item[this.hintField];
      }
      return '';
    },
    isGroup() {
      return this.item.isGroup;
    },
    isMyTeam() {
      if (this.item.isMyTeam) {
        return true;
      }

      // TODO: нипонив что это за type, возможно бага
      if (this.type !== 'head') {
        return false;
      }
      return (
        appConfig.get('account')?.id ===
        Number(this.item[this.userIdField].replace(this.headPrefix, ''))
      );
    }
  }
};
</script>

<i18n lang="json">
{
  "my-team": {
    "ru_RU": "Моя команда",
    "en_US": "My team"
  },
  "team": {
    "ru_RU": "Команда",
    "en_US": "Team"
  }
}
</i18n>

<style module>
.icon {
  margin-right: 7px;
  width: v-bind(iconWidth);
  height: v-bind(iconHeight);
}

.groupCaption {
  font-weight: 500;
}
</style>

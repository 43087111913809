<template>
  <modal-wrapper :open="showModal" @change="showModal = $event">
    <template #default="{ hide }">
      <resource-paginator
        :key="paginatorKey"
        :action="fetchMethod"
        :paginate-by-cursor="paginateByCursor"
      >
        <template #default="{ items: quotas, loading, next, allLoaded, remove }">
          <schemas-resource>
            <template
              #default="{
                loading: schemasLoading,
                schemas,
                schemasFields,
                selectedSchemasFields,
                setFields
              }"
            >
              <quotas-modal-layout
                :loading="(!quotas.length && loading) || schemasLoading"
                :quotas="quotas"
                :schemas="schemas"
                :schemas-fields="schemasFields"
                :selected-schemas-fields="selectedSchemasFields"
                @set:fields="setFields"
                @close="
                  hide();
                  $emit('close');
                "
              >
                <template #caption>
                  <slot name="caption" />
                </template>
                <template #sub-caption>
                  <slot name="sub-caption" />
                </template>
                <template #main>
                  <quota-list
                    :quotas="quotas"
                    :schemas-fields="selectedSchemasFields"
                    :schemas="schemas"
                    :show-caption="includeAllFrames"
                    :frame-begin="frameBegin"
                    @show-quota="handleShowQuota"
                  >
                    <template #quota="{ quota, schemaMap, schemasFields }">
                      <slot
                        name="quota"
                        :quota="quota"
                        :schema-map="schemaMap"
                        :schemas-fields="schemasFields"
                        :handle-show-quota="handleShowQuota"
                        :remove="remove"
                      />
                    </template>
                    <template #empty>
                      <slot name="empty" />
                    </template>
                    <template #after>
                      <div v-if="quotas.length && !allLoaded" :class="$style.moreButtonContainer">
                        <more-button :loading="loading" @click="next">
                          {{ $trlMessage('button.show_more') }}
                        </more-button>
                      </div>
                    </template>
                  </quota-list>
                </template>
                <template #footer>
                  <slot name="footer" :hide="hide" />
                </template>
                <template #close-text>
                  <slot name="close-text" />
                </template>
              </quotas-modal-layout>
            </template>
          </schemas-resource>

          <quota-modal
            :quota="selectedQuota"
            @change="selectedQuota = $event"
            @request-removed="$emit('request-removed', selectedQuota, remove)"
          />
        </template>
      </resource-paginator>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/components/modal-next/modal-wrapper';
import ResourcePaginator from '@/components/resource-paginator/resource-paginator';
import QuotasModalLayout from '@/components/quotas-modal/quotas-modal-layout';
import SchemasResource from '@/components/quotas-modal/schemas-resource';
import MoreButton from '@/components/more-button/more-button';
import QuotaList from '@/components/quota-list/quota-list';
import QuotaModal from '@/components/quota-modal/quota-modal';

export default {
  name: 'BaseQuotasModal',
  components: {
    ModalWrapper,
    ResourcePaginator,
    QuotasModalLayout,
    SchemasResource,
    MoreButton,
    QuotaList,
    QuotaModal
  },
  props: {
    fetchMethod: {
      type: Function,
      required: true
    },
    open: Boolean,
    frameBegin: {
      type: String,
      default: ''
    },
    includeAllFrames: Boolean,
    paginatorKey: {
      type: [String, Number],
      default: ''
    },
    paginateByCursor: ResourcePaginator.props.paginateByCursor
  },
  emits: ['request-removed', 'change', 'close'],
  data() {
    return {
      selectedQuota: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.open;
      },
      set(flag) {
        this.$emit('change', flag);
      }
    }
  },
  methods: {
    handleShowQuota(quota, deleted) {
      this.selectedQuota = { ...quota, _deleted: deleted };
    }
  }
};
</script>

<style lang="less" module>
.moreButtonContainer {
  padding-bottom: 20px;
}
</style>

<i18n lang="json">{}</i18n>


    import { makeI18n } from '@/shared/lib/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/components/error/error.vue', {}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n(undefined, baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  
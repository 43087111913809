<template>
  <div :class="$style.wrapper">
    <div
      v-if="!reference"
      ref="reference"
      :class="$style.referenceWrapper"
      @focusin="handleFocusin"
      @focusout="handleFocusout"
    >
      <slot :show="show" :hide="hide" :toggle="toggle" :shown="isShown" :disabled="disabled" />
    </div>
    <component
      :is="layer"
      v-if="isShown"
      :id="id"
      ref="layer"
      tabindex="0"
      :trigger="getReference"
      :content-class="contentClass"
      :content-style="contentStyle"
      :data-ignore-outside-click="!addLayer ? 'true' : undefined"
      @remove="hide"
    >
      <slot name="content" :hide="hide" :max-height="contentStyle.maxHeight" />
    </component>
  </div>
</template>

<script>
import { nextTick, defineComponent } from 'vue';
import { computePosition, offset, shift, flip, size, autoUpdate } from '@floating-ui/dom';

import BaseLayer from '@/shared/ui/base-layer/base-layer.vue';
import LayerPortal from '@/shared/ui/layer-portal/layer-portal.vue';
import { flipSizeFixMiddleware } from './flip-size-fix-middleware';

export default defineComponent({
  name: 'BasePopover',
  props: {
    id: {
      type: String,
      default: undefined
    },
    shown: Boolean,
    disabled: Boolean,
    placement: {
      type: String,
      default: 'bottom-start'
    },
    distance: {
      type: Number,
      default: 10
    },
    shift: {
      type: Number,
      default: 0
    },
    boundary: {
      type: Element,
      default: undefined
    },
    overflowPadding: {
      type: [Number, Object],
      default: 0
    },
    contentClass: BaseLayer.props.contentClass,
    addLayer: {
      type: Boolean,
      default: true
    },
    flip: {
      type: Boolean,
      default: true
    },
    autoBoundaryMaxSize: {
      type: Boolean,
      default: true
    },
    willChange: {
      type: String,
      default: 'transform'
    },
    maxHeight: {
      type: [String, Number],
      default: Infinity
    },
    strategy: {
      type: String,
      default: 'absolute'
    },
    middlewares: {
      type: Array,
      default: () => []
    },
    preventOverflowOnCrossAxis: {
      type: Boolean,
      default: false
    },
    reference: {
      type: HTMLElement,
      default: null
    }
  },
  emits: ['update:shown', 'shown', 'hidden', 'dispose', 'update', 'focus', 'blur'],
  data() {
    return {
      isShown: false,
      contentStyle: {},
      isFocus: false
    };
  },
  computed: {
    layer() {
      return this.addLayer ? BaseLayer : LayerPortal;
    },
    normalizedMaxHeight() {
      return typeof this.maxHeight === 'string' ? parseInt(this.maxHeight, 10) : this.maxHeight;
    },
    computedMiddlewares() {
      const middlewares = [
        offset({
          mainAxis: this.distance,
          crossAxis: this.shift
        })
      ];
      if (this.flip && this.autoBoundaryMaxSize) {
        middlewares.push(flipSizeFixMiddleware);
      }
      if (this.flip) {
        middlewares.push(
          flip({
            padding: this.overflowPadding,
            boundary: this.boundary
          })
        );
      }
      middlewares.push(
        shift({
          crossAxis: this.preventOverflowOnCrossAxis ?? false,
          padding: this.overflowPadding,
          boundary: this.boundary
        })
      );
      if (this.autoBoundaryMaxSize) {
        middlewares.push(
          size({
            padding: this.overflowPadding,
            boundary: this.boundary,
            apply: ({ availableHeight }) => {
              this.contentStyle.maxHeight = `${Math.min(
                availableHeight,
                this.normalizedMaxHeight
              )}px`;
            }
          })
        );
      }
      return middlewares.concat(this.middlewares);
    }
  },
  watch: {
    shown: 'handleShown',
    disabled(value) {
      if (value) {
        this.dispose();
      } else {
        this.init();
      }
    },
    isFocus: {
      immediate: true,
      handler(flag) {
        flag ? this.$emit('focus') : this.$emit('blur');
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.dispose();
  },
  methods: {
    show(event) {
      if (this.disabled || this.isShown) {
        return;
      }
      if (event instanceof Event) {
        event.preventDefault();
      }
      // подписываемся на все события скроллов, т.к. у нас всегда контент выносится в конец боди,
      // при вложении нескольких подобных элементов друг в друга последующие перестают позиционироваться,
      // т.к. находятся не в скролящямся элементе
      window.addEventListener('scroll', this.updatePosition, {
        capture: true,
        passive: true
      });

      this.isShown = true;
      nextTick(() => {
        const reference = this.getReference();
        if (!reference) {
          return;
        }
        this.stopAutoUpdate = autoUpdate(reference, this.getContent(), this.updatePosition, {
          ancestorScroll: false
        });
        this.$emit('update:shown', true);
        this.$emit('shown');
      });
    },
    hide(event) {
      if (!this.isShown) {
        return;
      }
      if (event instanceof Event) {
        event.preventDefault();
      }
      window.removeEventListener('scroll', this.updatePosition, {
        capture: true,
        passive: true
      });
      this.stopAutoUpdate?.();
      this.isShown = false;
      this.setDefaultContentStyle();
      this.$emit('update:shown', false);
      this.$emit('hidden');
    },
    toggle(event) {
      if (this.isShown) {
        this.hide(event);
      } else {
        this.show(event);
      }
    },
    init() {
      this.setDefaultContentStyle();
      if (this.shown) {
        this.show();
      }
    },
    dispose() {
      this.hide();
      this.$emit('dispose');
    },
    setDefaultContentStyle() {
      this.contentStyle = { maxHeight: `${this.normalizedMaxHeight}px` };
    },
    updatePosition() {
      const reference = this.getReference();
      if (!reference) {
        return Promise.resolve();
      }

      return computePosition(reference, this.getContent(), {
        strategy: this.strategy,
        placement: this.placement,
        middleware: this.computedMiddlewares
      }).then((data) => {
        const { x, y, strategy } = data;

        this.contentStyle = {
          ...this.contentStyle,
          position: strategy,
          top: '0',
          left: '0',
          transform: `translate3d(${Math.round(x)}px, ${Math.round(y)}px, 0)`,
          /**
           * Свойство willChange настраиваемое, чтобы исправить проблему
           * с размытием контента в конкретных dropdown'ах;
           * Подробная хронология и описание тут:
           * https://huntflow.atlassian.net/browse/SRV-22656
           */
          willChange: this.willChange
        };

        nextTick(() => {
          this.$emit('update');
        });
      });
    },
    handleShown() {
      if (this.shown) {
        this.show();
      } else {
        this.hide();
      }
    },
    getReference() {
      if (this.reference) {
        return this.reference;
      }

      // Походу есть такой кейс:
      // 1) кликается на триггер дропдауна
      // 2) вызывается handleFocusin, который дёргает getReference
      // 3) в это время компонент с дропдауном удаляется и this.$refs.reference становится falsy
      // https://huntflow.atlassian.net/browse/DEV-18415
      const refNodes = this.$refs.reference?.querySelectorAll('*');
      if (!refNodes) {
        return undefined;
      }

      const reference = Array.from(refNodes).find((node) => {
        const display = getComputedStyle(node).display;
        if (display === 'contents' || display === 'none') {
          return false;
        }
        return true;
      });
      if (!reference) {
        throw new Error('reference not found');
      }
      return reference;
    },
    getContent() {
      return this.$refs.layer?.getContent();
    },
    handleFocusin(event) {
      const reference = this.getReference();
      if (!reference) {
        return;
      }
      if (reference.contains(event.target) || this.getContent()?.contains(event.target)) {
        this.isFocus = true;
      }
    },
    handleFocusout(event) {
      const reference = this.getReference();
      if (!reference) {
        return;
      }
      if (
        reference.contains(event.relatedTarget) ||
        this.getContent()?.contains(event.relatedTarget)
      ) {
        return;
      }
      this.isFocus = false;
    }
  }
});
</script>

<style module>
.wrapper,
.referenceWrapper {
  display: contents;
}
</style>

<i18n lang="json">{}</i18n>

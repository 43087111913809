<script>
import { defineComponent } from 'vue';

import { appConfig } from '@/shared/lib/config/app-config';

export default defineComponent({
  name: 'BlogAvailabilityWrapper',
  render() {
    if (appConfig.blogAvailable) {
      return this.$slots.default({
        cloudRegionHost: appConfig.cloudRegionHost
      });
    }
    return null;
  }
});
</script>

<i18n lang="json">{}</i18n>

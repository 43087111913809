import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import { AttachmentType } from './attachment-type';

function removeFileAttachment(file, attachments) {
  const id = getFileId(file);
  return attachments.filter((attachment) => getFileId(attachment) !== id);
}

function removeAttachments(attachmentType, attachmentsToRemove, attachments) {
  // Array is needed to handle multiple attachments (file)
  const attachmentArr = Array.isArray(attachmentsToRemove)
    ? attachmentsToRemove
    : [attachmentsToRemove];
  const ids = attachmentArr.map((attachment) => getId(attachment, attachmentType));
  return attachments.filter(([type, value]) => {
    const isAttachmentExists =
      type === attachmentType && ids.includes(getId(value, attachmentType));
    return !isAttachmentExists;
  });
}

function addAttachments(attachmentType, attachmentsToAdd, attachments) {
  const attachmentArr = Array.isArray(attachmentsToAdd) ? attachmentsToAdd : [attachmentsToAdd];
  return [...attachmentArr.map((attachment) => [attachmentType, attachment]), ...getRest()];
  function getRest() {
    // Для файлов разрешаем множественное добавление элементов
    // Для всего остального - по одному (один опросник, одно письмо, одна анкета, одно событие и т.д.)
    if (attachmentType !== AttachmentType.FILE) {
      return attachments.filter(([type]) => type !== attachmentType);
    }
    // а вот эта логика специфична для файлов
    // т.к. у них на ходу меняются id-шники (см. temporalId / id)
    const ids = (Array.isArray(attachmentsToAdd) ? attachmentsToAdd : [attachmentsToAdd]).map(
      (attachment) => getId(attachment, attachmentType)
    );
    return attachments.filter(([type, attachment]) => {
      if (type === AttachmentType.FILE) {
        return !ids.includes(getId(attachment, type));
      }
      return true;
    });
  }
}

function getAttachmentsObj(attachmentsList) {
  const result = mapValues(
    groupBy(attachmentsList, ([type]) => type),
    (value) => {
      const attachments = value.map(([, attachment]) => attachment);
      return attachments.length > 1 ? attachments : attachments[0];
    }
  );
  return result;
}

function getFileId(attachment) {
  return attachment.temporalId || attachment.id;
}

function isFileAttachment(attachmentType) {
  return attachmentType === AttachmentType.FILE;
}

function getId(attachment, attachmentType) {
  return isFileAttachment(attachmentType) ? getFileId(attachment) : attachment.id;
}

export const AttachmentHelpers = {
  removeAttachments,
  addAttachments,
  getAttachmentsObj,
  removeFileAttachment
};

import { flow } from 'lodash';
import { CoworkerAPI } from '@/shared/api/coworker';
import {
  traverseTree,
  flatten,
  sort,
  filter,
  filterPostorder,
  map
} from '../../../shared/lib/tree/tree';
import { compareByName, compareByEmail } from '../../../shared/lib/compators/compators';
import { MemberType } from '@/shared/types/member-type';

// window.AccountMember - массив из объектов ниже
// когда будет ts, можно будет описать в типах
// email: "test@example.com"
// head: null // id на parent
// id: 14
// member: 1 // это тоже id, но из другой таблицы...
// member_type: "owner" || "manager" || watcher" // watcher сомнительно, так как обычно только owner или manager
// meta: null // Это хз для чего
// name: "test@example.com"

// Обычно на этом эпапе делается listToIndex
// но до построения дерева, он будет не корретным
const members = (window.AccountMember || []).map((v) => ({
  ...v
}));

export function initState(members) {
  return {
    // Храним нормализованно
    byId: members.reduce((acc, current) => {
      acc[current.id] = current;
      return acc;
    }, {}),
    // TODO: возможно можно вычислять, так как порядок не важен
    all: members.map(({ id }) => id)
  };
}

const state = initState(members);

const RecruiterTypes = [MemberType.OWNER, MemberType.MANAGER];

const getters = {
  find: (state) => (id) => state.byId[id],
  list: (state) => {
    return state.all.map((id) => state.byId[id]);
  },
  findByMember: (state, getters) => (memberId) => {
    return getters.find(getters.byMembers[memberId]);
  },
  byMembers: (_, getters) => {
    return getters.list.reduce((acc, { member, id }) => {
      acc[member] = id;
      return acc;
    }, {});
  },
  hasRecruiters: (_, getters) => {
    return getters.recruiters && getters.recruiters.length > 1;
  },
  recruiters: (_, getters) =>
    getters.list.filter((member) => RecruiterTypes.includes(member.member_type)),
  recruiterTree: (_, { recruiters, useRecruiterTeams }) => {
    if (!useRecruiterTeams) {
      return recruiters.map((recruiter) => createItem(recruiter));
    }

    const cache = {};

    recruiters.forEach((item) => {
      cacheItem(item, cache);
    });
    const result = [];

    recruiters.forEach((item) => {
      const id = String(item.id);
      if (!item.head) {
        result.push(cache[id]);
      } else {
        const cachedHead = cache[String(item.head)];
        if (!cachedHead) {
          const id = String(item.id);
          result.push({
            ...cache[id],
            head: null
          });
          return;
        }
        cachedHead.children.push(cache[id]);
      }
    });

    traverseTree(result, (node, parent, deep) => {
      node.meta.deep = deep;
    });

    return result;
  },
  // Может показаться, что это лишнее дублирование
  // но кажется, создавая такие делегаты
  // 1. Проще тестировать
  // 2. В теории, эти данные больше относятся к данному модулю
  meId: (_, __, ___, rootGettters) => {
    return rootGettters['config/meMemberId'];
  },
  useRecruiterTeams: (_, getters, ___, rootGetters) => {
    return rootGetters['config/useRecruiterTeams'];
  },
  defaultBoss: (_, getters) => getters.recruiters.find((recruiter) => isOwner(recruiter)),
  childrenById: (_, getters) => (parentId) =>
    getters.recruiters.filter((recruiter) => recruiter.head === parentId),
  mePath:
    (state, { meId }) =>
    (key) => {
      let current = state.byId[meId];
      const result = [];

      if (!current) {
        return result;
      }

      while (current) {
        result.push(current[key]);
        current = state.byId[current.head];
      }

      return result;
    },
  recruiterFlatTreeWithGroup:
    (_, getters) =>
    (excludeIds = [], excludeKey = 'id') => {
      const [meId, meCompanyId] = getters.mePath(excludeKey);

      const recruitersSort = createRecruitersSort(getters.mePath(excludeKey), excludeKey);
      const excludeFilter = createExcludeFilter(excludeIds, excludeKey);
      const addMyFlags = createAddMyFlags({
        meId,
        meCompanyId: meCompanyId || meId,
        key: excludeKey
      });
      const addHeadToChildren = createAddHeadToChildren({
        key: excludeKey
      });

      return flow(
        (tree) => map(tree, flow(addHeadToChildren, addMyFlags), excludeKey),
        (tree) => filterPostorder(tree, excludeFilter),
        (tree) => sort(tree, recruitersSort),
        flatten
      )(getters.recruiterTree);
    },
  potentialBosses:
    (_, getters) =>
    (excludeIds = [], excludeKey = 'id') => {
      const recruitersSort = createRecruitersSort(getters.mePath(excludeKey), excludeKey);
      // Здесь не надо обрабатывать случаи с excludeIds,
      // когда есть дети, так как в excludeIds находится текущий рекрутер
      // Если текущего блокировать но выводить детей, мы можем зациклить
      // дерево - руководителем будет руководить подчиненный
      const excludeFilter = createExcludeFilter(excludeIds, excludeKey);

      return flow(
        (tree) => filter(tree, potentialBossesFilter),
        (tree) => filter(tree, excludeFilter),
        (tree) => sort(tree, recruitersSort),
        (tree) => map(tree, makeAllIsHead),
        flatten
      )(getters.recruiterTree);
    }
};

const actions = {
  changeHead({ commit }, payload) {
    commit('changeHead', payload);
  },
  removeRecruiter({ commit, getters }, payload) {
    const { id, head } = payload;

    return CoworkerAPI.remove(id, { new_head_id: head }).then(() => {
      const oldChilds = getters.recruiters.filter((recruiter) => recruiter.head === id);

      oldChilds.forEach(({ id }) => {
        // не уверен, что хорошо, с точки зрения производительности
        // но сделал здесь, чтобы не перегружать removeRecruiter мутацию
        commit('changeHead', { id, head });
      });

      commit('removeRecruiter', payload);
    });
  }
};

const mutations = {
  changeHead(state, { id, head }) {
    if (!state.byId[id]) {
      return;
    }
    state.byId[id].head = head;
  },
  removeRecruiter(state, { id: removeId }) {
    const index = state.all.findIndex((id) => id === removeId);

    state.all.splice(index, 1);

    delete state.byId[removeId];
  }
};

const GROUP_ID_PREFIX = 'group:';

function createGroupId(id) {
  return GROUP_ID_PREFIX + id;
}

function getIdFromGroupId(id) {
  if (typeof id === 'number') {
    return id;
  }
  return Number(id.replace(GROUP_ID_PREFIX, ''));
}

function cacheItem(item, cache) {
  const id = String(item.id);
  if (!cache[id]) {
    cache[id] = createItem(item);
  }
}

function createItem(item) {
  return {
    value: item,
    meta: {},
    children: []
  };
}

function createAddMyFlags({ meId, meCompanyId, key }) {
  return (node) => {
    if (node.value[key] === meId) {
      return {
        ...node,
        value: { ...node.value, isMe: true }
      };
    }
    if (node.value.isHead && getIdFromGroupId(node.value[key]) === meCompanyId) {
      return {
        ...node,
        value: { ...node.value, isMyTeam: true }
      };
    }
    return node;
  };
}

function createExcludeFilter(exculdeIds, excludeKey) {
  return (node) => !exculdeIds.includes(node.value[excludeKey]) && excludeEmptyGroup(node);
}

function makeAllIsHead(node) {
  return {
    ...node,
    value: { ...node.value, isHead: true }
  };
}

function excludeEmptyGroup(node) {
  return !(node.value.isHead && node.children.length === 0);
}

function createAddHeadToChildren({ key }) {
  return (node) => {
    const isParent = node.children.length > 0 || node.meta.deep === 0;

    if (!isParent) {
      return node;
    }

    // "Теперь если у рекрутера нет команды он не должен группироваться в команду"
    if (!node.children.length) {
      return node;
    }

    const children = [
      {
        ...node,
        meta: { deep: node.meta.deep + 1 },
        children: []
      },
      ...node.children
    ];

    return {
      ...node,
      value: createGroupItem(node.value, key),
      children
    };
  };
}

const MAX_DEEP = 3 - 1;

function potentialBossesFilter(node) {
  if (node.meta.deep >= MAX_DEEP) {
    return false;
  }
  if (isOwner(node.value)) {
    return true;
  }
  if (node.value.head) {
    return true;
  }
  return false;
}

function createRecruitersSort(mePath, key) {
  const [meId, ...other] = mePath;
  const groupIds = other.length === 0 ? [meId] : other;

  const isOrphan = (node) =>
    !(
      node.children.length ||
      node.value.isGroup ||
      node.value.isHead ||
      node.value.head ||
      node.meta.deep
    );

  return function (a, b) {
    if (a.value.isHead && groupIds.includes(getIdFromGroupId(a.value[key]))) {
      return -1;
    }
    if (b.value.isHead && groupIds.includes(getIdFromGroupId(b.value[key]))) {
      return 1;
    }

    if (!a.value.isHead && meId === getIdFromGroupId(a.value[key])) {
      return -1;
    } else if (!b.value.isHead && meId === getIdFromGroupId(b.value[key])) {
      return 1;
    }

    if (a.value.isMe) {
      return -1;
    }
    if (b.value.isMe) {
      return 1;
    }

    if (!isOrphan(a) && isOrphan(b)) {
      return -1;
    }
    if (isOrphan(a) && !isOrphan(b)) {
      return 1;
    }

    if (!a.value.isGroup && b.value.isGroup) {
      return -1;
    }
    if (a.value.isGroup && !b.value.isGroup) {
      return 1;
    }

    return lexicographicRecruiterComparator(a.value, b.value);
  };
}

function createGroupItem(recruiter, key) {
  return {
    ...recruiter,
    isHead: true,
    isGroup: true,
    // Есть два стула, сяду на оба
    id: createGroupId(recruiter.id),
    [key]: createGroupId(recruiter[key])
  };
}

function isOwner(recruiter) {
  return recruiter.member_type === MemberType.OWNER;
}

export function lexicographicRecruiterComparator(a, b) {
  return compareByName(a, b) || compareByEmail(a, b);
}

export default { namespaced: true, state, getters, mutations, actions };

import pickBy from 'lodash/pickBy';
import { JSON_SCHEMA_TYPE } from '../../vjsf.ts';
import { getSchemaType } from './get-schema-type';
import {
  getDictionaryId,
  isMultiple,
  isDictionaryLike,
  isRequired,
  isUri
} from '../legacy-schema-utils';

const filterOutUndefineds = (obj) => pickBy(obj, (prop) => prop !== undefined);

export function makeSchema(field, { dictionaries = {} } = {}) {
  const isArray = isMultiple(field);
  const required = isRequired(field);
  const type = isArray ? JSON_SCHEMA_TYPE.ARRAY : getSchemaType(field);

  const schema = {
    type,
    title: field.title ?? '',
    // не для всех полей isNotEmpty имеет смысл, даже с required=true, но лишнее не повредит
    isNotEmpty: required,
    uniqueItems: isArray ? true : undefined,
    maxLength: field.maxlength || field.maxLength, // FFFUUUU
    minLength: field.minlength || field.minLength, // FFFUUUU
    ...(required ? {} : { nullable: true })
  };

  if (field.is_archived) {
    schema.metadata = { is_archived: field.is_archived };
  }

  if (type === JSON_SCHEMA_TYPE.OBJECT) {
    schema.properties = {};
    schema.required = [];
  }

  if (isArray && Array.isArray(field.values)) {
    schema.items = {
      type: getSchemaType(field),
      enum: field.values
    };
  }

  if (isArray && isDictionaryLike(field)) {
    schema.items = {
      type: getSchemaType(field),
      enum: (dictionaries[getDictionaryId(field)]?.fields || []).map(({ id }) => id)
    };
  }

  if (!isArray && Array.isArray(field.values)) {
    schema.enum = field.values;
    if (!required && !field.values.includes(null)) {
      schema.enum.push(null);
    }
  }

  if (isUri(field)) {
    schema.format = 'uri';
  }

  if (field.validators) {
    schema.format = field.validators[0].type;
  }

  return filterOutUndefineds(schema);
}

<template>
  <base-dropdown
    :disabled="disabled"
    max-height="210px"
    :shown="shown"
    @hidden="$emit('hidden')"
    @shown="$emit('shown')"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <template #default="{ toggle, show, hide, disabled: isDisabled, shown: isShown }">
      <slot
        :toggle="toggle"
        :show="show"
        :hide="hide"
        :prev="prev"
        :next="next"
        :enter="enter"
        :shown="isShown"
        :disabled="isDisabled"
      />
    </template>
    <template #content="{ maxHeight, hide }">
      <item-list
        ref="list"
        :items="items"
        :dropdown-size="dropdownSize"
        :value="value"
        :scroll-to="scrollTo"
        :item-size="itemSize"
        :max-height="maxHeight"
        :caption-field="captionField"
        @change="$emit('change', $event)"
        @click="hide"
        @enter="hide"
      >
        <template #item="scopedProps">
          <slot name="item" v-bind="scopedProps" />
        </template>
        <template #item-title="scopedProps">
          <slot name="item-title" v-bind="scopedProps" />
        </template>
        <template #item-hint="scopedProps">
          <slot name="item-hint" v-bind="scopedProps" />
        </template>
      </item-list>
    </template>
  </base-dropdown>
</template>

<script>
// К сожалению стандартый `dropdown-autocomplete` не подошёл, тут более подробно описано требуемое поведение https://huntflow.atlassian.net/wiki/spaces/MAIN/pages/2969272333
import { defineComponent } from 'vue';

import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown.vue';
import ItemList from './item-list.vue';

export default defineComponent({
  name: 'DropdownAutocomplete',
  components: {
    BaseDropdown,
    ItemList
  },
  props: {
    dropdownSize: ItemList.props.dropdownSize,
    items: ItemList.props.items,
    value: ItemList.props.value,
    scrollTo: ItemList.props.scrollTo,
    itemSize: ItemList.props.itemSize,
    captionField: ItemList.props.captionField,
    disabled: Boolean,
    shown: Boolean
  },
  emits: ['change', 'hidden', 'shown', 'focus', 'blur'],
  methods: {
    prev() {
      this.$refs.list?.prev();
    },
    next() {
      this.$refs.list?.next();
    },
    enter() {
      this.$refs.list?.enter();
    }
  }
});
</script>

<i18n lang="json">{}</i18n>
